import React, { Component } from "react";
import { Box, Dialog, DialogContent, DialogTitle, LinearProgress, Typography } from "@mui/material";

interface DownloadProgressProps {
    isDownloadDialogOpen: boolean;
    downloadProgress: number;
}

export default class DownloadProgressPopup extends Component<DownloadProgressProps> {
    render() {
        return (
            <>
                <Dialog
                    open={this.props.isDownloadDialogOpen}
                    onClose={() => this.setState({ isDownloadDialogOpen: false })}
                    sx={{
                        '& .MuiDialog-paper': {
                            minWidth: '400px',
                            minHeight: '200px',
                            padding: '20px',
                            borderRadius: '12px',
                            boxShadow: '0 8px 16px rgba(0,0,0,0.3)',
                        },
                    }}
                >
                    <DialogTitle sx={{ fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'center' }}>
                        Pobieranie
                    </DialogTitle>
                    <DialogContent>
                        <Box display="flex" alignItems="center" flexDirection="column">
                            <Box width="100%" mb={2}>
                                <LinearProgress
                                    variant="determinate"
                                    value={this.props.downloadProgress}
                                    sx={{
                                        height: '10px',
                                        borderRadius: '5px',
                                        '& .MuiLinearProgress-bar': {
                                            borderRadius: '5px',
                                        },
                                    }}
                                />
                            </Box>
                            <Box minWidth={35} textAlign="center">
                                <Typography variant="body2" color="textSecondary">
                                    {`${Math.round(this.props.downloadProgress)}%`}
                                </Typography>
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}
