import {ISettings} from "../../Http/Settings/ISettings";
import {GetHttpClient} from "../../HttpClient/Factory/HttpClientFactory";

export default class SettingsRepository {
    public static async FindSettings(): Promise<ISettings> {
        let url = '/api/core/settings';

        return GetHttpClient().GET(url).then((response) => {
            return response.data
        })
    }
}
