import UserService from "../../../Infrastructure/Service/User/UserService";
import {IPaginator} from "../../../Infrastructure/Paginator/Paginator";
import {IUserPaginator} from "../../../Infrastructure/Http/User/IUserPaginator";
import UserFacade from "../../../Domain/User/UserFacade";
import IUser, {IUserFilter} from "../../../Infrastructure/Http/User/IUser";
import {IUserNotification} from "../../../Infrastructure/Http/User/HttpUserNotification";

export default class UserAdapter {

    private readonly userFacade: UserFacade;

    public constructor()
    {
        this.userFacade = new UserFacade();
    }
    public async findAll(page: IPaginator, filters: IUserFilter|undefined = undefined): Promise<IUserPaginator> {
        return await this.userFacade.findAll(page, filters);
    }

    public async findById(id: number): Promise<IUser> {
        return await this.userFacade.findById(id);
    }

    public async me(): Promise<IUser> {
        return await this.userFacade.me();
    }

    public async update(user: IUser): Promise<IUser> {
        return await this.userFacade.update(user);
    }
    public logout(withNavigation: boolean = true): void {
        this.userFacade.logout(withNavigation);
    }

    public async notifications(page?: number): Promise<IUserNotification[]> {
        return await this.userFacade.notifications(page);
    }

    public async readNotificationById(id: string): Promise<IUserNotification> {
        return await this.userFacade.readNotificationById(id);
    }
}
