import React, {Component} from 'react';
import "./CheckBoxStyle.scss";
export interface ICheckBoxProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>{
  label: string;
  labelPosition?: 'left'|'top'|'bottom'|'right'
}

export interface ICheckBoxState {

}

export class CheckBox extends Component<ICheckBoxProps, ICheckBoxState> {
    constructor(props: ICheckBoxProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className={`checkbox checkbox-${this.props.labelPosition}`}>
                <div className="checkbox_label">
                    {this.props.label&& (
                        <span>{this.props.label}</span>
                    )}
                </div>
                <div className="checkbox_input">
                    <input type="checkbox" {...this.props}/>
                </div>
            </div>
        );
    }
}
