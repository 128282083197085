import React, {Component} from 'react';
import withRouter, {IRouterProps} from '../../../../Infrastructure/HOC/Router/WithRouter';
import WithLayout from "../../../../Infrastructure/HOC/Layout/WithLayout";
import { GridRowsProp} from "@mui/x-data-grid";
import {AuthorizedPath} from "../../../../Infrastructure/Route/Authorized/AuthorizedPath";
import CameraAdapter from "../../Adapter/CameraAdapter";
import {IAbstractLayoutChildrenProps} from "../../../../Infrastructure/HOC/Layout/AbstractLayout";
import "./CamerasListViewStyle.scss";
import { CamerasTable } from '../../../../Infrastructure/Component/Camera/CamerasTable/CamerasTable';
export interface ICamerasListViewProps extends IAbstractLayoutChildrenProps, IRouterProps {

}

export interface ICamerasListViewState {
    rows: GridRowsProp;
    isTableLoading: boolean;
}

class CamerasListView extends Component<ICamerasListViewProps, ICamerasListViewState> {

    private readonly cameraAdapter: CameraAdapter;
    constructor(props: ICamerasListViewProps) {
        super(props);
        this.cameraAdapter = new CameraAdapter();
        this.state = {
            rows: [],
            isTableLoading: true
        }
    }

    private verifyPermissions() {
        if (!this.props.currentUser?.permissions?.canViewCamera) {
            this.props.navigate(AuthorizedPath.HOME);
        }
    }

    private async fetch(){
        this.verifyPermissions();
        await this.cameraAdapter.findAll({page: 1, limit: 20}).then((iterator) => {
            const rows: GridRowsProp = iterator?.items?.map((row) => {
                return {
                    id: row?.id,
                    userName: row?.userName,
                    status: row?.status,
                    batteryLevel: row?.batteryLevel
                }
            });

            this.setState({rows: rows, isTableLoading: false});
        }).catch((error) => {
            this.setState({isTableLoading: false});
        })
    }

    componentDidMount() {
        this.fetch();
    }

    render() {
        return (
            <div className="cameras">
                <div className="cameras_container">
                    <div className="cameras_container_header">
                        <div className="cameras_container_header_label">
                            Kamery
                        </div>
                    </div>

                    <div className="cameras_container_body">
                        <div className="cameras_container_body_table">
                            <CamerasTable
                                rows={this.state.rows}
                                isTableLoading={this.state.isTableLoading}
                            />
                        </div>
                    </div>

                    <div className="cameras_container_footer">

                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(WithLayout(CamerasListView))