import {IRecordingEvidence} from "../../Infrastructure/Http/Recording/IRecording";
import RecordingEvidenceService from "../../Infrastructure/Service/Recording/RecordingEvidenceService";

export default class RecordingEvidenceFacade {
    private readonly recordingEvidenceService: RecordingEvidenceService;

    constructor() {
        this.recordingEvidenceService = new RecordingEvidenceService();
    }

    public async persist(id: number, http: IRecordingEvidence): Promise<IRecordingEvidence> {
        return await this.recordingEvidenceService.persist(id, http);
    }

    public async update(recordingId: number, evidenceId: number | undefined, http: IRecordingEvidence): Promise<IRecordingEvidence> {
        return await this.recordingEvidenceService.update(recordingId, evidenceId, http);
    }

    public async delete(recordingId: number, evidenceId: number | undefined): Promise<void> {
        return await this.recordingEvidenceService.delete(recordingId, evidenceId);
    }
}
