import {IRecording, IRecordingFilter} from "../../Infrastructure/Http/Recording/IRecording";
import {IRecordingPaginator} from "../../Infrastructure/Http/Recording/IRecordingPaginator";
import RecordingRepository from "../../Infrastructure/Repository/Recording/RecordingRepository";
import RecordingService from "../../Infrastructure/Service/Recording/RecordingService";

export default class RecordingFacade {

    private readonly recordingService: RecordingService;

    constructor() {
        this.recordingService = new RecordingService();
    }
    public async findAll(filters: IRecordingFilter): Promise<IRecordingPaginator> {
        return await this.recordingService.findAll(filters);
    }

    public async findById(id: number): Promise<IRecording> {
        return await this.recordingService.findById(id);
    }
    public async update(recording: IRecording): Promise<void> {
        return await this.recordingService.update(recording);
    }
    public async delete(id: number): Promise<void> {
        return await this.recordingService.delete(id);
    }
}
