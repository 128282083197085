import React, { Component, createRef, RefObject } from "react";
import {
    Box,
    Grid,
    IconButton,
    Typography,
    Dialog,
    DialogContent,
    LinearProgress,
    DialogTitle,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
import { FileData } from "@ffmpeg/ffmpeg/dist/esm/types";
import IUserActivityLog from "../../../../Http/User/IUserActivityLog";
import UserActivityLogAdapter from "../../../../../Application/User/Adapter/UserActivityLogAdapter";
import { IRecordingEvidence, IRecordingFile } from "../../../../Http/Recording/IRecording";
import SettingsAdapter from "../../../../../Application/Settings/SettingsAdapter";
import { ISettings } from "../../../../Http/Settings/ISettings";

interface EvidenceListProps {
    evidences: IRecordingEvidence[];
    durationInSeconds: number;
    handleEvidenceClick: (timeFrom: string) => void;
    file: IRecordingFile;
    handleEvidenceDeleteClick: (evidenceId: number | undefined) => void;
    handleEvidenceEditClick: (evidenceId: number | undefined) => void;
    editMode: boolean;
    videoRef: HTMLVideoElement | null;
}

interface EvidenceListState {
    isDownloadDialogOpen: boolean;
    downloadProgress: number;
    expandedTitle: string | null;
    settings: ISettings | undefined;
    selectedEvidenceId: number | null | undefined;
}

export default class EvidenceList extends Component<EvidenceListProps, EvidenceListState> {
    private readonly userActivityLogAdapter: UserActivityLogAdapter;
    private readonly settingsAdapter: SettingsAdapter;
    private ffmpeg: FFmpeg | null = null;
    private downloadInterval: NodeJS.Timeout | null = null;

    private videoRefs: RefObject<HTMLVideoElement>[];

    static defaultProps = {
        evidences: [],
    };

    constructor(props: EvidenceListProps) {
        super(props);
        this.userActivityLogAdapter = new UserActivityLogAdapter();
        this.settingsAdapter = new SettingsAdapter();

        this.videoRefs = props.evidences.map(() => createRef<HTMLVideoElement>());

        this.state = {
            isDownloadDialogOpen: false,
            downloadProgress: 0,
            expandedTitle: null,
            settings: undefined,
            selectedEvidenceId: null,
        };
    }

    componentDidMount() {
        this.fetchSettings();
        this.setVideoTimes();
    }

    componentDidUpdate(prevProps: EvidenceListProps) {
        if (prevProps.evidences !== this.props.evidences) {
            this.setVideoTimes();
        }
    }

    componentWillUnmount() {
        if (this.downloadInterval) {
            clearInterval(this.downloadInterval);
        }
    }

    private async fetchSettings(): Promise<void> {
        await this.settingsAdapter.findSettings().then((s): void => {
            this.setState({ settings: s });
        });
    }

    setVideoTimes() {
        this.props.evidences.forEach((evidence, index) => {
            const videoRef = this.videoRefs[index]?.current; // Use videoRefs correctly here
            if (videoRef) {
                videoRef.currentTime = parseFloat(evidence.timeFrom);
            }
        });
    }

    handleDownload = async (timeFrom: number, timeTo: number, duration: number) => {
        try {
            this.setState({ isDownloadDialogOpen: true, downloadProgress: 0 });
            this.ffmpeg = new FFmpeg();
            await this.ffmpeg.load();

            if (this.props.file.url) {
                const fileData = await fetchFile(this.props.file.url);
                await this.ffmpeg.writeFile('input.mp4', new Uint8Array(fileData));

                const endTime = Math.min(timeTo, duration);

                this.downloadInterval = setInterval(async () => {
                    const outputFileExists = await this.checkOutputFileExists();
                    if (outputFileExists) {
                        const progressPercentage = Math.min(Math.round((this.state.downloadProgress + 1) * 100 / 2), 100);
                        this.setState({ downloadProgress: progressPercentage });
                    }
                }, 500);

                await this.ffmpeg.exec(['-i', 'input.mp4', '-ss', `${timeFrom}`, '-to', `${endTime}`, '-c', 'copy', '-copyts', 'output.mp4']);

                clearInterval(this.downloadInterval);

                const data: FileData = await this.ffmpeg.readFile('output.mp4');
                const blob = new Blob([data], { type: "video/mp4" });
                const url = URL.createObjectURL(blob);

                const link = document.createElement("a");
                link.href = url;
                link.download = `fragment_nagrania_${timeFrom}_${timeTo}.mp4`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.error("Error trimming and downloading video:", error);
        } finally {
            this.setState({ isDownloadDialogOpen: false });

            this.ffmpeg = null;

            const userActivityLog: IUserActivityLog = {
                description: "Pobrał fragment nagrania o ścieżce: " + this.props.file.url,
            };
            await this.userActivityLogAdapter.persist(userActivityLog);
        }
    };

    async checkOutputFileExists() {
        if (!this.ffmpeg) return false;

        try {
            await this.ffmpeg.readFile('output.mp4');
            return true;
        } catch (error) {
            return false;
        }
    }

    toggleExpandTitle = (title: string) => {
        if (this.state.expandedTitle === title) {
            this.setState({ expandedTitle: null });
        } else {
            this.setState({ expandedTitle: title });
        }
    };

    render() {
        const { evidences, file, editMode } = this.props;
        const { selectedEvidenceId } = this.state;

        return (
            <>
                {evidences.map((evidence, index) => {
                    const timeFromSeconds: number = parseFloat(evidence.timeFrom);
                    const timeToSeconds: number = parseFloat(evidence.timeTo);
                    const videoDuration: number = parseFloat(this.props.file.duration);
                    const isTitleExpanded: boolean = evidence.title ? evidence.title.length > 25 && this.state.expandedTitle === evidence.title : false;

                    const left: number = (timeFromSeconds / videoDuration) * 100;
                    const marginLeft: number = timeFromSeconds <= 0.3 * videoDuration ? 100 : Math.max(left - 10, 0);

                    const isSelected = selectedEvidenceId === evidence.id;

                    return (
                        <Box
                            key={evidence.id}
                            position="relative"
                            left={`${left}%`}
                            marginLeft={`${marginLeft}px`}
                            bottom="-130px"
                            height="300px"
                            sx={{
                                cursor: "pointer",
                                transition: "transform 0.3s, box-shadow 0.3s, z-index 0.3s",
                                zIndex: isSelected ? 10 : 1,
                                boxShadow: isSelected ? "0 12px 24px rgba(0, 0, 0, 0.5)" : "none",
                                backgroundColor: isSelected ? "#303030" : "transparent",
                                "&:hover": {
                                    boxShadow: "0 12px 24px rgba(0, 0, 0, 0.5)",
                                    backgroundColor: "#404040",
                                }
                            }}
                            onClick={() => {
                                this.props.handleEvidenceClick(evidence.timeFrom);
                                this.setState({ selectedEvidenceId: evidence.id });
                            }}
                        >
                            <Box
                                className="thumbnail-container"
                                sx={{
                                    display: "flex",
                                    position: "absolute",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                    width: "220px",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    backgroundColor: "#1A1E2D",
                                    borderRadius: "8px",
                                    zIndex: isSelected ? 10 : 1,
                                    "&:hover": {
                                        boxShadow: "0 12px 24px rgba(0, 0, 0, 0.5)",
                                    }
                                }}
                            >
                                <video
                                    ref={this.videoRefs[index]}
                                    src={file.url}
                                    width="220"
                                    height="140"
                                    muted
                                    style={{
                                        borderRadius: "8px",
                                        boxShadow: isSelected ? "0 12px 24px rgba(0, 0, 0, 0.5)" : "none",
                                    }}
                                />
                                <Grid container sx={{p: 2}} justifyContent="space-between" alignItems="center">
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: "#fff",
                                            fontWeight: isSelected ? "bold" : "normal",
                                        }}
                                        gutterBottom
                                    >
                                        {isTitleExpanded ? evidence.title : evidence.title?.substring(0, 25)}
                                        {evidence.title && evidence.title.length > 25 && (
                                            <span
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.toggleExpandTitle(evidence.title ?? "");
                                                }}
                                                style={{
                                                    color: "#1E88E5",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                {isTitleExpanded ? "Less" : "...More"}
                                            </span>
                                        )}
                                    </Typography>
                                    <Box>
                                        <IconButton color="secondary" onClick={() => this.props.handleEvidenceEditClick(evidence.id)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton color="secondary" onClick={() => this.props.handleEvidenceDeleteClick(evidence.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                    <IconButton
                                        color="secondary"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.handleDownload(timeFromSeconds, timeToSeconds, videoDuration);
                                        }}
                                    >
                                        <DownloadIcon/>
                                    </IconButton>
                                </Grid>
                            </Box>
                        </Box>
                    );
                })}
                <Dialog open={this.state.isDownloadDialogOpen}
                        onClose={() => this.setState({isDownloadDialogOpen: false})}>
                    <DialogTitle>Pobieranie fragmentu nagrania</DialogTitle>
                    <DialogContent>
                        <LinearProgress variant="determinate" value={this.state.downloadProgress}/>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}
