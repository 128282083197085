import React, {Component} from 'react';
import {IRecordingNote} from "../../../Http/Recording/IRecordingNote";
import PersonIcon from '@mui/icons-material/Person';
import "./RecordingNoteItemStyle.scss";

enum RecordingNoteItemMode {
    READ = 'read',
    WRITE = 'write'
}
export interface IRecordingNoteItemProps {
    note: IRecordingNote;
    canEdit: boolean;
    canDelete: boolean;

    onSave: (id: number, note: IRecordingNote) => void;
    onDelete: (id: number) => void;
}

export interface IRecordingNoteItemState {
   mode:  RecordingNoteItemMode;
   note: IRecordingNote;
}

export class RecordingNoteItem extends Component<IRecordingNoteItemProps, IRecordingNoteItemState> {
    constructor(props: IRecordingNoteItemProps) {
        super(props);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onTextAreaChanged = this.onTextAreaChanged.bind(this);
        this.state = {
            note: this.props.note,
            mode: RecordingNoteItemMode.READ
        };
    }

    private onSave () {
        this.props.onSave(this.props.note?.id ?? 0, this.state.note);
    }

    private onDelete () {
        this.props.onDelete(this.props.note?.id ?? 0);
    }

    private onTextAreaChanged(e: string) {
        this.setState(prevState => ({
            note: {
                ...prevState.note,
                text: e
            }
        }))
    }

    render() {
        return (
            <div className="recording-note-item">
                <div className="recording-note-item_container">
                  <div className="recording-note-item_container_header">
                    <div className="recording-note-item_container_header_profile">
                      <div className="recording-note-item_container_header_profile_avatar">
                          <PersonIcon/>
                      </div>
                        {this.props.note.user?.firstName && this.props.note.user.lastName && (
                            <div className="recording-note-item_container_header_profile_user">
                                <span>{this.props.note.user.firstName} {this.props.note.user.lastName}</span>
                            </div>
                        )}
                    </div>
                  </div>
                  <div className="recording-note-item_container_body">
                      {this.props.canEdit && this.state.note?.text && (
                          <textarea
                              value={this.state.note.text}
                              onChange={(e) => this.onTextAreaChanged(e.target?.value)}
                              readOnly={this.state.mode === RecordingNoteItemMode.READ}
                          />
                      )}

                      {!this.props.canEdit && (
                          <p>{this.props.note?.text}</p>
                      )}
                  </div>

                  <div className="recording-note-item_container_footer">
                      {this.props.canEdit && this.state.mode === RecordingNoteItemMode.READ && (
                          <span className="recording-note-item_container_footer_edit" onClick={() => {
                              this.setState({mode: RecordingNoteItemMode.WRITE})
                          }}>Edytuj</span>
                      )}

                      {this.props.canEdit && this.state.mode === RecordingNoteItemMode.WRITE && (
                          <span className="recording-note-item_container_footer_save" onClick={() => {
                              this.setState({mode: RecordingNoteItemMode.READ},  this.onSave);
                          }}>Zapisz</span>
                      )}

                      {this.props.canDelete && this.state.mode === RecordingNoteItemMode.READ && (
                          <span className="recording-note-item_container_footer_delete" onClick={this.onDelete}>Usuń</span>
                      )}

                      {this.state.mode === RecordingNoteItemMode.WRITE && (
                          <span className="recording-note-item_container_footer_cancel" onClick={() => {
                              this.setState({note: this.props.note, mode: RecordingNoteItemMode.READ})
                          }}>Odrzuć</span>
                      )}
                  </div>
                </div>
            </div>
        );
    }
}
