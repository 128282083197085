import React, {Component} from 'react';
import { IRecording } from '../../../Http/Recording/IRecording';
import RecordingService from "../../../Service/Recording/RecordingService";
import {GridColDef, GridRowsProp} from "@mui/x-data-grid";
import {RecordingTableThumbnail} from "../../Recording/TableThumbnail/RecordingTableThumbnail";
import {IconButton, Link} from "@mui/material";
import {AuthorizedPath} from "../../../Route/Authorized/AuthorizedPath";
import DownloadIcon from "@mui/icons-material/Download";
import RouteNavigator from "../../../Route/Navigator/RouteNavigator";
import Table from "../../Shared/Table/Table";

export interface IUserRecordingsListProps {
    rows: GridRowsProp;
    isTableLoading: boolean;
}

export interface IUserRecordingsListState {
      columns: GridColDef[];
}

export class UserRecordingsList extends Component<IUserRecordingsListProps, IUserRecordingsListState> {
  constructor(props: IUserRecordingsListProps) {
        super(props);
        this.state = {
          columns: [
            { field: 'title', headerName: "Nagranie", width: 120, type: 'string',  editable: false,  renderCell: (params) => {
                return <RecordingTableThumbnail
                    id={params.row?.id}
                    title={params.row?.title}
                    sourceUrl={params?.row?.url}
                />
              }},
            { field: 'startDate', headerName: 'Początek',  width: 200, type: "string",  editable: false, flex: 1},
            { field: 'endDate', headerName: 'Koniec',  width: 200, type: "string",  editable: false, flex: 1},
            { field: 'isEvidence', headerName: 'Dowód',  width: 200, type: "boolean",  editable: false, flex: 1, renderCell: (params) => {
                return <span>{params?.row?.isevidence === true ? "Tak" : "Nie"}</span>
              }},
            { field: 'action', headerName: "Akcja", width: 200, type: 'string',  editable: false, flex: 1, renderCell: (params) => {
                return  (
                    <div className="flex justify-between items-center gap-2">
                      <Link href={AuthorizedPath.RECORDING(params?.row?.id)}>Wyświetl</Link>
                    </div>
                )
              }}
          ],
        };
    }

  componentDidMount() {
  }

    render() {
        return (
            <Table
                label="Lista nagrań"
                description="Poniżej znajdziesz listę nagran danego użytkownika"
                initialState={{
                    pagination: { paginationModel: { pageSize: 5 } },
                }}
                pageSizeOptions={[5, 10, 25, 50]}
                columns={this.state.columns}
                rows={this.props.rows}
                checkboxSelection={false}
                disableRowSelectionOnClick={true}
                loading={this.props.isTableLoading}
            />
        );
    }
}
