import {IUserAuthCredentials} from "../../Http/Auth/IUserAuthCredentials";
import {IUserAuthToken} from "../../Http/Auth/IUserAuthToken";
import AuthRepository from "../../Repository/Auth/AuthRepository";
import {IUserTokenValidity} from "../../Http/Auth/IUserTokenValidity";
import {IPasswordVerify} from "../../Http/Auth/IPasswordVerify";

export default class AuthService {


    public async login(credentials: IUserAuthCredentials): Promise<IUserAuthToken> {
        return await AuthRepository.Login(credentials);
    }

    public async isValidToken(): Promise<IUserTokenValidity> {
        return await AuthRepository.IsValidToken();
    }

    public async passwordVerify(password: IPasswordVerify): Promise<number> {
        return await AuthRepository.PasswordVerify(password);
    }
}
