import IUserActivityLog, {IUserActivityLogFilter, IUserActivityLogPaginator} from "../../Http/User/IUserActivityLog";
import UserActivityLogStorage from "../../Storage/User/UserActivityLogStorage";
import {IPaginator} from "../../Paginator/Paginator";

export default class UserActivityLogService {

    private readonly userActivityLogStorage: UserActivityLogStorage;
    constructor() {
        this.userActivityLogStorage = new UserActivityLogStorage();
    }

    public async findAll(page: IPaginator, filters: IUserActivityLogFilter): Promise<IUserActivityLogPaginator>  {
        return await this.userActivityLogStorage.findAll(page, filters);
    }

    public async persist(http: IUserActivityLog): Promise<void> {
        return await this.userActivityLogStorage.persist(http);
    }
}
