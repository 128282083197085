import React, {Component} from 'react';
import WithLayout from "../../../Infrastructure/HOC/Layout/WithLayout";
import withRouter from "../../../Infrastructure/HOC/Router/WithRouter";
import './NotFoundView.scss'

class NotFoundView extends Component<{}> {
    render() {
        return (
            <div className="not-found-container">
                <div className="not-found-content">
                    <img
                        src="https://static2.faktykaliskie.info/data/articles/xga-16x9-gonitwa-za-zblakanymi-kozami-w-akcji-straz-i-policja-1690163710.webp"
                        alt="404 Not Found" className="not-found-image"/>
                    <h1 className="not-found-title">404 - Strona nie istnieje</h1>
                    <p className="not-found-description">Zapędziłeś się w kozi róg</p>
                    <audio autoPlay>
                        <source src="/static/media/koza.mp3" type="audio/mpeg"/>
                        Twoja przeglądarka nie jest w stanie odtworzyć tego dźwięku
                    </audio>
                </div>
            </div>
        );
    }
}

export default WithLayout(withRouter(NotFoundView));
