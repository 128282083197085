import React, { Component } from 'react';
import {
    Box,
    Divider,
    FormControlLabel,
    List,
    ListItem,
    ListItemIcon,
    Switch,
    Typography,
    TextField
} from '@mui/material';
import { Link } from 'react-router-dom';
import WithLayout from "../../../Infrastructure/HOC/Layout/WithLayout";
import withRouter from "../../../Infrastructure/HOC/Router/WithRouter";
import { UserAvatar } from "../../../Infrastructure/Component/User/Avatar/UserAvatar";
import InfiniteScroll from '../../../Infrastructure/Component/Shared/InfiniteScroll/InfiniteScroll';
import UserAdapter from "../../User/Adapter/UserAdapter";
import { IUserNotification } from "../../../Infrastructure/Http/User/HttpUserNotification";

interface NotificationListViewState {
    notifications: IUserNotification[];
    hasMore: boolean;
    page: number;
    showUnreadOnly: boolean;
    searchQuery: string;
}

class NotificationListView extends Component<{}, NotificationListViewState> {
    private pageSize: number = 20;
    private readonly userAdapter: UserAdapter;

    constructor(props: {}) {
        super(props);
        this.state = {
            notifications: [],
            hasMore: true,
            page: 1,
            showUnreadOnly: false,
            searchQuery: '',
        };
        this.userAdapter = new UserAdapter();
        this.loadMoreNotifications = this.loadMoreNotifications.bind(this);
        this.handleNotificationClick = this.handleNotificationClick.bind(this);
        this.toggleShowUnreadOnly = this.toggleShowUnreadOnly.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    private async fetchNotifications(page: number) {
        return this.userAdapter.notifications(page);
    }

    private async loadMoreNotifications(): Promise<void> {
        const { page } = this.state;
        const newNotifications: IUserNotification[] = await this.fetchNotifications(page);

        this.setState({
            notifications: [...this.state.notifications, ...newNotifications],
            hasMore: newNotifications.length === this.pageSize,
            page: page + 1,
        });
    }

    private async handleNotificationClick(notificationId: string) {
        try {
            await this.userAdapter.readNotificationById(notificationId);
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    }

    private toggleShowUnreadOnly(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ showUnreadOnly: event.target.checked });
    }

    private handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ searchQuery: event.target.value });
    }

    private formatDateTime(dateString: string): string {
        const date: Date = new Date(dateString);
        return date.toLocaleDateString('pl-PL', { year: 'numeric', month: 'long', day: 'numeric' }) + ', ' +
            date.toLocaleTimeString('pl-PL', { hour: '2-digit', minute: '2-digit' });
    }

    render() {
        const { notifications, hasMore, showUnreadOnly, searchQuery } = this.state;

        const sortedNotifications = notifications.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

        const filteredNotifications: IUserNotification[] = sortedNotifications
            .filter(notification =>
                (!showUnreadOnly || notification.isActive) &&
                (notification.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    notification.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    notification.description.toLowerCase().includes(searchQuery.toLowerCase()))
            );

        return (
            <Box
                className="notification-list"
                sx={{
                    maxWidth: 1600,
                    margin: '0 auto',
                    padding: 2,
                    backgroundColor: 'var(--container-background)',
                    color: 'var(--text)',
                    borderRadius: 2,
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
                }}
            >
                <Typography
                    variant="h4"
                    component="h1"
                    sx={{ mb: 2, fontWeight: 'bold', color: 'var(--text)' }}
                >
                    Powiadomienia
                </Typography>
                <TextField
                    label="Szukaj powiadomień"
                    variant="outlined"
                    fullWidth
                    value={searchQuery}
                    onChange={this.handleSearchChange}
                    sx={{ mb: 2, backgroundColor: 'var(--background)', color: 'var(--text)', borderRadius: 1 }}
                    InputLabelProps={{
                        style: { color: 'var(--text)' },
                    }}
                    InputProps={{
                        style: { color: 'var(--text)' },
                    }}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={showUnreadOnly}
                            onChange={this.toggleShowUnreadOnly}
                            color="primary"
                        />
                    }
                    label="Pokaż tylko nieprzeczytane"
                    sx={{ color: 'var(--text)', mb: 2 }}
                />
                <InfiniteScroll loadMore={this.loadMoreNotifications} hasMore={hasMore}>
                    <List>
                        {filteredNotifications.map((notification) => (
                            <React.Fragment key={notification.id}>
                                <ListItem
                                    component={Link}
                                    to={`/${notification.type}/${notification.resourceId}`}
                                    onClick={() => this.handleNotificationClick(notification.id)}
                                    sx={{
                                        borderRadius: 2,
                                        borderLeft: notification.isActive
                                            ? '4px solid var(--primary)'
                                            : 'none',
                                        backgroundColor: notification.isActive
                                            ? 'var(--container-background)'
                                            : 'var(--table-row-active-background)',
                                        padding: 2,
                                        transition: 'background-color 0.3s, box-shadow 0.3s',
                                        display: 'flex',
                                        alignItems: 'center',
                                        '&:hover': {
                                            backgroundColor: 'var(--table-background)',
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                                        },
                                    }}
                                >
                                    <ListItemIcon>
                                        <UserAvatar
                                            firstName={notification.firstName}
                                            lastName={notification.lastName}
                                        />
                                    </ListItemIcon>
                                    <Box sx={{ flex: 1 }}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'var(--text)' }}>
                                            {notification.firstName} {notification.lastName}
                                        </Typography>
                                        <Typography variant="body2">
                                            {notification.description}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            ml: 'auto',
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: 'var(--text)',
                                        }}
                                    >
                                        <Typography variant="body2">
                                            {this.formatDateTime(notification.createdAt)}
                                        </Typography>
                                    </Box>
                                </ListItem>
                                <Divider sx={{ backgroundColor: 'var(--deactivated)' }} />
                            </React.Fragment>
                        ))}
                    </List>
                </InfiniteScroll>
            </Box>
        );
    }
}

export default WithLayout(withRouter(NotificationListView));
