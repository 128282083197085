import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import "./ProfileHeaderStyle.scss";
import {Link} from "react-router-dom";
import {UserRole} from "../../../Http/User/IUser";
import {UserAvatar} from "../Avatar/UserAvatar";

export interface IProfileHeaderProps {
    image?: any
    canEdit: boolean;
    firstName: string;
    lastName: string;
    userName: string;
    roles: UserRole[];
    editRoute: string;
}

export interface IProfileHeaderState {}

export class ProfileHeader extends Component<IProfileHeaderProps, IProfileHeaderState> {
    constructor(props: IProfileHeaderProps) {
        super(props);
        this.state = {};
    }

    render() {
        const { image, firstName, lastName, userName, roles, editRoute } = this.props;

        return (
            <div className="profile-header">
                <div className="profile-header_container">
                    <div className="profile-header_container_body">
                        <div className="profile-header_container_body_edit">
                            {this.props.canEdit && (
                                <Link to={editRoute}>
                                    <Button variant="contained" startIcon={<EditIcon />}>
                                        Edytuj
                                    </Button>
                                </Link>
                            )}
                        </div>
                        <div className="profile-header_container_body_avatar">
                            <UserAvatar firstName={this.props.firstName} lastName={this.props.lastName} mode={'large'}/>
                        </div>
                        <div className="profile-header_container_body_info">
                            <p className="profile-header_container_body_info_full_name">
                                {firstName} {lastName}
                            </p>
                            <p className="profile-header_container_body_info_username">
                                {userName}
                            </p>
                            <p className="profile-header_container_body_info_role">
                                {Array.isArray(roles) ? (
                                    roles.map((role, idx) => (
                                        <span key={idx}>{role}</span>
                                    ))
                                ) : (
                                    <span>No roles available</span>
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
