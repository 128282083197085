import React, {Component} from 'react';
import {AlertContainer} from "../AlertContainer";

export interface ILoggedOutAlertProps {

}

export interface ILoggedOutAlertState {

}

export default class LoggedOutAlert extends Component<ILoggedOutAlertProps, ILoggedOutAlertState> {
    constructor(props: ILoggedOutAlertProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <AlertContainer variant="filled" severity="error">
              Zostałeś wylogowany, w celu korzystania oprogramowania, zaloguj się ponownie
            </AlertContainer>
        );
    }
}
