import React, { Component } from 'react';
import { CircularProgress, Box } from '@mui/material';

interface InfiniteScrollProps {
    loadMore: () => Promise<void>;
    hasMore: boolean;
    threshold?: number;
    loader?: React.ReactNode;
    children: React.ReactNode;
}

interface InfiniteScrollState {
    isLoading: boolean;
}

class InfiniteScroll extends Component<InfiniteScrollProps, InfiniteScrollState> {
    private observer: IntersectionObserver | null = null;

    static defaultProps = {
        threshold: 0.8,
        loader: <CircularProgress />,
    };

    constructor(props: InfiniteScrollProps) {
        super(props);
        this.state = {
            isLoading: false,
        };
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    componentDidMount() {
        this.setupObserver();
    }

    componentDidUpdate(prevProps: InfiniteScrollProps) {
        if (prevProps.hasMore !== this.props.hasMore && this.props.hasMore) {
            this.setupObserver();
        }
    }

    componentWillUnmount() {
        this.cleanupObserver();
    }

    setupObserver() {
        if (this.observer) {
            this.observer.disconnect();
        }

        this.observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    this.loadMoreItems();
                }
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: this.props.threshold,
            }
        );

        const lastChild = document.querySelector('.infinite-scroll-loader');
        if (lastChild) {
            this.observer.observe(lastChild);
        }
    }

    cleanupObserver() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    async loadMoreItems() {
        if (this.state.isLoading || !this.props.hasMore) {
            return;
        }

        this.setState({ isLoading: true });

        try {
            await this.props.loadMore();
        } catch (error) {
            console.error('Error loading more items:', error);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    render() {
        const { children, loader, hasMore } = this.props;
        const { isLoading } = this.state;

        return (
            <div>
                {children}
                {hasMore && (
                    <div
                        className="infinite-scroll-loader"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '16px',
                        }}
                    >
                        {isLoading ? loader : null}
                    </div>
                )}
            </div>
        );
    }
}

export default InfiniteScroll;
