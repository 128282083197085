import React, {Component} from 'react';
import {RecordingMetadataItem} from "./RecordingMetadataItem";
import "./RecordingMetadaStyle.scss";
import {Divider} from "@mui/material";
import {Link} from "react-router-dom";
import {AuthorizedPath} from "../../../Route/Authorized/AuthorizedPath";
import {IRecording} from "../../../Http/Recording/IRecording";
export interface IRecordingMetadataProps {
  recording: IRecording;
}

export interface IRecordingMetadataState {

}

export class RecordingMetadata extends Component<IRecordingMetadataProps, IRecordingMetadataState> {
    constructor(props: IRecordingMetadataProps) {
        super(props);
        this.state = {};
    }
    private get duration() {
        const seconds = Number(this.props.recording?.file?.duration);
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.round(seconds % 60);
        return `${minutes}m ${remainingSeconds}s`;
    }
    render() {
        return (
            <div className="recording-metadata">
                <div className="recording-metadata_container">
                  <div className="recording-metadata_container_header">
                      <div className="recording-metadata_container_header_label">
                        <span>Metadane</span>
                      </div>

                      <div className="recording-metadata_container_header_divider">
                        <Divider/>
                      </div>
                  </div>

                  <div className="recording-metadata_container_body">
                      <RecordingMetadataItem
                          label={"Użytkownika"}
                          value={`${this.props.recording?.user?.firstName.toString()} ${this.props.recording?.user?.lastName.toString()}`}
                          link={AuthorizedPath.USER(this.props.recording?.user?.id?.toString())}
                      />

                      <RecordingMetadataItem
                          label={"Identyfikator kamery"}
                          value={`${this.props.recording?.camera?.serialNumber?.toString()}`}
                          link={AuthorizedPath.CAMERA(this.props.recording?.camera?.id?.toString())}
                      />
                      <RecordingMetadataItem label={"Czas trwania"} value={this.duration}/>

                      <RecordingMetadataItem label={"Data nagrania od"} value={this.props.recording?.file?.recordedAt?.toString()}/>

                      <RecordingMetadataItem label={"Data nagrania do"} value={this.props.recording?.file?.endedAt?.toString()}/>

                      <RecordingMetadataItem label={"Dowód"} value={this.props.recording?.isEvidence ? 'Tak' : 'Nie'}/>
                  </div>
                  <div className="recording-metadata_container_footer">
                      <RecordingMetadataItem label={"Suma kontrolna"} value={this.props.recording?.file?.checksum} canCopy={true}/>
                  </div>
                </div>
            </div>
        );
    }
}