import React, {Component} from 'react';
import "./TableTextFieldFilterStyle.scss";
import TextBox, {ITextBoxProps} from "../../../TextBox/TextBox";
export interface ITableTextFieldFilterProps extends ITextBoxProps {
    label: string;
    onFilterChange: (e: string) => void;
}

export interface ITableTextFieldFilterState {

}

export default class TableTextFieldFilter extends Component<ITableTextFieldFilterProps, ITableTextFieldFilterState> {

    constructor(props: ITableTextFieldFilterProps) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.state = {}
    }

    private onChange(e: string) {
        this.props.onFilterChange(e);
    }

    render() {
        return (
            <div className="table-text-field-filter">
                <div className="table-text-field-filter_label">
                    <span>{this.props.label}</span>
                </div>
                <div className="table-text-field-filter_input">
                    <TextBox  type={this.props.type} value={this.props.value}  onChange={(e) => this.onChange(e?.target?.value)}/>
                </div>
            </div>
        );
    }
}