import React, {Component} from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import "./RecordingMetadataItemStyle.scss";
import {IconButton} from "@mui/material";
import {Link} from "react-router-dom";
export interface IRecordingMetadataItemProps {
  label: string;
  value?: string;
  link?: string;
  canCopy?: boolean;
}

export interface IRecordingMetadataItemState {
    copiedSuccessfully: boolean;
}

export class RecordingMetadataItem extends Component<IRecordingMetadataItemProps, IRecordingMetadataItemState> {
    constructor(props: IRecordingMetadataItemProps) {
        super(props);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.state = {
            copiedSuccessfully: false
        };
    }

    private async copyToClipboard(){
        try {
            await navigator?.clipboard?.writeText(this.props.value ?? "").then(() => {
                this.setState({copiedSuccessfully: true})
            })
        } catch (error) {
        }
    }

    render() {
        return (
            <div className="recording-metadata-item">
                <div className="recording-metadata-item_label">
                    <span>{this.props.label}</span>
                </div>
                <div className="recording-metadata-item_value">
                    {this.props.canCopy && (
                        <IconButton onClick={this.copyToClipboard}>
                            <ContentCopyIcon color={this.state.copiedSuccessfully? "success": "primary"}/>
                        </IconButton>
                    )}

                    {!this.props.value && (
                        <span role="error">Brak</span>
                    )}

                    {!this.props.link && this.props.value && (
                        <span>{this.props.value}</span>
                    )}

                    {this.props.link && this.props.value && (
                        <Link to={this.props.link}>{this.props.value}</Link>
                    )}

                </div>
            </div>
        );
    }
}
