import {GetHttpClient} from "../../HttpClient/Factory/HttpClientFactory";
import {IRecordingEvidence} from "../../Http/Recording/IRecording";

export default class RecordingEvidenceRepository {
    public static async Persist(id: number, http: IRecordingEvidence): Promise<IRecordingEvidence> {
        let url: string = `/api/recordings/${id}/evidence`;
        return GetHttpClient().POST(url, http).then((response) => {
            return response.data
        })
    }

    public static async Update(recordingId: number, evidenceId: number | undefined, http: IRecordingEvidence): Promise<IRecordingEvidence> {
        let url: string = `/api/recordings/${recordingId}/evidence/${evidenceId}`;
        return GetHttpClient().PUT(url, http).then((response) => {
            return response.data
        })
    }

    public static async Delete(recordingId: number, evidenceId: number | undefined): Promise<void> {
        let url: string = `/api/recordings/${recordingId}/evidence/${evidenceId}`;
        return GetHttpClient().DELETE(url).then((response) => {
            return response.data
        })
    }
}
