import React, {Component} from 'react';
import {IListBoxItem, ListBox} from "../../Shared/ListBox/ListBox";
import IUser, {IUserPermissions} from "../../../Http/User/IUser";
import {FormControlLabel, Switch, Tooltip} from '@mui/material';
import "./UserPermissionsListStyle.scss";
import {ITableSelectItem, ITableSelectProps, TableSelect} from "../../Shared/TableSelect/TableSelect";
import {GridColDef} from "@mui/x-data-grid";
export interface IPermissionListProps{
    permissions: IUserPermissions[]
    value?: ITableSelectItem;
    onChange: (e: ITableSelectItem) => void;
    label: string;
}

export interface IPermissionListState {
    columns: GridColDef[];

}

export class UserPermissionList extends Component<IPermissionListProps, IPermissionListState> {
    constructor(props: IPermissionListProps) {
        super(props);
        this.state = {
            columns: [
                {
                    field: 'id', headerName: "ID", width: 120, type: 'string', editable: false
                },
                {
                    field: 'label', headerName: "Nazwa", width: 220, type: 'string', editable: false
                }
            ]
        };
    }

    private get rows(): ITableSelectItem[] {
        return this.props.permissions.map((item, idx) => {
            return {id: item.id, label: item.name}
        })
    }


    render() {
        return (
            <TableSelect
                label={this.props.label}
                placeholder={"Przykład - Uprawnienie administratora"}
                columns={this.state.columns}
                rows={this.rows}
                value={this.props.value}
                onChange={(e) => {
                    this.props.onChange(e);
                }}
            />
        );
    }
}
