import React, { Component } from 'react';
import './SideBarItemStyle.scss';
import { NavLink } from "react-router-dom";

export interface ISideBarItem {
    icon: any;
    label: string;
    route: string;
    active?: boolean;
    onClick?: () => void;
    collapsed?: boolean;
    newTab?: boolean
}

export interface ISideBarItemProps extends ISideBarItem {}

export interface ISideBarItemState {}

export default class SideBarItem extends Component<ISideBarItemProps, ISideBarItemState> {
    constructor(props: ISideBarItemProps) {
        super(props);
        this.state = {};
    }

    render() {
        const { icon, label, route, onClick, collapsed } = this.props;

        return (
            <NavLink
                className={({ isActive }) =>
                    [
                        "sidebar-item",
                        isActive ? "sidebar-item_active" : "",
                        collapsed ? "collapsed" : ""
                    ].join(" ")
                }
                to={route}
                target={this.props.newTab ? "_blank" : ""}
            >
                <div className="sidebar-item_icon">
                    {icon}
                </div>

                <div className={`sidebar-item_label ${collapsed ? 'hidden' : ''}`}>
                    {label}
                </div>
            </NavLink>
        );
    }
}
