import React, { Component } from 'react';
import IUser from "../../../Http/User/IUser";
import {ITableSelectItem, TableSelect} from "../../Shared/TableSelect/TableSelect";
import {GridColDef} from "@mui/x-data-grid";
import "./UsersListStyle.scss";

export interface IUsersListProps{
    users: IUser[];
    value?: ITableSelectItem;
    onChange: (e: ITableSelectItem) => void;
    label: string;
}

export interface IUsersListState {
    columns: GridColDef[];
}

export default class UsersList extends Component<IUsersListProps, IUsersListState> {
    constructor(props: IUsersListProps) {
        super(props);
        this.state = {
            columns: [
                {
                    field: 'id', headerName: "ID", width: 120, type: 'string', editable: false
                },
                {
                    field: 'label', headerName: "Nazwa", width: 220, type: 'string', editable: false
                }
            ]
        };
    }

    private get rows(): ITableSelectItem[] {
        return this.props.users.map((item) => {
            return { id: item.id.toString(), label: `${item.profile?.firstName} ${item.profile?.lastName}` };
        });
    }

    render() {
        return (
            <TableSelect
                label={this.props.label}
                placeholder={"Przykład - Jan kowalski"}
                columns={this.state.columns}
                rows={this.rows}
                value={this.props.value}
                onChange={(e) => {
                    this.props.onChange(e);
                }}
            />
        );
    }
}
