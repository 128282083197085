import React, {Component} from 'react';
import "./RecordingTableThumbnailStyle.scss";
import {Link} from "react-router-dom";
import {AuthorizedPath} from "../../../Route/Authorized/AuthorizedPath";
export interface IRecordingTableThumbnailProps {
  id: number;
  title: string;
  sourceUrl: string;
}

export interface IRecordingTableThumbnailState {

}

export class RecordingTableThumbnail extends Component<IRecordingTableThumbnailProps, IRecordingTableThumbnailState> {
    constructor(props: IRecordingTableThumbnailProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Link className="recording-table-thumbnail" to={AuthorizedPath.RECORDING(this.props.id?.toString())} >
              <div className="recording-table-thumbnail_image">
                <video src={this.props.sourceUrl}/>
              </div>
              <div className="recording-table-thumbnail_title">
                {/*  Hidden because it overlaps other columns*/}
                {/*<span>{this.props.title}</span>*/}
              </div>
            </Link>
        );
    }
}
