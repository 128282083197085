import React, {Component} from 'react';
import {
    DataGrid,
    DataGridProps,
    GridColDef,
    GridCsvExportMenuItem,
    GridPrintExportMenuItem,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridToolbarFilterButton,
    plPL
} from "@mui/x-data-grid";
import {GridRowsProp} from "@mui/x-data-grid/models/gridRows";
import {GridRowSelectionModel} from "@mui/x-data-grid/models/gridRowSelectionModel";
import {GridCallbackDetails} from "@mui/x-data-grid/models/api";
import {GridTxtExportMenuItem} from "./ToolBar/GridTxtExportMenuItem";
import "./TableStyle.scss";

export interface ITableProps extends DataGridProps {
    label?: string;
    description?: string;
    columns: GridColDef[];
    rows: GridRowsProp;
    loading?: any;
    onView?: (id: string) => void;
    onDelete?: (id: string) => void;
    onSelectionChanged?: (rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) => void;
    onPageChanged?: (page: number) => void;
}

export interface ITableState {}

export default class Table extends Component<ITableProps, ITableState> {
    constructor(props: ITableProps) {
        super(props);
        this.state = {}
    }

    private toolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarExportContainer>
                    <GridCsvExportMenuItem/>
                    <GridTxtExportMenuItem rows={this.props.rows}/>
                    <GridPrintExportMenuItem/>
                </GridToolbarExportContainer>
            </GridToolbarContainer>
        )
    }

    render() {
        return (
            <div className="table w-full" style={{ height: 'auto', width: '100%' }}>
                <div className="table_container">
                    <div className="table_container_header">
                        {this.props.label && (
                            <h1 className="table_container_header_label">{this.props.label}</h1>
                        )}
                        {this.props.description && (
                            <p className="table_container_header_description">{this.props.description}</p>
                        )}
                    </div>

                    <div className="table_container_body">
                        <DataGrid
                            {...this.props}
                            sx={{
                                boxShadow: 0,
                                border: 0,
                                borderColor: 'transparent',
                                overflowX: "scroll"
                            }}
                            slots={{
                                toolbar: this.toolbar
                            }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 200 }
                                },
                            }}
                            localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
                            columns={this.props.columns}
                            rows={this.props.rows}
                            onRowSelectionModelChange={this.props.onSelectionChanged}
                            loading={this.props.loading}
                            autoHeight
                        />
                    </div>
                </div>
            </div>
        );
    }
}
