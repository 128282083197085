import HTTPClient from "../HTTPClient";
import InvalidAuthTokenException from "../../Exception/Shared/InvalidAuthTokenException";
import NotFoundException from "../../Exception/Shared/NotFoundException";
import InternalErrorException from "../../Exception/Shared/InternalErrorException";
import ForbiddenActionException from "../../Exception/Shared/ForbiddenActionException";
import {REACT_APP_API_URL} from "../../../env";


export const GetHttpClient = () => {
    const headers: any = {
        'Content-Type': 'application/json'
    };


    const httpClient = new HTTPClient({
        baseURL: REACT_APP_API_URL,
        headers: headers
    })

    httpClient.getInstance().interceptors.request.use(config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    });

    httpClient.getInstance().interceptors.response.use(
        response => response,
        error => {
            const status = error.response ? error.response.status : null;

            switch (status) {
                case 401:
                    return Promise.reject(new InvalidAuthTokenException());
                case 403:
                    return Promise.reject(new ForbiddenActionException());
                case 404:
                    return Promise.reject(new NotFoundException());
                case 500:
                    return Promise.reject(new InternalErrorException());
                default:
                    return Promise.reject(error);
            }
        }
    );

    return httpClient;
}

