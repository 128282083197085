import React, { Component } from 'react';
import './AbstractLayoutStyle.scss';
import SideBar from "../../Component/Shared/SideBar/SideBar";
import { AuthorizedPath } from "../../Route/Authorized/AuthorizedPath";
import { PageLoader } from "../../Component/Shared/Loader/PageLoader/PageLoader";
import { SidebarMenuFactory } from "../../Component/Shared/SideBar/Factory/SidebarMenuFactory";
import UserService from "../../Service/User/UserService";
import SettingsAdapter from "../../../Application/Settings/SettingsAdapter";
import { ISettings } from "../../Http/Settings/ISettings";
import { ISideBarItem } from "../../Component/Shared/SideBar/Item/SideBarItem";
import IUser from "../../Http/User/IUser";

export interface IAbstractLayoutChildrenProps {
    currentUser: IUser;
    settings: ISettings
}

export interface IAbstractLayoutProps {
    children: any | undefined | IAbstractLayoutChildrenProps;
}

export interface IAbstractLayoutState {
    currentUser: IUser;
    settings: ISettings;
    menuItems: ISideBarItem[];
    underlineitems: ISideBarItem[];
    isSidebarCollapsed: boolean;
}

export default class AbstractLayout extends Component<IAbstractLayoutProps, IAbstractLayoutState> {

    private readonly userService: UserService;
    private readonly settingsAdapter: SettingsAdapter;

    constructor(props: IAbstractLayoutProps) {
        super(props);
        this.userService = new UserService();
        this.settingsAdapter = new SettingsAdapter();
        const isSidebarCollapsed = localStorage.getItem('collapsed') === 'true';

        this.state = {
            currentUser: {} as IUser,
            settings: {} as ISettings,
            menuItems: [],
            underlineitems: [],
            isSidebarCollapsed: isSidebarCollapsed
        }
    }

    private async loadUserData() {
        try {
            const currentUser = await this.userService.me();
            this.setState({ currentUser });
        } catch (error) {
            this.userService.logout();
        }
    }

    private async fetchSettings(): Promise<void> {
        const settings = await this.settingsAdapter.findSettings();
        this.setState({ settings });
    }

    componentDidUpdate(prevProps: Readonly<IAbstractLayoutProps>, prevState: Readonly<IAbstractLayoutState>) {
        if (this.state.settings !== prevState.settings || this.state.currentUser !== prevState.currentUser) {
            this.setState({
                menuItems: SidebarMenuFactory.CreateMenuItems(this.state.currentUser, this.state.settings),
                underlineitems: SidebarMenuFactory.CreateUnderlineMenuItems(this.state.currentUser)
            });
        }
    }

    componentDidMount() {
        this.fetchSettings();
        this.loadUserData();

        this.updateSidebarWidth();
    }

    updateSidebarWidth() {
        const sidebarWidth = this.state.isSidebarCollapsed ? '47px' : '247px';
        document.documentElement.style.setProperty('--sidebar-width', sidebarWidth);
    }

    render() {
        const { currentUser, menuItems, underlineitems } = this.state;

        return (
            <div className="abstract-layout">
                <div className="abstract-layout_container">
                    <div className="abstract-layout_container_body">
                        <div className="abstract-layout_container_body_sidebar">
                            {currentUser?.id > 0 && (
                                <SideBar
                                    profile={{
                                        redirectRoute: AuthorizedPath.USER(currentUser.id?.toString()),
                                        firstName: currentUser?.profile?.firstName,
                                        lastName: currentUser?.profile?.lastName,
                                        userName: currentUser?.userName,
                                        roles: currentUser?.roles
                                    }}
                                    userId={currentUser.id}
                                    items={menuItems}
                                    underLinedItems={underlineitems}
                                    onLogout={() => this.userService.logout()}
                                />
                            )}
                        </div>
                        <div className="abstract-layout_container_body_content">
                            {!currentUser?.id && <PageLoader />}
                            {currentUser?.id > 0 && React.cloneElement(
                                this.props.children,
                                {
                                    currentUser: currentUser,
                                    settings: this.state.settings
                                } as IAbstractLayoutChildrenProps
                            )}
                        </div>
                    </div>
                    <div className="abstract-layout_container_footer"></div>
                </div>
            </div>
        );
    }
}
