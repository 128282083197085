import {ICamera} from "../../Http/Camera/ICamera";
import {GetHttpClient} from "../../HttpClient/Factory/HttpClientFactory";
import IDiskSpaceInfo from "../../Http/Disk/IDiskSpaceInfo";

export default class DiskRepository {
    public static async FindSpaceInfo(): Promise<IDiskSpaceInfo> {
        let url: string = `/api/core/disk/info`;

        return  GetHttpClient().GET(url).then((response) => {
            return response.data
        })
    }
}