import React, {Component} from 'react';
import './MyUserViewStyle.scss';
import WithLayout from "../../../../Infrastructure/HOC/Layout/WithLayout";
import withRouter, {IRouterProps} from "../../../../Infrastructure/HOC/Router/WithRouter";
import {ProfileHeader} from "../../../../Infrastructure/Component/User/ProfileHeader/ProfileHeader";
import example_user_profile from "../../../../Infrastructure/Assets/Svg/example_user_profile.svg";
import UserAdapter from "../../Adapter/UserAdapter";
import {AuthorizedPath} from "../../../../Infrastructure/Route/Authorized/AuthorizedPath";
import {IAbstractLayoutChildrenProps} from "../../../../Infrastructure/HOC/Layout/AbstractLayout";
import {UserRecordingsList} from "../../../../Infrastructure/Component/User/RecordingsList/UserRecordingsList";
import RecordingAdapter from "../../../Recording/Adapter/RecordingAdapter";
import {GridRowsProp} from "@mui/x-data-grid";
import {UserActivityLogList} from "../../../../Infrastructure/Component/User/ActivityLogList/UserActivityLogList";
import UserActivityLogAdapter from "../../Adapter/UserActivityLogAdapter";
import CameraAdapter from "../../../Camera/Adapter/CameraAdapter";
import { CamerasTable } from '../../../../Infrastructure/Component/Camera/CamerasTable/CamerasTable';
import IUser from "../../../../Infrastructure/Http/User/IUser";

export interface MyUserViewProps extends IAbstractLayoutChildrenProps {

}

export interface MyUserViewState {
    canEdit: boolean;
    recordingRows: GridRowsProp;
    isRecordingsTableLoading: boolean;

    camerasRows: GridRowsProp;
    isCamerasTableLoading: boolean;

    activityLogRows: GridRowsProp;
    isActivityLogsTableLoading: boolean;
}

class MyUserView extends Component<MyUserViewProps, MyUserViewState> {

    private readonly userAdapter: UserAdapter;
    private readonly userActivityLogAdapter: UserActivityLogAdapter;
    private readonly recordingAdapter: RecordingAdapter;
    private readonly cameraAdapter: CameraAdapter;

    constructor(props: MyUserViewProps) {
        super(props);
        this.userAdapter = new UserAdapter();
        this.userActivityLogAdapter = new UserActivityLogAdapter();
        this.recordingAdapter = new RecordingAdapter();
        this.cameraAdapter = new CameraAdapter();

        this.state = {
            canEdit: true,
            recordingRows: [],
            isRecordingsTableLoading: true,

            camerasRows: [],
            isCamerasTableLoading: true,

            activityLogRows: [],
            isActivityLogsTableLoading: true
        }
    }

    private async fetchRecordings() {
        await this.recordingAdapter.findAll({userId: Number(this.props.currentUser?.id)}).then((page) => {
            const rows = page.items?.map((row) => {
                return {
                    id: row.id,
                    title: row.title,
                    url: row.file?.url,
                    userId: row.user?.id,
                    startDate: row.file?.recordedAt,
                    endDate: row.file?.endedAt,
                    isEvidence: row.isEvidence
                }
            });
            this.setState({recordingRows: rows, isRecordingsTableLoading: false});
        }).catch((error) => {
            this.setState({isRecordingsTableLoading: false})
        })
    }

    private async fetchActivityLogs() {
        await this.userActivityLogAdapter.findAll({page: 1, limit: 50}, {userId : Number(this.props.currentUser?.id) }).then((page) => {
            const rows = page.items?.map((row) => {
                return {
                    id: row.id,
                    description: row.description,
                    createdAt: row.createdAt,
                }
            });
            this.setState({activityLogRows: rows, isActivityLogsTableLoading: false});
        }).catch((error) => {
            this.setState({isActivityLogsTableLoading: false})
        })
    }

    componentDidMount() {
        this.fetchRecordings();
        this.fetchActivityLogs();
    }

    render() {
        return (
            <div className="single-user-profile">
                <div className="single-user-profile_container">
                    <div className="single-user-profile_container_header">
                        {this.props.currentUser?.id && (
                            <ProfileHeader
                                firstName={this.props.currentUser?.profile?.firstName ?? "-"}
                                lastName={this.props.currentUser?.profile?.lastName ?? "-"}
                                userName={this.props.currentUser?.userName ?? "-"}
                                canEdit={this.state.canEdit}
                                editRoute={AuthorizedPath.USER_EDIT(this.props.currentUser?.id?.toString() ?? "0")}
                                roles={this.props.currentUser?.roles}
                            />
                        )}
                    </div>

                    <div className="single-user-profile_container_body">
                        <div className="single-user-profile_container_body_segment">
                            <div className="single-user-profile_container_body_segment_label">
                                <span>Nagrania</span>
                            </div>
                            <div className="single-user-profile_container_body_segment_content">
                                <UserRecordingsList
                                    rows={this.state.recordingRows}
                                    isTableLoading={this.state.isRecordingsTableLoading}
                                />
                            </div>
                        </div>
                        <div className="single-user-profile_container_body_segment">
                            <div className="single-user-profile_container_body_segment_label">
                                <span>Logi</span>
                            </div>
                            <div className="single-user-profile_container_body_segment_content">
                                <UserActivityLogList
                                    rows={this.state.activityLogRows}
                                    isTableLoading={this.state.isActivityLogsTableLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(WithLayout(MyUserView));
