import {IRecording, IRecordingFilter} from "../../Http/Recording/IRecording";
import {IRecordingPaginator} from "../../Http/Recording/IRecordingPaginator";
import RecordingRepository from "../../Repository/Recording/RecordingRepository";
import {IRecordingNote} from "../../Http/Recording/IRecordingNote";
import {GetHttpClient} from "../../HttpClient/Factory/HttpClientFactory";
import RecordingNoteRepository from "../../Repository/Recording/RecordingNoteRepository";

export default class RecordingNoteService {

    public async findAll(id: number): Promise<IRecordingNote[]> {
       return await RecordingNoteRepository.FindAll(id);
    }

    public async findById(id: number): Promise<IRecording> {
        return await RecordingNoteRepository.FindById(id);
    }

    public async persist(id: number, http: IRecordingNote): Promise<IRecordingNote> {
        return await RecordingNoteRepository.Persist(id, http);
    }

    public async update(id: number, http: IRecordingNote): Promise<IRecordingNote> {
        return await RecordingNoteRepository.Update(id, http);
    }

    public async delete(id: number): Promise<any> {
        return await RecordingNoteRepository.Delete(id);
    }
}