import React, {Component} from 'react';
import WithLayout from "../../../../Infrastructure/HOC/Layout/WithLayout";
import './UsersListViewStyle.scss';
import Table from "../../../../Infrastructure/Component/Shared/Table/Table";
import {GridColDef, GridRowsProp} from "@mui/x-data-grid";
import {Alert, Button, Link} from "@mui/material";
import UsersTableFilter from "../../../../Infrastructure/Component/User/Filter/UsersTableFilter";
import {AuthorizedPath} from "../../../../Infrastructure/Route/Authorized/AuthorizedPath";
import UserAdapter from "../../Adapter/UserAdapter";
import LoggedOutAlert from "../../../../Infrastructure/Component/Shared/AlertContainer/Alerts/LoggedOutAlert";
import {
    SomethingWentWrongAlert
} from "../../../../Infrastructure/Component/Shared/AlertContainer/Alerts/SomethingWentWrongAlert";
import {IUserDegree, IUserFilter, IUserPermissions} from "../../../../Infrastructure/Http/User/IUser";
import {IAbstractLayoutChildrenProps} from "../../../../Infrastructure/HOC/Layout/AbstractLayout";
import withRouter, {IRouterProps} from "../../../../Infrastructure/HOC/Router/WithRouter";
import UserDegreeService from "../../../../Infrastructure/Service/User/UserDegreeService";
import UserPermissionsService from "../../../../Infrastructure/Service/User/UserPermissionsService";
import {PageHeader} from "../../../../Infrastructure/Component/Shared/PageHeader/PageHeader";
export interface IUsersListViewProps extends IAbstractLayoutChildrenProps, IRouterProps {

}

export interface IUsersListViewState {
    columns: GridColDef[];
    rows: GridRowsProp;
    filters?: IUserFilter;
    availableDegress: IUserDegree[];
    availablePermissions: IUserPermissions[];
    isUsersTableLoading: boolean;
    isLoggedOut?: boolean;
}

class UsersListView extends Component<IUsersListViewProps, IUsersListViewState> {

    private readonly userAdapter: UserAdapter;
    private readonly userDegreeService: UserDegreeService;
    private readonly userPermissionsService: UserPermissionsService;
    constructor(props: IUsersListViewProps) {
        super(props);
        this.userAdapter = new UserAdapter();
        this.userDegreeService = new UserDegreeService();
        this.userPermissionsService = new UserPermissionsService();
        this.fetch = this.fetch.bind(this);
        this.onFiltersChanged = this.onFiltersChanged.bind(this);
        this.state = {
            rows: [],
            columns: [
                { field: 'id', headerName: "ID", width: 200, type: 'string',  editable: false, flex: 1 },
                { field: 'fullName',headerName: "Użytkownik",  width: 200, type: 'string', editable: false, flex: 1},
                { field: 'permissions', headerName: "Uprawnienia", width: 200, type: 'string',  editable: false, flex: 1 },
                { field: 'action', headerName: "Akcja", width: 200, type: 'string',  editable: false, flex: 1, renderCell: (params) => {
                    return  <Link href={AuthorizedPath.USER(params?.row?.id)}>Wyświetl</Link>
                }}
            ],
            filters: {} as IUserFilter,
            availableDegress: [],
            availablePermissions: [],
            isUsersTableLoading: true,
            isLoggedOut: undefined
        }
    }


    private async fetch() {
        await this.userPermissionsService.findAll().then((availablePermissions) => {
            this.setState({availablePermissions: availablePermissions})
        })
        await this.userAdapter.findAll({page: 1, limit: 20}, this.state.filters).then((iterator) => {
            const rows: GridRowsProp = iterator?.items?.map((row) => {
                return {
                    id: row?.id,
                    fullName: `${row?.profile?.firstName} ${row?.profile?.lastName}`,
                    permissions: row?.permissions?.name,
                }
            });

            this.setState({rows: rows, isUsersTableLoading: false});
        }).catch((error) => {
            this.setState({isUsersTableLoading: false})
        })
    }

    private onFiltersChanged(filter: IUserFilter) {
        this.setState({filters: filter}, () => {
            this.fetch()
        })
    }

    private verifyAccess() {
        if (!this.props.currentUser?.permissions?.canViewUser) {
            this.props.navigate(-1);
        }
    }

    componentDidMount() {
        this.verifyAccess();
        this.fetch()
    }

    render() {

        return (
            <div className="users">
                <div className="users_container">
                    <div className="users_container_header">
                        <PageHeader
                            label="Użytkownicy"
                            description={"W tej zakładce znajduję się lista użytkowników"}
                        />
                    </div>

                    <div className="users_container_body">

                        <div className="users_container_body_filters w-full">
                            <UsersTableFilter
                                onFilter={this.onFiltersChanged}
                                degrees={this.state.availableDegress}
                                permissions={this.state.availablePermissions}
                            />
                        </div>

                        <div className="users_container_body_table">
                            <Table
                                columns={this.state.columns}
                                rows={this.state.rows}
                                loading={this.state.isUsersTableLoading}
                            />
                        </div>

                    </div>

                    <div className="users_container_footer">
                        {this.state.isLoggedOut && <LoggedOutAlert/> }
                        {this.state.isLoggedOut && <SomethingWentWrongAlert/> }
                    </div>
                </div>
            </div>
        );
    }
}

export default WithLayout(withRouter(UsersListView));
