import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom'; // Importuj useNavigate z React Router
import './NotificationIcon.scss';
import {Notifications} from '@mui/icons-material';
import UserAdapter from "../../../../Application/User/Adapter/UserAdapter";
import {IUserNotification} from "../../../Http/User/HttpUserNotification";
import SSEManager from "../../../SSEManager/SSEManager";
// @ts-ignore
import notificationSound from "./Resource/notification.wav";

interface NotificationIconProps {
    userId: number
}

export const NotificationIcon: React.FC<NotificationIconProps> = ({userId}) => {
    const navigate = useNavigate();
    const userAdapter = new UserAdapter();
    const audioRef = useRef<HTMLAudioElement>(null);

    const notificationTopic: string = `mercure://users/notifications/${userId}`;
    const [totalNotifications, setTotalNotifications] = useState(0)
    const handleClick = () => {
        navigate('/notifications');
    };


    async function fetchTotalNotifications() {
        await userAdapter.notifications().then((notifications: IUserNotification[]) => {
            const totalNotifications = notifications.filter(notification => notification?.isActive).length;
            setTotalNotifications(totalNotifications);
        })
    }

    function showDesktopNotification(): void {
        if (Notification.permission === 'granted') {
            new Notification(`KTAVisor: Otrzymałeś nowe powiadomienie`, {
                body: `Kliknij by zobaczyć nieprzeczytane powiadomienia`,
            }).onclick = () => {
                navigate('/notifications');
            };
        }
    }

    function askForNotificationPermission(): void {
        if (Notification.permission !== 'granted') {
            Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                } else {
                    console.log('Notification permission denied.');
                }
            });
        }
    }
    async function handleSSE() {
        showDesktopNotification();
        await fetchTotalNotifications().then(() => {
            if (audioRef.current) {
                audioRef.current.play().catch(error => {
                    console.error('Failed to play sound:', error);
                });
            }
        });
    }

    useEffect(() => {
        askForNotificationPermission();
        fetchTotalNotifications().then();
    })

    return (
        <SSEManager topics={[notificationTopic]} onUpdate={handleSSE}>
            <div className={`notification-icon ${totalNotifications > 0 ? 'filled' : 'empty'}`} onClick={handleClick}>
                <audio ref={audioRef} src={notificationSound} preload="auto"></audio>

                <Notifications/>
                {totalNotifications > 0 && (
                    <span className="notification-count">
                        {totalNotifications}
                    </span>
                )}
            </div>
        </SSEManager>

    );
};
