import React, {Component} from 'react';
import {REACT_APP_MERCURE_URL} from "../../env";

export interface ISSEManagerProps<T> {
    hub?: string;
    topics: string[];
    onUpdate: (data: T) => void;
    children?: any;
}
interface ISSEManagerState<T> {
    lastMessage: T
}

export default class SSEManager<T> extends Component<ISSEManagerProps<T>, ISSEManagerState<T>>{


    private  eventSource: EventSource|undefined;
    constructor(props: any) {
        super(props);
        this.state = {
            lastMessage: {} as T
        }
    }

    private onUpdate(e: MessageEvent) {
        let data = JSON.parse(e.data);
        if (data instanceof Object) {
            data = data as T
        }

        if (this.state.lastMessage === data) {
            return;
        }


        this.setState({lastMessage: data}, () => {
            this.props.onUpdate(data)
        })
    }

    private startEventSource() {
        const url = new URL(REACT_APP_MERCURE_URL);
        this.props.topics.forEach(function(topic) {
            url.searchParams.append('topic', topic);
        });


        this.eventSource = new EventSource(url);
        this.eventSource.onmessage =  (e) => {
            this.onUpdate(e);
        }
    }

    private stopEventSource() {
        if (!this.eventSource) {
            return;
        }
        this.eventSource.close();
    }


    componentWillUnmount() {
        this.stopEventSource()
    }

    componentDidMount() {
        this.startEventSource()
    }

    render() {
        return (
            <div className="event-cluster w-full">
                {this.props.children}
            </div>
        );
    }

}
