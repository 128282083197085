import {IUserPermissions} from "../../Http/User/IUser";
import {GetHttpClient} from "../../HttpClient/Factory/HttpClientFactory";
import {ICameraPaginator} from "../../Http/Camera/ICameraPaginator";
import {ICameraFIlter} from "../../Http/Camera/ICamera";
import ICameraQuality from "../../Http/Camera/ICameraQuality";
import ICameraResolution from "../../Http/Camera/ICameraResolution";

export default class CameraCoreRepository {

    public static async FindAllResolutions(): Promise<ICameraResolution[]> {
        let url: string = `/api/core/camera/resolutions`;
        return  GetHttpClient().GET(url).then((response) => {
            return response.data
        })
    }
    public static async FindAllQualities(): Promise<ICameraQuality[]> {
        let url: string = `/api/core/camera/qualities`;
        return  GetHttpClient().GET(url).then((response) => {
            return response.data
        })
    }
}