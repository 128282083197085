import {IUserAuthCredentials} from "../../Infrastructure/Http/Auth/IUserAuthCredentials";
import {IUserAuthToken} from "../../Infrastructure/Http/Auth/IUserAuthToken";
import AuthRepository from "../../Infrastructure/Repository/Auth/AuthRepository";
import AuthService from "../../Infrastructure/Service/Auth/AuthService";
import {IUserTokenValidity} from "../../Infrastructure/Http/Auth/IUserTokenValidity";
import {IPasswordVerify} from "../../Infrastructure/Http/Auth/IPasswordVerify";

export default class AuthFacade {

    private readonly authService: AuthService;
    constructor() {
        this.authService = new AuthService();
    }
    public async login(credentials: IUserAuthCredentials): Promise<IUserAuthToken> {
        return await this.authService.login(credentials);
    }

    public async isValidToken(): Promise<IUserTokenValidity> {
        return await this.authService.isValidToken();
    }

    public async passwordVerify(password: IPasswordVerify): Promise<number> {
        return await this.authService.passwordVerify(password);
    }
}
