import RecordingEvidenceNoteService from "../../Infrastructure/Service/Recording/RecordingEvidenceNoteService";
import {IRecordingEvidenceNote} from "../../Infrastructure/Http/Recording/IRecordingEvidenceNote";

export default class RecordingEvidenceNoteFacade {

    private readonly recordingEvidenceNoteService: RecordingEvidenceNoteService;

    constructor() {
        this.recordingEvidenceNoteService = new RecordingEvidenceNoteService();
    }

    public async findById(id: number | undefined): Promise<IRecordingEvidenceNote[]> {
        return await this.recordingEvidenceNoteService.findById(id);
    }

    public async persist(id: number | undefined, http: IRecordingEvidenceNote): Promise<IRecordingEvidenceNote> {
        return await this.recordingEvidenceNoteService.persist(id, http);
    }

    public async update(evidenceNoteId: number | undefined, http: IRecordingEvidenceNote): Promise<IRecordingEvidenceNote> {
        return await this.recordingEvidenceNoteService.update(evidenceNoteId, http);
    }
}
