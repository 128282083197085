import SettingsService from "../../Infrastructure/Service/Settings/SettingsService";
import {ISettings} from "../../Infrastructure/Http/Settings/ISettings";

export default class SettingsFacade {
    private readonly settingsService: SettingsService;

    constructor() {
        this.settingsService = new SettingsService();
    }

    public async findSettings(): Promise<ISettings> {
        return await this.settingsService.findSettings();
    }
}
