export default interface IUser {
    id:        number;
    userName:     string;
    password:  string;
    roles:      UserRole[];
    profile:   IUserProfile;
    degree:     IUserDegree;
    permissions: IUserPermissions;
    updatedAt: Date;
    createdAt: Date;
}
export enum UserRole {
    ROLE_USER = 'ROLE_USER',
    ROLE_ADMIN = 'ROLE_ADMIN'
}
export interface IUserProfile {
    id: number;
    firstName: string;
    lastName: string;
}

export interface IUserPermissions {
    id:                               number;
    name:                             string;
    canCreateUser:                    boolean;
    canViewUser:                      boolean;
    canEditUser:                      boolean;
    canDeleteUser:                    boolean;
    canViewRecording:                 boolean;
    canAddNoteToRecording:            boolean;
    canDownloadRecording:             boolean;
    canAnonymizeRecording:            boolean;
    canDeleteRecording:               boolean;
    canChangeRecordingExpirationDate: boolean;
    canViewCamera:                    boolean;
    canEditCamera:                    boolean;
    canDeleteCamera:                  boolean;
    canViewReport:                    boolean;
    canCreateReport:                  boolean;
    canDownloadReport:                boolean;
    canDeleteReport:                  boolean;
    updatedAt:                        Date;
    createdAt:                        Date;
}


export interface IUserDegree {
    id: number;
    name: string;
}

export interface IUserFilter {
    permissionId?: string;
    hasRfid?: boolean;
}