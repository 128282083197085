import React, { Component } from 'react';
import { Modal, InputLabel, Button } from "@mui/material";
import {
    DataGrid,
    GridColDef, GridCsvExportMenuItem, GridPrintExportMenuItem,
    GridToolbarColumnsButton,
    GridToolbarContainer, GridToolbarExportContainer,
    GridToolbarFilterButton,
    plPL
} from "@mui/x-data-grid";
import { GridRowsProp } from "@mui/x-data-grid/models/gridRows";
import TextBox, {ITextBoxProps} from "../TextBox/TextBox";
import "./TableSelectStyle.scss";
import {GridTxtExportMenuItem} from "../Table/ToolBar/GridTxtExportMenuItem";

export interface ITableSelectItem {
    id: any;
    label: string;
}


export interface ITableSelectProps  {
    label?: string
    placeholder?: string
    items?: ITableSelectItem[];
    columns: GridColDef[];
    rows: GridRowsProp;
    onChange: (selectedItem: ITableSelectItem) => void;
    value?: ITableSelectItem;
}

export interface ITableSelectState {
    isOpened: boolean;

}

export class TableSelect extends Component<ITableSelectProps, ITableSelectState> {
    constructor(props: ITableSelectProps) {
        super(props);
        this.close = this.close.bind(this);
        this.handleModal = this.handleModal.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.state = {
            isOpened: false,
         };
    }

    private close() {
        this.setState({ isOpened: false });
    }

    private handleModal() {
        this.setState((prevState) => ({ isOpened: !prevState.isOpened }));
    }

    private handleRowClick(params: any) {
        this.props.onChange({
            id: params?.row?.id,
            label: params?.row?.label,
        });
        this.close();
    }

    private toolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarExportContainer>
                    <GridCsvExportMenuItem/>
                    <GridTxtExportMenuItem rows={this.props.rows}/>
                    <GridPrintExportMenuItem/>
                </GridToolbarExportContainer>
            </GridToolbarContainer>
        )
    }
    render() {


        return (
            <div className="table_select">
                <div className="table_select_field">
                    <TextBox
                        label={this.props.label}
                        placeholder={this.props.placeholder}
                        value={this.props.value?.label || ''}
                        data-id={this.props.value?.id || ''}
                        onClick={this.handleModal}
                        readOnly
                    />
                </div>

                <Modal open={this.state.isOpened} onClose={this.close} className="table_select_modal">
                    <div className="table_select_modal_content">
                        <div className="table_select_modal_content_header">
                            <InputLabel>{this.props.label}</InputLabel>
                        </div>
                        <div className="table_select_modal_content_body" >
                            <DataGrid
                                columns={this.props.columns}
                                rows={this.props.rows}
                                autoHeight={false}
                                onRowClick={this.handleRowClick}
                                localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
                                pagination={true}
                                sx={{
                                    boxShadow: 0,
                                    border: 0,
                                    borderColor: 'transparent',
                                    overflowX: "scroll"
                                }}
                                slots={{
                                    toolbar: this.toolbar
                                }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 200 },
                                    },
                                }}
                            />
                        </div>
                        <div className="table_select_modal_content_footer">
                            <Button onClick={this.close}>Zamknij</Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
