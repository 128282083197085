import UserStorage from "../../Infrastructure/Storage/User/UserStorage";
import {IPaginator} from "../../Infrastructure/Paginator/Paginator";
import {IUserPaginator} from "../../Infrastructure/Http/User/IUserPaginator";
import UserService from "../../Infrastructure/Service/User/UserService";
import IUser, {IUserFilter} from "../../Infrastructure/Http/User/IUser";
import {IUserNotification} from "../../Infrastructure/Http/User/HttpUserNotification";

export default class UserFacade {

    private readonly userService: UserService;

    public constructor()
    {
        this.userService = new UserService();
    }
    public async findAll(page: IPaginator, filters: IUserFilter|undefined = undefined): Promise<IUserPaginator> {
        return await this.userService.findAll(page, filters);
    }

    public async findById(id: Number): Promise<IUser> {
        return await this.userService.findById(id);
    }

    public async me(): Promise<IUser> {
        return await this.userService.me();
    }
    public async update(user: IUser): Promise<IUser> {
        return await this.userService.update(user);
    }

    public logout(withNavigation: boolean = true): void {
        this.userService.logout(withNavigation);
    }

    public async notifications(page?: number): Promise<IUserNotification[]> {
        return await this.userService.notifications(page);
    }

    public async readNotificationById(id: string): Promise<IUserNotification> {
        return await this.userService.readNotificationById(id);
    }
}
