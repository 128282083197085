import {IPaginator} from "../../Paginator/Paginator";
import {IUserPaginator} from "../../Http/User/IUserPaginator";
import UserRepository from "../../Repository/User/UserRepository";
import UserStorage from "../../Storage/User/UserStorage";
import IUser, {IUserFilter} from "../../Http/User/IUser";
import {IUserNotification} from "../../Http/User/HttpUserNotification";

export default class UserService {

    private readonly userStorage: UserStorage;

    public constructor()
    {
        this.userStorage = new UserStorage();
    }
    public async findAll(page: IPaginator, filters: IUserFilter|undefined = undefined): Promise<IUserPaginator> {
        return await this.userStorage.findAll(page, filters);
    }

    public async findById(id: Number): Promise<IUser> {
        return await this.userStorage.findById(id);
    }

    public async me(): Promise<IUser> {
        return await this.userStorage.me();
    }
    public async update(user: IUser): Promise<IUser> {
        return await this.userStorage.update(user);
    }

    public logout(withNavigation: boolean = true): void {
        this.userStorage.logout(withNavigation);
    }

    public async notifications(page?: number): Promise<IUserNotification[]> {
        return await this.userStorage.notifications(page);
    }

    public async readNotificationById(id: string): Promise<IUserNotification> {
        return await this.userStorage.readNotificationById(id);
    }
}
