import React, {Component} from 'react';
import {Alert, AlertProps, Collapse, IconButton, Stack} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export interface IAlertContainerProps extends AlertProps{
  children: any;
}

export interface IAlertContainerState {
    open: boolean
}

export class AlertContainer extends Component<IAlertContainerProps, IAlertContainerState> {
    constructor(props: IAlertContainerProps) {
        super(props);
        this.state = {
            open: true
        };
    }

    private setOpen(open: boolean) {
        this.setState({open: open})
    }
    render() {
        return (
            <Stack sx={{ width: 'auto', position: 'fixed', right: '1rem', bottom: '1rem' }} spacing={2}>
                <Collapse in={this.state.open}>
                    <Alert {...this.props}
                           action={
                               <IconButton
                                   aria-label="close"
                                   color="inherit"
                                   size="small"
                                   onClick={() => {
                                       this.setOpen(false);
                                   }}
                               >
                                   <CloseIcon fontSize="inherit" />
                               </IconButton>
                           }
                           sx={{ mb: 2 }}
                    >
                        {this.props.children}
                    </Alert>
                </Collapse>
            </Stack>
        );
    }
}
