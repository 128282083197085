import React, {Component} from 'react';
import {GridColDef, GridRowsProp} from "@mui/x-data-grid";
import Table from "../../Shared/Table/Table";

export interface IUserActivityLogListProps {
    rows: GridRowsProp;
    isTableLoading: boolean;
}

export interface IUserActivityLogListState {
    columns: GridColDef[];
}

export class UserActivityLogList extends Component<IUserActivityLogListProps, IUserActivityLogListState> {
    constructor(props: IUserActivityLogListProps) {
        super(props);
        this.state = {
            columns: [
                { field: 'description', headerName: 'Czynność',  width: 400, type: "string",  editable: false, flex: 1},
                { field: 'createdAt', headerName: 'Data i godzina',  width: 200, type: "string",  editable: false, flex: 1},
            ],
        };
    }

    render() {
        return (
            <Table
                label="Dziennik aktywności"
                description="Poniżej znajduje się lista aktywności danego użytkownika"
                initialState={{
                    pagination: { paginationModel: { pageSize: 5 } },
                }}
                pageSizeOptions={[5, 10]}
                columns={this.state.columns}
                rows={this.props.rows}
                checkboxSelection={false}
                disableRowSelectionOnClick={true}
                loading={this.props.isTableLoading}
            />
        );
    }
}
