import IUserActivityLog, {IUserActivityLogFilter, IUserActivityLogPaginator} from "../../Http/User/IUserActivityLog";
import UserActivityLogRepository from "../../Repository/User/UserActivityLogRepository";
import {IPaginator} from "../../Paginator/Paginator";

export default class UserActivityLogStorage {

    public async findAll(page: IPaginator, filters: IUserActivityLogFilter): Promise<IUserActivityLogPaginator>  {
        return await UserActivityLogRepository.FindAll(page, filters);
    }

    public async persist(http: IUserActivityLog): Promise<void> {
        return await UserActivityLogRepository.Persist(http);
    }
}
