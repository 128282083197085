import RecordingService from "../../../Infrastructure/Service/Recording/RecordingService";
import {IRecording, IRecordingFilter} from "../../../Infrastructure/Http/Recording/IRecording";
import {IRecordingPaginator} from "../../../Infrastructure/Http/Recording/IRecordingPaginator";
import RecordingFacade from "../../../Domain/Recording/RecordingFacade";

export default class RecordingAdapter {
    private readonly recordingFacade: RecordingFacade;

    constructor() {
        this.recordingFacade = new RecordingFacade();
    }
    public async findAll(filters: IRecordingFilter): Promise<IRecordingPaginator> {
        return await this.recordingFacade.findAll(filters);
    }

    public async findById(id: number): Promise<IRecording> {
        return await this.recordingFacade.findById(id);
    }

    public async update(recording: IRecording): Promise<void> {
        return await this.recordingFacade.update(recording);
    }
    public async delete(id: number): Promise<void> {
        return await this.recordingFacade.delete(id);
    }
}
