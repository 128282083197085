import RecordingEvidenceNoteFacade from "../../../Domain/Recording/RecordingEvidenceNoteFacade";
import {IRecordingEvidenceNote} from "../../../Infrastructure/Http/Recording/IRecordingEvidenceNote";

export default class RecordingEvidenceNoteAdapter {

    private readonly recordingEvidenceNoteFacade: RecordingEvidenceNoteFacade;

    constructor() {
        this.recordingEvidenceNoteFacade = new RecordingEvidenceNoteFacade();
    }
    public async findById(id: number | undefined): Promise<IRecordingEvidenceNote[]> {
        return await this.recordingEvidenceNoteFacade.findById(id);
    }

    public async persist(id: number | undefined, http: IRecordingEvidenceNote): Promise<IRecordingEvidenceNote> {
        return await this.recordingEvidenceNoteFacade.persist(id, http);
    }

    public async update(evidenceNoteId: number | undefined, http: IRecordingEvidenceNote): Promise<IRecordingEvidenceNote> {
        return await this.recordingEvidenceNoteFacade.update(evidenceNoteId, http);
    }
}
