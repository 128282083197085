import {IPaginator} from "../../Paginator/Paginator";
import {IUserPaginator} from "../../Http/User/IUserPaginator";
import {GetHttpClient} from "../../HttpClient/Factory/HttpClientFactory";
import {IRecordingPaginator} from "../../Http/Recording/IRecordingPaginator";
import {IRecording, IRecordingFilter} from "../../Http/Recording/IRecording";
import {IRecordingNote} from "../../Http/Recording/IRecordingNote";

export default class RecordingNoteRepository {


    public static async FindAll(id: number): Promise<IRecordingNote[]> {
        let url: string = `/api/recordings/${id}/note`;
        return  GetHttpClient().GET(url).then((response) => {
            return response.data
        })
    }

    /**
     * @TODO=REMOVE USELES Call, notes will be retrieived only by recording id, and not by note id
     * @param id
     * @constructor
     */
    public static async FindById(id: number): Promise<IRecording> {
        let url: string = `/api/recordings/note/${id}`;
        return  GetHttpClient().GET(url).then((response) => {
            return response.data
        })
    }

    public static async Persist(id: number, http: IRecordingNote): Promise<IRecordingNote> {
        let url: string = `/api/recordings/${id}/note`;
        return GetHttpClient().POST(url, http).then((response) => {
            return response.data
        })
    }

    public static async Update(id: number, http: IRecordingNote): Promise<IRecordingNote> {
        let url: string = `/api/recordings/note/${id}`;
        return GetHttpClient().PUT(url, http).then((response) => {
            return response.data
        })
    }

    public static async Delete(id: number): Promise<any> {
        let url: string = `/api/recordings/note/${id}`;
        return GetHttpClient().DELETE(url).then((response) => {
            return response.data
        })
    }
}