import React, {Component} from 'react';
import {IListBoxItem, ListBox} from "../../Shared/ListBox/ListBox";
import {ICamera} from "../../../Http/Camera/ICamera";
import "./CamerasListStyle.scss";
import {GridColDef} from "@mui/x-data-grid";
import {ITableSelectItem, TableSelect} from "../../Shared/TableSelect/TableSelect";
export interface ICamerasListProps {
    cameras: ICamera[];
    value?: ITableSelectItem;
    onChange: (e: ITableSelectItem) => void;
    label: string;
}

export interface ICamerasListState {
    columns: GridColDef[];

}

export default class CamerasList extends Component<ICamerasListProps, ICamerasListState> {

    constructor(props: ICamerasListProps) {
        super(props);
        this.state = {
            columns: [
                {
                    field: 'id', headerName: "ID", width: 120, type: 'string', editable: false
                },
                {
                    field: 'label', headerName: "Identyfikator kamery", width: 220, type: 'string', editable: false
                }
            ]
        }
    }

    private get items(): ITableSelectItem[] {
        return this.props.cameras.map((item, idx) => {
            return {id: item.id, label: item.serialNumber}
        })
    }
    render() {
        return (
            <TableSelect
                label={this.props.label}
                placeholder={"Przykład - 123456789"}
                columns={this.state.columns}
                rows={this.items}
                value={this.props.value}
                onChange={(e) => {
                    this.props.onChange(e);
                }}
            />
        );
    }
}