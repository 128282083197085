import React, { Component, createRef } from 'react';
import withRouter, { IRouterProps } from "../../../../Infrastructure/HOC/Router/WithRouter";
import WithLayout from "../../../../Infrastructure/HOC/Layout/WithLayout";
import { IRecording, IRecordingFile } from "../../../../Infrastructure/Http/Recording/IRecording";
import RecordingAdapter from "../../Adapter/RecordingAdapter";
import {
    Breadcrumbs,
    Link,
} from "@mui/material";
import { AuthorizedPath } from "../../../../Infrastructure/Route/Authorized/AuthorizedPath";
import { RecordingMetadata } from "../../../../Infrastructure/Component/Recording/MetaData/RecordingMetadata";
import { IRecordingNote } from "../../../../Infrastructure/Http/Recording/IRecordingNote";
import RecordingNoteAdapter from "../../Adapter/RecordingNoteAdapter";
import IUser from "../../../../Infrastructure/Http/User/IUser";
import UserAdapter from "../../../User/Adapter/UserAdapter";
import "./SingleRecordingViewStyle.scss";
import { RecordingNotesList } from "../../../../Infrastructure/Component/Recording/Note/RecordingNotesList";
import { IAbstractLayoutChildrenProps } from "../../../../Infrastructure/HOC/Layout/AbstractLayout";
import RecordingPlayer from "../../../../Infrastructure/Component/Recording/Player/RecordingPlayer";
import UserActivityLogAdapter from "../../../User/Adapter/UserActivityLogAdapter";
import IUserActivityLog from "../../../../Infrastructure/Http/User/IUserActivityLog";
import EvidenceCreator from "../../../../Infrastructure/Component/Recording/EvidenceCreator/EvidenceCreator";
import ExpiryDateManager from "../../../../Infrastructure/Component/Recording/ExpiryDateManage/ExpiryDateManage";
import DownloadButton from "../../../../Infrastructure/Component/Recording/DownloadButton/DownloadButton";

interface RouterParams {
    id: string;
}

export interface ISingleRecordingViewProps extends IAbstractLayoutChildrenProps, IRouterProps {
    params: RouterParams
}

export interface ISingleRecordingViewState {
    user: IUser;
    recording: IRecording;
    notes: IRecordingNote[];
    isLoading: boolean;
    open: boolean;
    currentFile: IRecordingFile | null;
    isPlaying: boolean;
}

class SingleRecordingView extends Component<ISingleRecordingViewProps, ISingleRecordingViewState> {
    private readonly userAdapter: UserAdapter;
    private readonly recordingAdapter: RecordingAdapter;
    private readonly recordingNoteAdapter: RecordingNoteAdapter;
    private readonly userActivityLogAdapter: UserActivityLogAdapter;
    private readonly videoRef: React.RefObject<HTMLVideoElement>;

    constructor(props: ISingleRecordingViewProps) {
        super(props);
        this.userAdapter = new UserAdapter();
        this.recordingAdapter = new RecordingAdapter();
        this.recordingNoteAdapter = new RecordingNoteAdapter();
        this.userActivityLogAdapter = new UserActivityLogAdapter();
        this.videoRef = createRef<HTMLVideoElement>();

        this.onAddNewNote = this.onAddNewNote.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.onSaveNote = this.onSaveNote.bind(this);
        this.onDeleteNote = this.onDeleteNote.bind(this);

        this.state = {
            user: {} as IUser,
            recording: { evidences: [] } as unknown as IRecording,
            notes: [],
            isLoading: true,
            open: false,
            currentFile: null,
            isPlaying: false,
        };
    }

    private verifyPermissions(): void {
        if (!this.props.currentUser?.permissions?.canViewCamera) {
            this.props.navigate(AuthorizedPath.HOME);
        }
    }

    private async fetch(): Promise<void> {
        this.verifyPermissions();
        this.setState({ isLoading: true });
        await this.userAdapter.me().then((currentUser) => {
            this.setState({ user: currentUser }, async () => {
                await this.recordingAdapter.findById(Number(this.props.params?.id)).then((recording) => {
                    this.setState({ recording: recording }, async () => {
                        await this.fetchNotes();
                        this.setState({ isLoading: false });
                    });
                });
            });
        });
    }

    private async onDelete(): Promise<void> {
        await this.recordingAdapter.delete(this.state.recording.id);
        await this.fetch();
    }

    private async fetchNotes(): Promise<void> {
        await this.recordingNoteAdapter.findAll(Number(this.props.params?.id)).then((notes) => {
            this.setState({ notes: notes });
        });
    }

    private async onAddNewNote(e: string) {
        await this.recordingNoteAdapter.persist((Number(this.props.params?.id)), { text: e }).then(() => {
            this.fetch();
        });
    }

    private async onSaveNote(id: number, note: IRecordingNote) {
        await this.recordingNoteAdapter.update(id, note);
    }

    private async onDeleteNote(id: number) {
        await this.recordingNoteAdapter.delete(id).then(async () => {
            await this.fetchNotes();
        });
    }

    private async handleDeleteClick(): Promise<void> {
        await this.onDelete();
    }

    private handleVideoPlay = (): void => {
        const userActivityLog: IUserActivityLog = {
            description: "Rozpoczął oglądanie wideo: " + this.state.recording.title,
        };
        this.userActivityLogAdapter.persist(userActivityLog);
    }

    componentDidMount() {
        this.fetch();
        if (this.videoRef.current) {
            this.videoRef.current.addEventListener('play', this.handleVideoPlay);
        }
    }

    componentWillUnmount() {
        if (this.videoRef.current) {
            this.videoRef.current.removeEventListener('play', this.handleVideoPlay);
        }
    }

    render() {
        return (
            <div className="single-recording">
                <div className="single-recording_container">
                    <div className="single-recording_container_header">
                        <div className="single-recording_container_header_left">
                            <Breadcrumbs separator="›" aria-label="breadcrumb">
                                <Link
                                    id="all_recordings_breadcrumb"
                                    underline="hover"
                                    color="inherit"
                                    href={AuthorizedPath.RECORDINGS}
                                >
                                    Nagrania
                                </Link>
                                <Link
                                    id="current_recording_breadcrumb"
                                    underline="hover"
                                    color="inherit"
                                    href={AuthorizedPath.RECORDING(this.props.params?.id)}
                                >
                                    {this.state.recording?.title}
                                </Link>
                            </Breadcrumbs>
                        </div>
                    </div>

                    <div className="single-recording_container_body">
                        <div className="single-recording_container_body_content">
                            <div className="single-recording_container_body_content_player">
                                <RecordingPlayer
                                    recordingId={this.state.recording.id}
                                    file={this.state.recording.file}
                                    videoRef={this.videoRef}
                                    title={this.state.recording.title}
                                />
                            </div>

                            <EvidenceCreator
                                recording={this.state.recording}
                                currentUserId={this.state.user.id}
                                videoRef={this.videoRef.current}
                            />

                            <DownloadButton recording={this.state.recording} />

                            <ExpiryDateManager recording={this.state.recording} />

                            <div className="single-recording_container_body_content_metadata">
                                <RecordingMetadata recording={this.state.recording} />
                            </div>

                            <div className="single-recording_container_body_content_notes">
                                <RecordingNotesList
                                    id={Number(this.props.params.id)}
                                    user={this.state.user}
                                    notes={this.state.notes}
                                    onAdd={this.onAddNewNote}
                                    onSave={this.onSaveNote}
                                    onDelete={this.onDeleteNote}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(WithLayout(SingleRecordingView));
