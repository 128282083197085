import React, { Component } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    Box,
    Checkbox,
    FormControlLabel,
    Card,
    CardContent,
    CardHeader,
    TextareaAutosize,
    IconButton,
    Tooltip,
    Divider
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { IRecordingEvidenceNote } from "../../../../Http/Recording/IRecordingEvidenceNote";
import { FormHelperText } from "@mui/joy";
import RecordingNoteAdapter from "../../../../../Application/Recording/Adapter/RecordingNoteAdapter";
import EvidenceNote from "../../EvidenceNote/EvidenceNote";
import TextBox from "../../../Shared/TextBox/TextBox";
import {IRecordingEvidence} from "../../../../Http/Recording/IRecording";
import RecordingEvidenceAdapter from "../../../../../Application/Recording/Adapter/RecordingEvidenceAdapter";

interface EvidenceDialogProps {
    isOpen: boolean;
    onClose: () => void;
    evidenceTitle?: string;
    evidenceNotes: IRecordingEvidenceNote[];
    formatTime: (seconds: number, precision?: number) => string;
    recordingId: number;
    evidenceStartTime: number;
    evidenceEndTime: number;
    currentUserId: number;
}

interface EvidenceDialogState {
    title: string;
    notes: IRecordingEvidenceNote[];
    startTime: number;
    endTime: number;
    indelible: boolean;
    expiredAt: string | number | undefined | null;
    newNote: string;
    noteEditMode: boolean;
    editingNote?: IRecordingEvidenceNote;
    error: string;
}

export default class EvidenceDialog extends Component<EvidenceDialogProps, EvidenceDialogState> {
    private readonly recordingNoteAdapter: RecordingNoteAdapter;
    private readonly recordingEvidenceAdapter: RecordingEvidenceAdapter;

    constructor(props: EvidenceDialogProps) {
        super(props);
        this.state = {
            title: props.evidenceTitle || '',
            notes: props.evidenceNotes,
            startTime: 0,
            endTime: 0,
            indelible: false,
            expiredAt: '',
            newNote: '',
            noteEditMode: false,
            error: '',
        };
        this.recordingNoteAdapter = new RecordingNoteAdapter();
        this.recordingEvidenceAdapter = new RecordingEvidenceAdapter();

        this.fetchNotes = this.fetchNotes.bind(this);
        this.saveEvidence = this.saveEvidence.bind(this);
    }

    componentDidUpdate(prevProps: EvidenceDialogProps) {
        if (prevProps.evidenceTitle !== this.props.evidenceTitle) {
            this.setState({ title: this.props.evidenceTitle || '' });
        }
        if (prevProps.evidenceNotes !== this.props.evidenceNotes) {
            this.setState({ notes: this.props.evidenceNotes });
        }
    }

    handleClose = () => {
        this.props.onClose();
    };

    handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ title: event.target.value });
    };

    handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ newNote: event.target.value });
    };

    handleIndelibleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ indelible: event.target.checked });
    };

    handleExpiredAtChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ expiredAt: event.target.value });
    };

    private async saveEvidence(): Promise<void> {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + Number(this.state.expiredAt));
        const expiryDate = currentDate.toISOString().split('T')[0];

        if (!this.state.indelible) {
            const expiryDateNumber = Number(this.state.expiredAt);

            if (expiryDateNumber < 40 || expiryDateNumber > 1000) {
                this.setState({ error: 'Wprowadź liczbę od 40 do 1000' });
                return;
            } else {
                this.setState({ error: '' })
            }
        }
        const newEvidence: IRecordingEvidence = {
            id: -1,
            title: this.state.title,
            timeFrom: String(this.props.evidenceStartTime),
            timeTo: String(this.props.evidenceEndTime),
            userId: this.props.currentUserId,
            indelible: this.state.indelible,
            expiryAt: expiryDate,
        };

        await this.recordingEvidenceAdapter.persist(Number(this.props.recordingId), newEvidence);
    }

    saveNote = async () => {
        // Implement the save note logic here
    };

    saveEditedNote = async () => {
        // Implement the save edited note logic here
    };

    private async fetchNotes(): Promise<void> {
        await this.recordingNoteAdapter.findAll(Number(this.props.recordingId)).then((notes) => {
            this.setState({ notes: notes });
        });
    }

    componentDidMount() {
        this.fetchNotes();
    }

    render() {
        const {
            isOpen,
            formatTime,
        } = this.props;

        const {
            title,
            startTime,
            endTime,
            indelible,
            notes,
            newNote,
            noteEditMode
        } = this.state;

        return (
            <Dialog
                open={isOpen}
                onClose={this.handleClose}
                fullWidth
                maxWidth="md"
                PaperProps={{
                    sx: {
                        borderRadius: 3,
                        p: 3,
                        boxShadow: 5,
                        bgcolor: "#121A2B",
                        color: "#ffffff",
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        bgcolor: "#121A2B",
                        color: "#ffffff",
                        fontWeight: "bold",
                        textAlign: "center",
                    }}
                >
                    Kreator dowodu
                </DialogTitle>
                <DialogContent sx={{ bgcolor: "#121A2B", mt: 1 }}>
                    <TextBox
                        autoFocus
                        label="Tytuł dowodu"
                        type="text"
                        value={title}
                        onChange={this.handleTitleChange}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={indelible}
                                onChange={this.handleIndelibleChange}
                                name="indelible"
                                color="primary"
                            />
                        }
                        label="Nieusuwalny"
                        sx={{ mb: 2, color: "#ffffff" }}
                    />
                    <Box>
                        <TextBox
                            label="Ważność dowodu (dni)"
                            type="number"
                            onChange={this.handleExpiredAtChange}
                            disabled={indelible}
                        />
                        {this.state.error && this.state.error.length > 1 && (
                            <FormHelperText sx={{ color: "error.main" }}>{this.state.error}</FormHelperText>
                        )}
                    </Box>
                    <Typography variant="body2" sx={{ mb: 1, color: "#ffffff" }}>
                        <Box component="span" fontWeight="fontWeightBold">
                            Od:
                        </Box>{" "}
                        {formatTime(startTime, 10)}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2, color: "#ffffff" }}>
                        <Box component="span" fontWeight="fontWeightBold">
                            Do:
                        </Box>{" "}
                        {formatTime(endTime, 10)}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                        <Typography variant="h6" sx={{ mb: 1, mr: 2, color: "#ffffff" }}>
                            <Box component="span" fontWeight="fontWeightBold">
                                Notatka:
                            </Box>
                        </Typography>
                        <TextareaAutosize
                            minRows={3}
                            placeholder="Dodaj notatkę"
                            value={newNote}
                            onChange={this.handleNoteChange}
                            style={{
                                width: "100%",
                                padding: "8px",
                                borderRadius: "4px",
                                border: "1px solid #2C3A4B",
                                backgroundColor: "#1E2A38",
                                color: "#ffffff",
                            }}
                        />
                        <Tooltip
                            title={noteEditMode ? "Zaktualizuj notatkę" : "Dodaj notatkę"}
                        >
                            <IconButton
                                onClick={noteEditMode ? this.saveEditedNote : this.saveNote}
                                color="primary"
                                sx={{ ml: 1 }}
                            >
                                {noteEditMode ? <EditIcon /> : <AddIcon />}
                            </IconButton>
                        </Tooltip>
                    </Box>

                    <EvidenceNote notes={notes} />
                </DialogContent>
                <DialogActions sx={{ bgcolor: "#121A2B", p: 2 }}>
                    <Button
                        onClick={this.handleClose}
                        variant="contained"
                        color="error"
                        sx={{ borderRadius: 2 }}
                    >
                        Anuluj
                    </Button>
                    <Button
                        onClick={this.saveEvidence}
                        variant="contained"
                        color="primary"
                        sx={{ borderRadius: 2 }}
                    >
                        Zapisz
                    </Button>
                </DialogActions>
            </Dialog>
        );

    }
}
