import React, {Component} from 'react';
import {GridColDef, GridRowsProp} from "@mui/x-data-grid";
import {Box, LinearProgress, Link} from "@mui/material";
import {AuthorizedPath} from "../../../Route/Authorized/AuthorizedPath";
import Table from "../../Shared/Table/Table";
import CamerasTableFilter, {ICamerasTableFilterProps} from "../Filter/CamerasTableFilter";
import SSEManager from '../../../SSEManager/SSEManager';
import {BatteryLevel} from "../BatteryLevel/BatteryLevel";

export interface ICamerasTableProps extends ICamerasTableFilterProps {
  label?: string;
  rows: GridRowsProp;
  isTableLoading: boolean;
  onSseUpdated?: (id: number) => void;
}

export interface ICamerasTableState {
  columns: GridColDef[];
}

interface ICameraSSEMessage {
    id: number
}

export class CamerasTable extends Component<ICamerasTableProps, ICamerasTableState> {
    constructor(props: ICamerasTableProps) {
        super(props);
        this.state = {
          columns: [
            { field: 'id', headerName: "ID", width: 200, type: 'string',  editable: false, flex: 1 },
            { field: 'userName', headerName: "ID użytkownika", width: 200, type: 'string',  editable: false, flex: 1 },
            { field: 'status', headerName: 'status',  width: 200, type: "boolean",  editable: false, flex: 1, renderCell: (params) => {

                switch (params.row?.status) {
                    case 'connected':
                        return <span role="success">Podłączona</span>
                    case 'mounted':
                        return <span role="warning">Zgrywa</span>
                    default:
                        return <span role="error">Nie Aktywna</span>
                }                 
            }},
            { field: 'batteryLevel', headerName: "Bateria", width: 200, type: 'string',  editable: false, flex: 1, renderCell: (params) => {
                return <BatteryLevel batteryLevel={params.row?.batteryLevel}/>
            }},
            { field: 'action', headerName: "Akcja", width: 200, type: 'string',  editable: false, flex: 1, renderCell: (params) => {
                return  <Link href={AuthorizedPath.CAMERA(params?.row?.id)}>Wyświetl</Link>
              }}
          ]
        };
    }

    render() {
        return (

            <div className="cameras-list">
                <div className="cameras-list_filter">
                    {this.props.onFilter && (
                        <CamerasTableFilter onFilter={this.props.onFilter}/>
                    )}
                </div>
                <div className="cameras-list_table">
                    <SSEManager<ICameraSSEMessage> topics={[
                        'mercure://camera/connected',
                        'mercure://camera/disconnected',
                        'mercure://camera/mounted'
                    ]} onUpdate={(message) => {
                        if (!this.props.onSseUpdated) {
                            return;
                        }

                        this.props.onSseUpdated(message.id);
                    }}>
                        <Table
                            label={this.props.label}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 5 } },
                            }}
                            pageSizeOptions={[5, 10]}
                            columns={this.state.columns}
                            rows={this.props.rows}
                            checkboxSelection={true}
                            loading={this.props.isTableLoading}
                        />
                    </SSEManager>
                </div>
            </div>

        );
    }
}
