import {IUserDegree, IUserPermissions} from "../../Http/User/IUser";
import UserDegreeRepository from "../../Repository/User/UserDegreeRepository";
import UserDegreeStorage from "../../Storage/User/UserDegreeStorage";
import UserPermissionsRepository from "../../Repository/User/UserPermissionsRepository";
import UserPermissionsStorage from "../../Storage/User/UserPermissionsStorage";

export default class UserPermissionsService {

    private readonly userPermissionsStorage: UserPermissionsStorage;
    public constructor() {
        this.userPermissionsStorage = new UserPermissionsStorage();
    }

    public async findAll(): Promise<IUserPermissions[]> {
        return await this.userPermissionsStorage.findAll();
    }
}