import {Button, Checkbox, FormControlLabel, FormGroup} from '@mui/material';
import React, {Component} from 'react';
import TableTextFieldFilter from "../../Shared/Table/Field/TextFieldFilter/TableTextFieldFilter";
import './UsersTableFilterStyle.scss';
import {IUserDegree, IUserFilter, IUserPermissions} from "../../../Http/User/IUser";
import {TableListFieldFilter} from "../../Shared/Table/Field/ListFieldFIlter/TableListFieldFilter";
import {UserPermissionList} from "../PermissionList/UserPermissionList";
import {ITableSelectItem} from "../../Shared/TableSelect/TableSelect";
import UsersList from "../UsersList/UsersList";
import {CheckBox} from "../../Shared/CheckBox/CheckBox";

export interface IUsersTableFilterProps {
    onFilter: (filter: IUserFilter) => void;
    degrees: IUserDegree[];
    permissions: IUserPermissions[];
}

export interface IUsersTableFilterState {
    selectedPermissionsItem?: ITableSelectItem;
    filters: IUserFilter;
}

export default class UsersTableFilter extends Component<IUsersTableFilterProps, IUsersTableFilterState> {

    constructor(props: IUsersTableFilterProps) {
        super(props);
        this.onPermissionChanged = this.onPermissionChanged.bind(this);
        this.onRfidChanged = this.onRfidChanged.bind(this);
        this.onClearAll = this.onClearAll.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.state = {
            selectedPermissionsItem: undefined,
            filters: {
                permissionId: "",
                hasRfid: false
            }
        }
    }


    private onPermissionChanged(e: ITableSelectItem) {
        this.setState(prevState => ({
            selectedPermissionsItem: e,
            filters: {
                ...prevState.filters,
                permissionId: e.id
            }
        }), this.onFilter)
    }

    private onRfidChanged() {
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                hasRfid: !this.state?.filters?.hasRfid
            }
        }), this.onFilter)
    }
    private onClearAll() {
        this.setState ({
            selectedPermissionsItem: undefined,
            filters: {
                permissionId: "",
                hasRfid: false
            }
        })
    }

    private onFilter() {
        this.props.onFilter(this.state.filters);
    }

    render() {
        return (
            <div className="users-filter">
                <div className="users-filter_container">
                    <div className="users-filter_container_header">
                        <div className="users-filter_container_header_label">
                            Filtry
                        </div>

                        <div className="users-filter_container_header_clear" onClick={this.onClearAll}>
                            Wyczyść
                        </div>
                    </div>
                    <div className="users-filter_container_body">
                        <UserPermissionList
                            label="Lista uprawnien"
                            value={this.state.selectedPermissionsItem}
                            onChange={this.onPermissionChanged}
                            permissions={this.props.permissions}
                        />

                        <CheckBox
                            label={"Karta RFID"}
                            onChange={this.onRfidChanged}
                            checked={this.state?.filters?.hasRfid}
                        />
                    </div>
                    <div className="users-filter_container_footer">
                        <hr/>
                    </div>
                </div>
            </div>
        );
    }
}
