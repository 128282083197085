import React, {Component} from 'react';
import withRouter, {IRouterProps} from "../../../../Infrastructure/HOC/Router/WithRouter";
import IUser from "../../../../Infrastructure/Http/User/IUser";
import UserAdapter from "../../Adapter/UserAdapter";
import TextBox from "../../../../Infrastructure/Component/Shared/TextBox/TextBox";
import SaveIcon from '@mui/icons-material/Save';
import {Button, Divider} from "@mui/material";
import {IAbstractLayoutChildrenProps} from "../../../../Infrastructure/HOC/Layout/AbstractLayout";
import withLayout from "../../../../Infrastructure/HOC/Layout/WithLayout";
import "./UserAccountViewStyle.scss";
interface RouterParams extends IRouterProps{
}
export interface IUserAccountProps extends RouterParams, IAbstractLayoutChildrenProps{
    params: any;
}

export interface IUserAccountState {
    currentUser: IUser;
    tempPassword: string;
}

class UserAccountView extends Component<IUserAccountProps, IUserAccountState> {

    private readonly userAdapter: UserAdapter;
    constructor(props: IUserAccountProps) {
        super(props);
        this.userAdapter = new UserAdapter();

        this.onFirstNameChanged = this.onFirstNameChanged.bind(this);
        this.onLastNameChanged = this.onLastNameChanged.bind(this);
        this.onUserNameChanged = this.onUserNameChanged.bind(this);
        this.onPasswordChanged = this.onPasswordChanged.bind(this);
        this.onSave = this.onSave.bind(this);
        this.state = {
            currentUser: this.props.currentUser,
            tempPassword: ''
        };
    }

    private onFirstNameChanged(e: string) {
        this.setState(prevState => ({
            currentUser: {
                ...prevState.currentUser,
                profile: {
                    ...prevState.currentUser?.profile,
                    firstName: e.toString()
                }
            }
        }))
    }

    private onLastNameChanged(e: string) {
        this.setState(prevState => ({
            currentUser: {
                ...prevState.currentUser,
                profile: {
                    ...prevState.currentUser?.profile,
                    lastName: e.toString()
                }
            }
        }))
    }

    private onUserNameChanged(e: string) {
        this.setState(prevState => ({
            currentUser: {
                ...prevState.currentUser,
                userName: e
            }
        }))
    }

    private onPasswordChanged(e: string) {
        this.setState(prevState => ({
            tempPassword: e,
            currentUser: {
                ...prevState.currentUser,
                password: e
            }
        }))
    }

    private onSave(){
        this.userAdapter.update(this.state.currentUser)
    }
    private validatePermissions() {
        if (this.props.currentUser?.id !== this.props.params.id) {
            this.props.navigate(-1)
        }
    }

    componentDidMount() {
        this.validatePermissions();
    }

    render() {
        return (
            <div className="user-account">
               <div className="user-account_container">
                   <div className="user-account_container_header">
                        <span>Dane personalne</span>
                   </div>

                   <div className="user-account_container_body">
                       <ul className="user-account_container_body_left">
                           <li>
                               <TextBox
                                   type="text"
                                   label="Imię"
                                   value={this.state?.currentUser?.profile?.firstName}
                                   onChange={(e) => this.onFirstNameChanged(e.target.value)}
                               />
                           </li>

                           <li>
                               <TextBox
                                   type="text"
                                   label="Nazwisko"
                                   value={this.state?.currentUser?.profile?.lastName}
                                   onChange={(e) => this.onLastNameChanged(e.target.value)}
                               />
                           </li>

                           <li>
                               <TextBox
                                   type="text"
                                   value={this.state?.currentUser?.userName}
                                   label="Nazwa użytkownika"
                                   onChange={(e) => this.onUserNameChanged(e.target.value)}
                               />
                           </li>

                           <li>
                               <TextBox
                                   type="password"
                                   label="Hasło"
                                   value={this.state?.tempPassword}
                                   onChange={(e) => this.onPasswordChanged(e.target.value)}
                               />
                           </li>

                       </ul>
                   </div>

                   <div className="mt-8">
                       <Divider/>
                   </div>
                   <div className="user-account_container_footer">
                       <Button variant="contained" startIcon={<SaveIcon />} onClick={this.onSave}>
                           Zapisz
                       </Button>
                   </div>
               </div>
            </div>
        );
    }
}
export default withLayout(withRouter(UserAccountView));
