import React, { Component } from 'react';
import './DiskSpaceItemStyle.scss';

export interface IDiskSpaceItemProps {
    size: string;
    label: string;
    type: 'used' | 'total';
}

export interface IDiskSpaceItemState {
    fillWidth: number;
}

export default class DiskSpaceItem extends Component<IDiskSpaceItemProps, IDiskSpaceItemState> {
    constructor(props: IDiskSpaceItemProps) {
        super(props);
        this.state = {
            fillWidth: 100,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ fillWidth: this.calculateFillWidth() });
        }, 100);
    }

    calculateFillWidth() {
        const { size, type } = this.props;
        const totalSpace = type === 'total' ? parseFloat(size) : 30;
        return type === 'used' ? (parseFloat(size) / totalSpace) * 100 : 100;
    }

    render() {
        const { label, size, type } = this.props;
        const { fillWidth } = this.state;

        return (
            <div className={`disk-space-item ${type}`}>
                <div className="disk-space-item_bar">
                    <div
                        className="disk-space-item_fill"
                        style={{ width: `${fillWidth}%` }}
                    />
                </div>
                <div className="disk-space-item_label">
                    {label}: {size}
                </div>
            </div>
        );
    }
}
