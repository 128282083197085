import DashboardFacade from "../../../Domain/Dashboard/DashboardFacade";
import IDiskSpaceInfo from "../../../Infrastructure/Http/Disk/IDiskSpaceInfo";

export default class DashboardAdapter {

    private  readonly dashboardFacade: DashboardFacade;

    constructor() {
        this.dashboardFacade = new DashboardFacade();
    }

    public async findSpaceInfo(): Promise<IDiskSpaceInfo> {
        return await this.dashboardFacade.findSpaceInfo();
    }
}