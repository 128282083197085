import React, {Component, ComponentType} from "react";
import AbstractLayout from "./AbstractLayout";

const WithLayout = <P extends object>(WrappedComponent: ComponentType<any>) => {
    class WithLayout extends Component<any> {

        render() {
            return (
                <AbstractLayout {...this.props}>
                    <WrappedComponent {...this.props as any} />
                </AbstractLayout>
            );
        }
    }

    return WithLayout;
};

export default WithLayout;