import {IRecording, IRecordingFilter} from "../../Http/Recording/IRecording";
import {IRecordingPaginator} from "../../Http/Recording/IRecordingPaginator";
import RecordingRepository from "../../Repository/Recording/RecordingRepository";

export default class RecordingService {

    public async findAll(filters: IRecordingFilter): Promise<IRecordingPaginator> {
        return await RecordingRepository.FindAll(filters);
    }

    public async findById(id: number): Promise<IRecording> {
        return await RecordingRepository.FindById(id);
    }

    public async update(recording: IRecording): Promise<void> {
        return await RecordingRepository.Update(recording);
    }
    public async delete(id: number): Promise<void> {
        return await RecordingRepository.Delete(id);
    }
}
