import React, {ChangeEvent, ChangeEventHandler, Component} from 'react';
import TableTextFieldFilter from "../../Shared/Table/Field/TextFieldFilter/TableTextFieldFilter";
import { IRecordingFilter } from "../../../Http/Recording/IRecording";
import { Button } from "@mui/material";
import IUser from "../../../Http/User/IUser";
import UsersList from "../../User/UsersList/UsersList";
import UserService from "../../../Service/User/UserService";
import CameraService from "../../../Service/Camera/CameraService";
import { ICamera } from "../../../Http/Camera/ICamera";
import CamerasList from "../../Camera/CamerasList/CamerasList";
import {ITableSelectItem} from "../../Shared/TableSelect/TableSelect";
import TextBox from "../../Shared/TextBox/TextBox";
import "./RecordingsTableFilterStyle.scss";
import {CheckBox} from "../../Shared/CheckBox/CheckBox";
import {AlertContainer} from "../../Shared/AlertContainer/AlertContainer";
export interface IRecordingsTableFilterProps {
    onFilter: (filter: IRecordingFilter) => void;
}

export interface IRecordingsTableFilterState {
    users: IUser[];
    cameras: ICamera[];
    selectedUserItem?: ITableSelectItem;
    selectedCameraItem?: ITableSelectItem;
    filters: IRecordingFilter;
    dateError: boolean;
}

export class RecordingsTableFilter extends Component<IRecordingsTableFilterProps, IRecordingsTableFilterState> {
    private readonly usersService: UserService;
    private readonly cameraService: CameraService;

    constructor(props: IRecordingsTableFilterProps) {
        super(props);
        this.usersService = new UserService();
        this.cameraService = new CameraService();
        this.onTitleChanged = this.onTitleChanged.bind(this);
        this.onUserIdChanged = this.onUserIdChanged.bind(this);
        this.onDateTimeFromChanged = this.onDateTimeFromChanged.bind(this);
        this.onDateTimeToChanged = this.onDateTimeToChanged.bind(this);
        this.onKeywordChanged = this.onKeywordChanged.bind(this);
        this.onCameraChanged = this.onCameraChanged.bind(this);
        this.onIsEvidenceChanged = this.onIsEvidenceChanged.bind(this);
        this.onClearAll = this.onClearAll.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.state = {
            users: [],
            cameras: [],
            selectedUserItem: undefined,
            selectedCameraItem: undefined,
            filters: {
                title: "",
                userId: 0,
                dateFrom: "",
                dateTo: "",
                keyword: "",
                serialNumber: "",
                isEvidence: false
            },
            dateError: false
        };
    }

    private async fetchUsers() {
        await this.usersService.findAll({}).then((page) => {
            this.setState({ users: page.items });
        });
    }

    private async fetchCameras() {
        await this.cameraService.findAll().then((page) => {
            this.setState({ cameras: page.items });
        });
    }

    private onTitleChanged(e: string) {
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                title: e
            }
        }), this.onFilter);
    }

    private onUserIdChanged(e: ITableSelectItem) {
        this.setState(prevState => ({
            selectedUserItem: e,
            filters: {
                ...prevState.filters,
                userId: e.id
            }
        }), this.onFilter);
    }

    private onDateTimeFromChanged(e: string) {
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                dateFrom: e
            }
        }), () => {
            this.validateDates();

            if (this.state.dateError) {
                return;
            }

            this.onFilter();
        });
    }

    private onDateTimeToChanged(e: string) {
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                dateTo: e
            }
        }),() => {
            this.validateDates();

            if (this.state.dateError) {
                return;
            }
            this.onFilter();
        });
    }

    private onKeywordChanged(e: string) {
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                keyword: e
            }
        }), this.onFilter);
    }

    private onCameraChanged(e: ITableSelectItem) {
        this.setState(prevState => ({
            selectedCameraItem: e,
            filters: {
                ...prevState.filters,
                serialNumber: e.label
            }
        }), this.onFilter);
    }

    private onIsEvidenceChanged(e: React.ChangeEvent<HTMLInputElement> ) {
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                isEvidence: e.target?.checked
            }
        }), this.onFilter);
    }

    private onClearAll() {
        this.setState({
            selectedUserItem: undefined,
            filters: {
                title: "",
                userId: 0,
                dateFrom: "",
                dateTo: "",
                keyword: "",
                serialNumber: "",
                isEvidence: false
            },
            dateError: false
        }, () => {
            this.props.onFilter(this.state.filters);
        });
    }

    private onFilter() {
        if (this.validateDates()) {
            this.props.onFilter(this.state.filters);
        }
    }

    private validateDates(): boolean {
        const { dateFrom, dateTo } = this.state.filters;

        if (!dateTo || dateTo.length === 0) {
            this.setState({ dateError: false });
            return true;
        }

        if (dateFrom && dateTo) {
            if (dateFrom > dateTo) {
                this.setState({ dateError: true });
                return false;
            } else {
                this.setState({ dateError: false });
                return true;
            }
        }

        return true;
    }

    componentDidMount() {
        this.fetchUsers().then(() => {
            this.fetchCameras();
        });
    }

    render() {
        const { dateError } = this.state;
        return (
            <div className="recordings-filter">
                <div className="recordings-filter_container">
                    <div className="recordings-filter_container_header">
                        <div className="recordings-filter_container_header_label">
                            Filtry
                        </div>

                        <div className="recordings-filter_container_header_clear" onClick={this.onClearAll}>
                            Wyczyść
                        </div>
                    </div>
                    <div className="recordings-filter_container_body">
                        <TextBox label="Słowo kluczowe"  placeholder="Przykład - Marszałkowska" value={this.state.filters?.keyword}  onChange={(e) => this.onKeywordChanged(e?.target?.value)}/>
                        <UsersList label="Identyfikatory Użytkownika" value={this.state.selectedUserItem} onChange={this.onUserIdChanged} users={this.state.users} />
                        <CamerasList label="Identyfikatory Kamery" value={this.state.selectedCameraItem} onChange={this.onCameraChanged} cameras={this.state.cameras} />

                        <TextBox label="Data i godzina od" placeholder="Przykład - 08-08-2008" type="datetime-local" value={this.state.filters?.dateFrom}  onChange={(e) => this.onDateTimeFromChanged(e?.target?.value)}/>
                        <TextBox label="Data i godzina do" placeholder="Przykład - 16-08-2008" type="datetime-local" value={this.state.filters?.dateTo}  onChange={(e) => this.onDateTimeToChanged(e?.target?.value)}/>
                        <CheckBox label="Dowód"  type="checkbox" onChange={this.onIsEvidenceChanged} checked={this.state.filters?.isEvidence}/>

                        {dateError &&
                            <AlertContainer severity="error">
                                Data "od" nie może być większa niż data "do".
                            </AlertContainer>
                        }
                    </div>
                    <div className="recordings-filter_container_footer">
                    </div>
                </div>
            </div>
        );
    }
}
