import React, { Component } from 'react';
import { CircularProgress, Box } from "@mui/material";
import './PageLoaderStyle.scss';

export interface IPageLoaderProps {}

export interface IPageLoaderState {}

export class PageLoader extends Component<IPageLoaderProps, IPageLoaderState> {
    constructor(props: IPageLoaderProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="page-loader">
                <Box position="relative" display="inline-flex">
                    <CircularProgress
                        variant="indeterminate"
                        sx={{
                            color: 'rgb(4,117,154)',
                            size: 400,
                            thickness: 6,
                        }}
                    />
                    <Box
                        position="absolute"
                        top="50%"
                        left="50%"
                        sx={{
                            transform: 'translate(-50%, -50%)',
                            width: '48px',
                            height: '48px',
                        }}
                    >
                        <img
                            src="/static/media/bwcImage.png"
                            alt="Camera"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                            }}
                        />
                    </Box>
                </Box>
            </div>
        );
    }
}
