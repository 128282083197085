import CameraService from "../../Infrastructure/Service/Camera/CameraService";
import {ICamera, ICameraFIlter} from "../../Infrastructure/Http/Camera/ICamera";
import {ICameraPaginator} from "../../Infrastructure/Http/Camera/ICameraPaginator";

export default class CameraFacade {
    private readonly cameraService: CameraService;
    constructor() {
        this.cameraService = new CameraService();
    }

    public async findAll(filters: ICameraFIlter|undefined = undefined): Promise<ICameraPaginator> {
        return await this.cameraService.findAll(filters);
    }

    public async findById(id: number): Promise<ICamera> {
        return await this.cameraService.findById(id);
    }
}