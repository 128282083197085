import React, {Component} from 'react';
import "./TextBoxStyle.scss";
export interface ITextBoxProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>{
    label?: string
    placeholder?: string
}

export interface ITextBoxState {

}

export default class TextBox extends Component<ITextBoxProps, ITextBoxState> {

    constructor(props: ITextBoxProps) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="textbox">
                <div className="textbox_label">
                    {this.props.label && (
                        <span>{this.props.label}</span>
                    )}
                </div>
                <div className="textbox_input">
                    <input {...this.props}/>
                </div>
            </div>
        );
    }
}