import React, {Component} from 'react';
import {AlertContainer} from "../AlertContainer";

export interface ISomethingWentWrongAlertProps {
    stackTrace?: any;
}

export interface ISomethingWentWrongAlertState {

}

export class SomethingWentWrongAlert extends Component<ISomethingWentWrongAlertProps, ISomethingWentWrongAlertState> {
    constructor(props: ISomethingWentWrongAlertProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <AlertContainer variant="filled" severity="error">
                <div className="mt-2">
                    Coś poszło nie tak, skontaktuj się z Administratorem
                </div>

                {this.props.stackTrace && (
                    <div className="mt-2">
                        <pre>{this.props.stackTrace}</pre>
                    </div>
                )}
            </AlertContainer>
        );
    }
}
