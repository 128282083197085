import {IUserPermissions} from "../../Http/User/IUser";
import {GetHttpClient} from "../../HttpClient/Factory/HttpClientFactory";
import {ICameraPaginator} from "../../Http/Camera/ICameraPaginator";
import {ICamera, ICameraFIlter} from "../../Http/Camera/ICamera";

export default class CameraRepository {


    public static async FindAll(filters: ICameraFIlter|undefined = undefined): Promise<ICameraPaginator> {
        let url: string = CameraRepository.BuildFilters(`/api/cameras`, filters);


        return GetHttpClient().GET(url).then((response) => {
            return response.data
        })
    }

    public static async FindById(id: number): Promise<ICamera> {
        let url: string = `/api/cameras/${id}`;

        return  GetHttpClient().GET(url).then((response) => {
            return response.data
        })
    }

    public static async FindRecordings(id: number): Promise<ICamera> {
        let url: string = `/api/cameras/${id}`;

        return  GetHttpClient().GET(url).then((response) => {
            return response.data
        })
    }
    private static BuildFilters(url: string, filters: ICameraFIlter|undefined = undefined): string {
        if (filters) {
            const queryParams: string[] = [];

            // Check each filter property and add to the query parameters if it exists
            if (filters.page) queryParams.push(`page=${filters.page}`);
            if (filters.limit) queryParams.push(`limit=${filters.limit}`);
            if (filters.order) queryParams.push(`order=${filters.order}`);
            if (filters.cameraId) queryParams.push(`cameraId=${filters.cameraId}`);
            if (filters.userId) queryParams.push(`userId=${filters.userId}`);
            if (filters.statuses) {
                filters.statuses.forEach((status => {
                    queryParams.push(`statuses[]=${status}`);
                }))
            }

            if (queryParams.length > 0) {
                url += `?${queryParams.join('&')}`;
            }
        }
        return url;
    }
}