import {IUserDegree} from "../../Http/User/IUser";
import UserDegreeRepository from "../../Repository/User/UserDegreeRepository";
import UserDegreeStorage from "../../Storage/User/UserDegreeStorage";

export default class UserDegreeService {

    private readonly userDegreeStorage: UserDegreeStorage;
    public constructor() {
        this.userDegreeStorage = new UserDegreeStorage();
    }

    public async findAll(): Promise<IUserDegree[]> {
        return await this.userDegreeStorage.findAll();
    }
}