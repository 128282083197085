import {IUserAuthCredentials} from "../../Http/Auth/IUserAuthCredentials";
import {IUserAuthToken} from "../../Http/Auth/IUserAuthToken";
import {GetHttpClient} from "../../HttpClient/Factory/HttpClientFactory";
import IPageIterator, {IPaginator} from "../../Paginator/Paginator";
import {IUserPaginator} from "../../Http/User/IUserPaginator";
import IUser, {IUserDegree} from "../../Http/User/IUser";

export default class UserDegreeRepository {

    public static async FindAll(): Promise<IUserDegree[]> {
        const url: string = `/api/core/users/degrees`;
        return  GetHttpClient().GET(url).then((response) => {
            return response.data
        })
    }
}