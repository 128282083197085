import {IRecordingNote} from "../../Infrastructure/Http/Recording/IRecordingNote";
import RecordingNoteRepository from "../../Infrastructure/Repository/Recording/RecordingNoteRepository";
import {IRecording} from "../../Infrastructure/Http/Recording/IRecording";
import RecordingNoteService from "../../Infrastructure/Service/Recording/RecordingNoteService";

export default class RecordingNoteFacade {

    private readonly recordingNoteService: RecordingNoteService;

    constructor() {
        this.recordingNoteService = new RecordingNoteService();
    }
    public async findAll(id: number): Promise<IRecordingNote[]> {
        return await this.recordingNoteService.findAll(id);
    }

    public async findById(id: number): Promise<IRecording> {
        return await this.recordingNoteService.findById(id);
    }

    public async persist(id: number, http: IRecordingNote): Promise<IRecordingNote> {
        return await this.recordingNoteService.persist(id, http);
    }

    public async update(id: number, http: IRecordingNote): Promise<IRecordingNote> {
        return await this.recordingNoteService.update(id, http);
    }

    public async delete(id: number): Promise<any> {
        return await this.recordingNoteService.delete(id);
    }
}