import React, {Component} from 'react';
import {Route, Routes} from "react-router-dom";
import LoginView from "../../../Application/Auth/View/Login/LoginView";
import {PublicPath} from "./PublicPath";
import LogoutView from "../../../Application/Auth/View/Logout/LogoutView";

export interface IPublicProps {

}

export interface IPublicState {

}

export default class PublicRoutes extends Component<IPublicProps, IPublicState> {

    constructor(props: IPublicProps) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <Routes>
                <Route index element={<LoginView />}/>
                <Route path={PublicPath.LOGIN} element={<LoginView />}/>
                <Route path={PublicPath.LOGOUT} element={<LogoutView />}/>
                <Route path={'*'} element={ <LoginView/>}/>
            </Routes>

        );
    }
}