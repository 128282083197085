import React, {Component} from 'react';
import {IRecordingNote} from "../../../Http/Recording/IRecordingNote";
import IUser from "../../../Http/User/IUser";
import {RecordingNoteItem} from "./RecordingNoteItem";
import {Divider} from "@mui/material";
import "./RecordingNotesListStyle.scss";
import RecordingNoteInput from "./RecordingNoteInput";
export interface IRecordingNotesListProps {
    id: number;
  notes: IRecordingNote[];
  user: IUser;

  onAdd: (e: string) => void;
  onSave: (id: number, note: IRecordingNote) => void;
  onDelete: (id: number) => void;
}

export interface IRecordingNotesListState {

}

export class RecordingNotesList extends Component<IRecordingNotesListProps, IRecordingNotesListState> {
    constructor(props: IRecordingNotesListProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="recording-notes-list">
                <div className="recording-notes-list_container">
                  <div className="recording-notes-list_container_header">
                    <div className="recording-notes-list_container_header_label">
                      Notatki
                    </div>
                    <div className="recording-notes-list_container_header_divider">
                      <Divider/>
                    </div>
                  </div>
                  <div className="recording-notes-list_container_body">
                      {this.props.user.permissions?.canAddNoteToRecording && (
                          <RecordingNoteInput onAdd={this.props.onAdd}/>
                      )}
                    {this.props.notes.length === 0 && (
                        <div className="recording-notes-list_container_body_no_notes">Brak notatek</div>
                    )}

                    {this.props.notes.length > 0 && this.props.notes.map((note, idx) => {
                      return (
                          <RecordingNoteItem
                              note={note}
                              key={idx}
                              canEdit={this.props.user?.id === note.user?.id}
                              canDelete={this.props.user?.id === note.user?.id}

                              onSave={this.props.onSave}
                              onDelete={this.props.onDelete}
                          />
                      )
                    })}
                  </div>
                </div>
            </div>
        );
    }
}
