import React, {Component} from 'react';
import './SingleUserProfileViewStyle.scss';
import WithLayout from "../../../../Infrastructure/HOC/Layout/WithLayout";
import withRouter, {IRouterProps} from "../../../../Infrastructure/HOC/Router/WithRouter";
import {ProfileHeader} from "../../../../Infrastructure/Component/User/ProfileHeader/ProfileHeader";
import UserAdapter from "../../Adapter/UserAdapter";
import {AuthorizedPath} from "../../../../Infrastructure/Route/Authorized/AuthorizedPath";
import {IAbstractLayoutChildrenProps} from "../../../../Infrastructure/HOC/Layout/AbstractLayout";
import {UserRecordingsList} from "../../../../Infrastructure/Component/User/RecordingsList/UserRecordingsList";
import RecordingAdapter from "../../../Recording/Adapter/RecordingAdapter";
import {GridRowsProp} from "@mui/x-data-grid";
import {UserActivityLogList} from "../../../../Infrastructure/Component/User/ActivityLogList/UserActivityLogList";
import UserActivityLogAdapter from "../../Adapter/UserActivityLogAdapter";
import CameraAdapter from "../../../Camera/Adapter/CameraAdapter";
import IUser from "../../../../Infrastructure/Http/User/IUser";
interface RouterParams {
    id: string;
}
export interface ISingleUserProfileViewProps extends IAbstractLayoutChildrenProps, IRouterProps {
    params: RouterParams
}

export interface ISingleUserProfileViewState {
    canEdit: boolean;
    user: IUser;
    recordingRows: GridRowsProp;
    isRecordingsTableLoading: boolean;

    camerasRows: GridRowsProp;
    isCamerasTableLoading: boolean;

    activityLogRows: GridRowsProp;
    isActivityLogsTableLoading: boolean;
}

class SingleUserProfileView extends Component<ISingleUserProfileViewProps, ISingleUserProfileViewState> {

    private readonly userAdapter: UserAdapter;
    private readonly userActivityLogAdapter: UserActivityLogAdapter;
    private readonly recordingAdapter: RecordingAdapter;
    private readonly cameraAdapter: CameraAdapter;

    constructor(props: ISingleUserProfileViewProps) {
        super(props);
        this.userAdapter = new UserAdapter();
        this.userActivityLogAdapter = new UserActivityLogAdapter();
        this.recordingAdapter = new RecordingAdapter();
        this.cameraAdapter = new CameraAdapter();

        this.state = {
            user: {} as IUser,
            canEdit: (Number(this.props.params?.id) === this.props.currentUser?.id),
            recordingRows: [],
            isRecordingsTableLoading: true,

            camerasRows: [],
            isCamerasTableLoading: true,

            activityLogRows: [],
            isActivityLogsTableLoading: true
        }
    }


    private async fetchUser() {
        await this.userAdapter.findById(Number(this.props.params?.id)).then((user) => {
            this.setState({user: user})
        })
    }

    private async fetchRecordings() {
        await this.recordingAdapter.findAll({userId: Number(this.props.params?.id)}).then((page) => {
            const rows = page.items?.map((row) => {
                return {
                    id: row.id,
                    title: row.title,
                    url: row.file?.url,
                    userId: row.user?.id,
                    startDate: row.file?.recordedAt,
                    endDate: row.file?.endedAt,
                    isEvidence: row.isEvidence
                }
            });
            this.setState({recordingRows: rows, isRecordingsTableLoading: false});
        }).catch((error) => {
            this.setState({isRecordingsTableLoading: false})
        })
    }

    private async fetchCameras(){
        await this.cameraAdapter.findAll({page: 1, limit: 5, userId: Number(this.props.params?.id)}).then((iterator) => {
            const rows: GridRowsProp = iterator?.items?.map((row) => {
                return {
                    id: row?.id,
                    userName: row?.userName,
                    status: row?.status,
                    batteryLevel: row?.batteryLevel
                }
            });

            this.setState({camerasRows: rows, isCamerasTableLoading: false});
        }).catch((error) => {
            this.setState({isCamerasTableLoading: false});
        })
    }

    private async fetchActivityLogs() {
        await this.userActivityLogAdapter.findAll({page: 1, limit: 50}, {userId : Number(this.props.params?.id) }).then((page) => {
            const rows = page.items?.map((row) => {
                return {
                    id: row.id,
                    description: row.description,
                    createdAt: row.createdAt,
                }
            });
            this.setState({activityLogRows: rows, isActivityLogsTableLoading: false});
        }).catch((error) => {
            this.setState({isActivityLogsTableLoading: false})
        })
    }

    componentDidMount() {
        this.fetchUser();
        this.fetchRecordings();
        this.fetchActivityLogs();
    }

    render() {
        return (
            <div className="single-user-profile">
                <div className="single-user-profile_container">
                    <div className="single-user-profile_container_header">
                        {this.state.user.id && (
                            <ProfileHeader
                                firstName={this.state.user?.profile?.firstName ?? "-"}
                                lastName={this.state.user?.profile?.lastName ?? "-"}
                                userName={this.state.user?.userName ?? "-"}
                                roles={this.state.user?.roles}
                                canEdit={this.state.canEdit}
                                editRoute={AuthorizedPath.USER_EDIT(this.state.user?.id?.toString() ?? "0")}
                            />
                        )}
                    </div>

                    <div className="single-user-profile_container_body">
                        <div className="single-user-profile_container_body_segment">

                            <div className="single-user-profile_container_body_segment_content">
                                <UserRecordingsList
                                    rows={this.state.recordingRows}
                                    isTableLoading={this.state.isRecordingsTableLoading}
                                />
                            </div>
                        </div>
                        <div className="single-user-profile_container_body_segment">
                            <div className="single-user-profile_container_body_segment_content">
                                <UserActivityLogList
                                    rows={this.state.activityLogRows}
                                    isTableLoading={this.state.isActivityLogsTableLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
             </div>
        );
    }
}

export default withRouter(WithLayout(SingleUserProfileView));
