import {
    useLocation,
    useNavigate,
    useParams,

} from "react-router-dom";

export interface IRouterProps {
    location: any;
    navigate: any;
    params: any;
}
function withRouter(Component: any) {
    function ComponentWithRouterProp(props: any) {

        const routerProps: IRouterProps = {
            location: useLocation(),
            navigate: useNavigate(),
            params: useParams(),
        }
        const mergedProps = {...props, ...routerProps};
        return (
            <Component
                {...mergedProps}
            />
        );
    }

    return ComponentWithRouterProp;
}

export default withRouter;
