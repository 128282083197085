import {ICamera, ICameraFIlter} from "../../Http/Camera/ICamera";
import {ICameraPaginator} from "../../Http/Camera/ICameraPaginator";
import CameraRepository from "../../Repository/Camera/CameraRepository";

export default class CameraService {

    public async findAll(filters: ICameraFIlter|undefined = undefined): Promise<ICameraPaginator> {
        return await CameraRepository.FindAll(filters);
    }

    public async findById(id: number): Promise<ICamera> {
        return await CameraRepository.FindById(id);
    }
}