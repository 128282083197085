import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import  EntryPoint  from './Infrastructure/Route/EntryPoint';
 import "./Infrastructure/Style/styles.scss";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
        <BrowserRouter>
            <EntryPoint />
        </BrowserRouter>
);
