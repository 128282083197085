import {IRecordingEvidence} from "../../Http/Recording/IRecording";
import RecordingEvidenceRepository from "../../Repository/Recording/RecordingEvidenceRepository";

export default class RecordingEvidenceService {
    public async persist(id: number, http: IRecordingEvidence): Promise<IRecordingEvidence> {
        return await RecordingEvidenceRepository.Persist(id, http);
    }

    public async update(recordingId: number, evidenceId: number| undefined, http: IRecordingEvidence): Promise<IRecordingEvidence> {
        return await RecordingEvidenceRepository.Update(recordingId, evidenceId, http);
    }

    public async delete(recordingId: number, evidenceId: number | undefined): Promise<void> {
        return await RecordingEvidenceRepository.Delete(recordingId, evidenceId);
    }
}
