import React, {Component} from "react";
import {Box, Card, CardContent, CardHeader, Divider, IconButton, Typography} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {IRecordingEvidenceNote} from "../../../Http/Recording/IRecordingEvidenceNote";

interface EvidenceNoteProps {
    notes: IRecordingEvidenceNote[];
}

interface EvidenceNoteState {
    editingNote: IRecordingEvidenceNote;
    newNote: string;
    noteEditMode: boolean;
}

export default class EvidenceNote extends Component<EvidenceNoteProps, EvidenceNoteState> {

    constructor(props: EvidenceNoteProps) {
        super(props);
        this.state = {
            editingNote: {} as IRecordingEvidenceNote,
            newNote: '',
            noteEditMode: false,
        }
    }

    onEditNoteClick = (note: IRecordingEvidenceNote) => {
        this.setState({ editingNote: note, newNote: note.text, noteEditMode: true });
    };

    render() {
        return (
            <>
                <Typography variant="h6" sx={{ mt: 3, mb: 2, color: "#ffffff" }}>
                    Notatki:
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 3,
                        bgcolor: "#0F1624",
                        borderRadius: 2,
                        p: 2,
                        boxShadow: 3,
                    }}
                >
                    {this.props.notes.length === 0 ? (
                        <Typography
                            variant="body2"
                            sx={{ color: "text.secondary", fontStyle: "italic" }}
                        >
                            Brak notatek
                        </Typography>
                    ) : (
                        this.props.notes.map((note) => (
                            <Card
                                key={note.id}
                                variant="outlined"
                                sx={{
                                    boxShadow: 3,
                                    bgcolor: "#121A2B",
                                    borderRadius: 2,
                                    transition: "0.3s",
                                    "&:hover": {
                                        boxShadow: 6,
                                    },
                                }}
                            >
                                <CardHeader
                                    title={`${note.user?.firstName} ${note.user?.lastName}`}
                                    subheader={new Date(
                                        note.updatedAt ?? ""
                                    ).toLocaleDateString()}
                                    sx={{
                                        bgcolor: "#161f34",
                                        fontWeight: "bold",
                                        color: "#ffffff",
                                        p: 1.5,
                                    }}
                                />
                                <CardContent>
                                    <Typography variant="body2" sx={{ color: "#ffffff" }}>
                                        {note.text}
                                    </Typography>
                                </CardContent>
                                <Divider sx={{ bgcolor: "#2C3A4B" }} />
                                <IconButton
                                    onClick={() => this.onEditNoteClick(note)}
                                    color="primary"
                                    sx={{ mt: 1, ml: 1 }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Card>
                        ))
                    )}
                </Box>
            </>
        );
    }
}
