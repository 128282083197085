import UserActivityLogFacade from "../../../Domain/User/UserActivityLogFacade";
import IUserActivityLog, {
    IUserActivityLogFilter,
    IUserActivityLogPaginator
} from "../../../Infrastructure/Http/User/IUserActivityLog";
import {IPaginator} from "../../../Infrastructure/Paginator/Paginator";

export default class UserActivityLogAdapter {

    private readonly userActivityLogFacade: UserActivityLogFacade;
    constructor() {
        this.userActivityLogFacade = new UserActivityLogFacade();
    }
    public async findAll(page: IPaginator, filters: IUserActivityLogFilter): Promise<IUserActivityLogPaginator>  {
        return await this.userActivityLogFacade.findAll(page, filters);
    }


    public async persist(http: IUserActivityLog): Promise<void> {
        return await this.userActivityLogFacade.persist(http);
    }
}
