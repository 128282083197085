import IDiskSpaceInfo from "../../Infrastructure/Http/Disk/IDiskSpaceInfo";
import DiskRepository from "../../Infrastructure/Repository/Disk/DiskRepository";
import DiskService from "../../Infrastructure/Service/Disk/DiskService";

export default class DashboardFacade {
    private readonly diskService: DiskService;
    constructor() {
        this.diskService = new DiskService();
    }

    public async findSpaceInfo(): Promise<IDiskSpaceInfo> {
        return await this.diskService.findSpaceInfo();
    }
}