import React, {Component} from 'react';
import TableTextFieldFilter from "../../Shared/Table/Field/TextFieldFilter/TableTextFieldFilter";
import './CameraTableFilterStyle.scss';
import {ICameraFIlter} from "../../../Http/Camera/ICamera";

export interface ICamerasTableFilterProps {
    onFilter?: (e: ICameraFIlter) => void;
}

export interface ICamerasTableFilterState {
    filters: ICameraFIlter
}

export default class CamerasTableFilter extends Component<ICamerasTableFilterProps, ICamerasTableFilterState> {

    constructor(props: ICamerasTableFilterProps) {
        super(props);
        this.onIdChanged = this.onIdChanged.bind(this);
        this.onCameraIdChanged = this.onCameraIdChanged.bind(this);
        this.onCameraUserIdChanged = this.onCameraUserIdChanged.bind(this);
        this.onStatusChanged = this.onStatusChanged.bind(this);

        this.onClearAll = this.onClearAll.bind(this);
        this.state = {
            filters: {} as ICameraFIlter
        }
    }

    private onIdChanged(id: string) {
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                id: id
            }
        }))
    }

    private onCameraIdChanged(cameraId: string) {
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                cameraId: Number(cameraId)
            }
        }))
    }

    private onCameraUserIdChanged(cameraUserId: string) {
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                cameraUserId: cameraUserId
            }
        }))
    }
    private onStatusChanged(statuses: any[]) {
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                statuses: statuses
            }
        }))
    }


    private onClearAll() {
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                cameraId: undefined,
                userId: undefined,
                statuses: [],
            }
        }))
    }

    render() {
        return (
            <div className="camera-filter">
                <div className="camera-filter_container">
                    <div className="camera-filter_container_header">
                        <div className="camera-filter_container_header_label">
                            Filtry
                        </div>

                        <div className="camera-filter_container_header_clear" onClick={this.onClearAll}>
                            Wyczyść
                        </div>
                    </div>
                    <div className="camera-filter_container_body">
                        {/*<TableTextFieldFilter label="Status" value={this.state.filters?.statuses?.filter} onFilterChange={this.onStatusChanged}/>*/}
                        <TableTextFieldFilter label="ID Kamery" value={this.state.filters?.cameraId} onFilterChange={this.onIdChanged}/>
                        <TableTextFieldFilter label="ID Użytkownik" value={this.state.filters?.userId} onFilterChange={this.onCameraUserIdChanged}/>
                    </div>
                    <div className="camera-filter_container_footer">
                        <hr/>
                    </div>
                </div>
            </div>
        );
    }
}