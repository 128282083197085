import React, {Component} from 'react';
import {Route, Routes} from "react-router-dom";
import HomeView from '../../../Application/Dashboard/View/Home/HomeView';

import {AuthorizedPath} from "./AuthorizedPath";
import UsersListView from "../../../Application/User/View/List/UsersListView";
import CamerasListView from "../../../Application/Camera/View/List/CamerasListView";
import SingleUserProfileView from "../../../Application/User/View/Single/SingleUserProfileView";
import SingleCameraView from "../../../Application/Camera/View/Single/SingleCameraView";
import UserAccountView from "../../../Application/User/View/Account/UserAccountView";
import RecordingsListView from "../../../Application/Recording/View/List/RecordingsListView";
import SingleRecordingView from "../../../Application/Recording/View/Single/SingleRecordingView";
import NotificationListView from "../../../Application/Notifications/View/NotificationListView";
import NotFoundView from "../../../Application/NotFound/View/NotFoundView";
import MyUserView from "../../../Application/User/View/Me/MyUserView";

export interface IAuthorizedRoutesProps {

}

export interface IAuthorizedRoutesState {

}

export default class AuthorizedRoutes extends Component<IAuthorizedRoutesProps, IAuthorizedRoutesState> {

    constructor(props: IAuthorizedRoutesProps) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <Routes>
                <Route index element={<HomeView  />}/>
                <Route path={AuthorizedPath.HOME} element={<HomeView />}/>
                <Route path={AuthorizedPath.USERS} element={<UsersListView />}/>
                <Route path={AuthorizedPath.USER(':id')} element={<SingleUserProfileView/>}/>
                <Route path={AuthorizedPath.MY_USER} element={<MyUserView/>}/>
                <Route path={AuthorizedPath.USER_EDIT(':id')} element={<UserAccountView/>}/>
                <Route path={AuthorizedPath.CAMERAS} element={<CamerasListView />}/>
                <Route path={AuthorizedPath.CAMERA(':id')} element={<SingleCameraView />}/>
                <Route path={AuthorizedPath.RECORDINGS} element={<RecordingsListView />}/>
                <Route path={AuthorizedPath.RECORDING(':id')} element={<SingleRecordingView />}/>
                <Route path={AuthorizedPath.NOTIFICATIONS} element={<NotificationListView />}/>
                <Route path={'*'} element={ <NotFoundView />}/>
            </Routes>
        );
    }
}
