import {ICamera, ICameraFIlter} from "../../../Infrastructure/Http/Camera/ICamera";
import {ICameraPaginator} from "../../../Infrastructure/Http/Camera/ICameraPaginator";
import CameraFacade from "../../../Domain/Camera/CameraFacade";

export default class CameraAdapter {

    private readonly cameraFacade: CameraFacade;
    constructor() {
        this.cameraFacade = new CameraFacade();
    }
    public async findAll(filters: ICameraFIlter|undefined = undefined): Promise<ICameraPaginator> {
        return await this.cameraFacade.findAll(filters);
    }

    public async findById(id: number): Promise<ICamera> {
        return await this.cameraFacade.findById(id);
    }
}