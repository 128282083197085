import React, {Component} from 'react';
import {Alert, Button, TextField} from "@mui/material";
import AuthAdapter from "../../Adapter/AuthAdapter";
import {AxiosError} from "axios";
import HttpStatusCode from "../../../../Infrastructure/HttpClient/Enum/ResponseCode";
import TextBox from "../../../../Infrastructure/Component/Shared/TextBox/TextBox";
import InvalidAuthTokenException from "../../../../Infrastructure/Exception/Shared/InvalidAuthTokenException";
import {AlertContainer} from "../../../../Infrastructure/Component/Shared/AlertContainer/AlertContainer";
import gradient_image from "../../../../Infrastructure/Assets/Svg/login_left_image.svg";
import './LoginViewStyle.scss'
import {CheckBox} from "../../../../Infrastructure/Component/Shared/CheckBox/CheckBox";
export interface ILoginViewProps {

}

export interface ILoginViewState {
    userName: string;
    password: string;

    errorMessage: string;
}

class LoginView extends Component<ILoginViewProps, ILoginViewState> {

    private readonly authAdapter: AuthAdapter;
    constructor(props: ILoginViewProps) {
        super(props);
        this.authAdapter = new AuthAdapter();
        this.onUserNameChanged = this.onUserNameChanged.bind(this);
        this.onPasswordChanged = this.onPasswordChanged.bind(this);
        this.onLogin = this.onLogin.bind(this);
        this.state = {
            userName: "",
            password: "",
            errorMessage: ""
        }
    }

    private onUserNameChanged(userName: string) {
        this.setState({userName: userName})
    }

    private onPasswordChanged(password: string) {
        this.setState({password: password})
    }

    private async onLogin() {
        try{

            if (this.state.password.length < 8) {
                this.setState({errorMessage: "Hasło musi zawierać conajmniej 8 znaków"})
                return;
            }
            this.setState({errorMessage: ""}, async () => {
                await this.authAdapter.login({
                    userName: this.state.userName,
                    password:this.state.password
                })
                .catch( (exception) => {
                    if(exception instanceof InvalidAuthTokenException) {
                        this.setState({errorMessage: "Podałeś błędne dane logowania"})
                    }
                });
            })
        }
        catch (error: any) {

            const exception: AxiosError = error as AxiosError;

            if (exception?.response?.status === HttpStatusCode.UNAUTHORIZED) {
                this.setState({errorMessage: "Wprowadziłeś niepoprawną nazwe użytkownika lub hasło"})
            }
        }
    }

    componentDidMount() {
        this.authAdapter.logout(false);
    }

    render() {
        return (
            <div className="login-view">
                <div className="login-view_container">
                    <div className="login-view_container_left">
                        <div className="login-view_container_left_header">
                            <p className="text-8xl font-bold">KTAVisor</p>
                            <p className="text-1xl font-bold">System zarządzania kamerami</p>
                        </div>

                        <div className="login-view_container_left_body">
                            <TextBox
                                type="text"
                                label="Nazwa użytkownika"
                                value={this.state.userName}
                                onChange={(e) => this.onUserNameChanged(e.target.value)}
                            />

                            <TextBox
                                type="password"
                                label="Hasło"
                                value={this.state.password}
                                onChange={(e) => this.onPasswordChanged(e.target.value)}
                            />

                            <Button
                                variant="contained"
                                size="small"
                                className="login-btn"
                                onClick={this.onLogin}
                            >
                                Zaloguj
                            </Button>

                            {this.state.errorMessage && (
                                <AlertContainer severity="error">{this.state.errorMessage}</AlertContainer>
                            )}
                        </div>
                    </div>

                    <div className="login-view_container_right">
                        <div className="login-view_container_right_image">

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginView
