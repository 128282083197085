import React, {Component} from 'react';
import withRouter, {IRouterProps} from "../../../../Infrastructure/HOC/Router/WithRouter";
import WithLayout from "../../../../Infrastructure/HOC/Layout/WithLayout";
import {Alert, Breadcrumbs, Button, Link} from "@mui/material";
import TextBox from "../../../../Infrastructure/Component/Shared/TextBox/TextBox";
import ICameraQuality from "../../../../Infrastructure/Http/Camera/ICameraQuality";
import {ICamera} from "../../../../Infrastructure/Http/Camera/ICamera";
import CameraAdapter from "../../Adapter/CameraAdapter";
import CameraCoreService from "../../../../Domain/Camera/CameraCoreFacade";
import {AuthorizedPath} from "../../../../Infrastructure/Route/Authorized/AuthorizedPath";
import {IAbstractLayoutChildrenProps} from "../../../../Infrastructure/HOC/Layout/AbstractLayout";
import "./SingleCameraViewStyle.scss";
import NotFoundException from "../../../../Infrastructure/Exception/Shared/NotFoundException";
interface ICameraParams {
    id: string
}
export interface ISingleCameraViewProps extends IAbstractLayoutChildrenProps, IRouterProps{
    params: ICameraParams
}

export interface ISingleCameraViewState {
    camera?: ICamera;
    exception?: string;
}

class SingleCameraView extends Component<ISingleCameraViewProps, ISingleCameraViewState> {

    private readonly cameraAdapter: CameraAdapter;
    private readonly cameraCoreService: CameraCoreService;
    constructor(props: ISingleCameraViewProps) {
        super(props);
        this.cameraAdapter = new CameraAdapter();
        this.cameraCoreService = new CameraCoreService();

        this.state = {
            camera: {} as ICamera,
            exception: undefined
        }
    }

    private verifyPermissions() {
        if (!this.props.currentUser?.permissions?.canViewCamera) {
            this.props.navigate(AuthorizedPath.HOME);
        }
    }
    private async fetch() {
        this.verifyPermissions();

        await this.cameraAdapter.findById(Number(this.props.params?.id)).then((camera) => {
            this.setState({camera: camera})
        }).catch((err) => {
            if (err instanceof NotFoundException) {
                this.setState({exception: 'Wybrana kamera już nie istnieje w systemie'});
            }
        })
    }

    componentDidMount() {
        this.fetch()
    }

    render() {
        return (
            <div className="single-camera">
               <div className="single-camera_container">
                   <div className="single-camera_container_header">
                       <div className="single-camera_container_header_left">
                           <Breadcrumbs separator="›" aria-label="breadcrumb">
                               <Link underline="hover" key="1" color="inherit" href="/">
                                   Kamery
                               </Link>
                               <Link underline="hover" key="1" color="inherit" href="/">
                                   {this.props.params?.id}
                               </Link>,
                           </Breadcrumbs>
                       </div>
                   </div>

                   <div className="single-camera_container_body">
                       <div className="single-camera_container_body_content">
                           <div className="single-camera_container_body_content_left">
                               {this.state.camera?.user?.id && (
                                   <div className="single-camera_container_body_content_left_user">
                                       <span>Użytkownik</span>
                                       <Link href={AuthorizedPath.USER(this.state.camera?.user?.id?.toString())}>
                                           {this.state.camera?.user?.firstName} {this.state.camera?.user?.lastName}
                                       </Link>
                                   </div>
                               )}



                               {this.state.camera?.id && (
                                   <>
                                       <div className="single-camera_container_body_content_left_header">
                                           <span>Indentyfikacja</span>
                                       </div>

                                       <div className="single-camera_container_body_content_left_input">
                                           <TextBox label="ID Kamery" value={this.state.camera?.id} readOnly/>
                                       </div>
                                   </>
                               )}

                               {this.state.camera?.serialNumber && (
                                   <div className="single-camera_container_body_content_left_input">
                                       <TextBox label="Identyfikator kamery" value={this.state.camera?.serialNumber} readOnly/>
                                   </div>
                               )}
                               {this.state.camera?.user?.userName && (
                                   <div className="single-camera_container_body_content_left_input">
                                       <TextBox label="Kamera przypisana do (ID użytkownika)" value={this.state.camera?.user?.userName} readOnly/>
                                   </div>
                               )}

                               {this.state.exception && (
                                   <Alert severity="error">{this.state.exception}</Alert>
                               )}
                           </div>
                       </div>
                   </div>

                   <div className="single-camera_container_footer">
                   </div>
               </div>
            </div>
        );
    }
}

export default withRouter(WithLayout(SingleCameraView))
