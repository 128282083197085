import React, { Component, SyntheticEvent } from "react";
import { Slider } from "@mui/material";

interface EvidenceEditorProps {
    evidenceMode: boolean;
    timeFrom: string;
    timeTo: string;
    durationInSeconds: number;
    videoRef: HTMLVideoElement | null;
    onTimeRangeChange: (timeFrom: string, timeTo: string) => void;
}

interface EvidenceEditorState {
    sliderValue: [number, number];
    activeThumb: number;
}

export default class EvidenceEditor extends Component<EvidenceEditorProps, EvidenceEditorState> {
    constructor(props: EvidenceEditorProps) {
        super(props);
        this.state = {
            sliderValue: [
                parseFloat(this.props.timeFrom) || 0,
                parseFloat(this.props.timeTo) || this.props.durationInSeconds,
            ],
            activeThumb: 0,
        };
        this.handleSliderChange = this.handleSliderChange.bind(this);
        this.handleSliderChangeCommitted = this.handleSliderChangeCommitted.bind(this);
    }

    componentDidUpdate(prevProps: EvidenceEditorProps) {
        if (
            prevProps.timeFrom !== this.props.timeFrom ||
            prevProps.timeTo !== this.props.timeTo ||
            prevProps.durationInSeconds !== this.props.durationInSeconds
        ) {
            this.setState({
                sliderValue: [
                    parseFloat(this.props.timeFrom) || 0,
                    parseFloat(this.props.timeTo) || this.props.durationInSeconds,
                ],
            });
        }
    }

    handleSliderChange(event: Event | SyntheticEvent<Element, Event>, newValue: number | number[]): void {
        const [newTimeFrom, newTimeTo] = newValue as number[];
        this.setState({ sliderValue: [newTimeFrom, newTimeTo] });
        this.props.onTimeRangeChange(newTimeFrom.toFixed(6), newTimeTo.toFixed(6));

        if (this.props.videoRef) {
            const thumbIndex = this.state.activeThumb;
            if (thumbIndex === 0) {
                this.props.videoRef.currentTime = newTimeFrom;
            } else {
                this.props.videoRef.currentTime = newTimeTo;
            }
        }
    }

    handleSliderChangeCommitted(event: Event | SyntheticEvent<Element, Event>, newValue: number | number[]): void {
        const [newTimeFrom, newTimeTo] = newValue as number[];

        if (this.props.videoRef) {
            const thumbIndex = this.state.activeThumb;
            if (thumbIndex === 0) {
                this.props.videoRef.currentTime = newTimeFrom;
            } else {
                this.props.videoRef.currentTime = newTimeTo;
            }
        }
    }

    handleActiveThumbChange = (event: Event, newValue: number | number[], activeThumb: number) => {
        this.setState({ activeThumb });
        this.handleSliderChange(event, newValue);
    };

    render() {
        const {
            evidenceMode,
            durationInSeconds,
        } = this.props;

        const { sliderValue } = this.state;

        return (
            <>
                {evidenceMode ? (
                    <Slider
                        value={sliderValue}
                        max={durationInSeconds}
                        step={0.000001} // Adjust step to the required precision
                        onChange={(event, value) => this.handleActiveThumbChange(event, value, this.state.activeThumb)}
                        onChangeCommitted={this.handleSliderChangeCommitted}
                        valueLabelDisplay="auto"
                        sx={{ color: '#ff5252', width: '100%', zIndex: 3 }}
                        onMouseDown={(event): void => {
                            const target: HTMLElement = event.target as HTMLElement;
                            const thumbIndex: number = Number(target.getAttribute('data-index'));
                            this.setState({ activeThumb: thumbIndex });
                        }}
                        onMouseUp={(event): void => {
                            const target: HTMLElement = event.target as HTMLElement;
                            const thumbIndex: number = Number(target.getAttribute('data-index'));
                            this.setState({ activeThumb: thumbIndex });
                        }}
                        onMouseOver={(event): void => {
                            const target: HTMLElement = event.target as HTMLElement;
                            const thumbIndex: number = Number(target.getAttribute('data-index'));
                            this.setState({ activeThumb: thumbIndex });
                        }}
                    />
                ) : null}
            </>
        );
    }
}
