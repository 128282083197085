import {IRecordingEvidenceNote} from "../../Http/Recording/IRecordingEvidenceNote";
import RecordingEvidenceNoteRepository from "../../Repository/Recording/RecordingEvidenceNoteRepository";

export default class RecordingEvidenceNoteService {
    public async findById(id: number | undefined): Promise<IRecordingEvidenceNote[]> {
        return await RecordingEvidenceNoteRepository.FindById(id);
    }

    public async persist(id: number | undefined, http: IRecordingEvidenceNote): Promise<IRecordingEvidenceNote> {
        return await RecordingEvidenceNoteRepository.Persist(id, http);
    }

    public async update(evidenceNoteId: number | undefined, http: IRecordingEvidenceNote): Promise<IRecordingEvidenceNote> {
        return await RecordingEvidenceNoteRepository.Update(evidenceNoteId, http);
    }
}
