import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Tooltip} from "@mui/material";
import IUserActivityLog from "../../../Http/User/IUserActivityLog";
import RecordingAdapter from "../../../../Application/Recording/Adapter/RecordingAdapter";
import UserActivityLogAdapter from "../../../../Application/User/Adapter/UserActivityLogAdapter";
import {IRecording} from "../../../Http/Recording/IRecording";
import {calculateDateToDays} from "../../Shared/CalculateDateToDays/CalculateDateToDays";

interface ExpiryDateManagerState {
    newExpiryDate: string;
    error: string;
    isModalOpen: boolean;
}

interface ExpiryDateManagerProps {
    recording: IRecording;
}

export default class ExpiryDateManager extends Component<ExpiryDateManagerProps, ExpiryDateManagerState> {
    private readonly recordingAdapter: RecordingAdapter;
    private readonly userActivityLogAdapter: UserActivityLogAdapter;

    constructor(props: ExpiryDateManagerProps) {
        super(props);
        this.state = {
            newExpiryDate: '',
            error: '',
            isModalOpen: false,
        }
        this.recordingAdapter = new RecordingAdapter();
        this.userActivityLogAdapter = new UserActivityLogAdapter();

        this.handleSave = this.handleSave.bind(this);
        this.handleChangeExpiryDate = this.handleChangeExpiryDate.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    private readonly handleChangeExpiryDate = (event: { target: { value: any; }; }): void => {
        const value = event.target.value;
        this.setState({ newExpiryDate: value });
    };

    private handleSave(): void {
        const { newExpiryDate } = this.state;
        const daysToAdd = Number(newExpiryDate);

        if (daysToAdd < 40 || daysToAdd > 1000) {
            this.setState({ error: 'Wprowadź liczbę od 40 do 1000' });
            return;
        }

        const today: Date = new Date();
        const expiryDate: string = new Date(today.setDate(today.getDate() + daysToAdd)).toISOString();

        const updatedRecording = { ...this.props.recording, expiryDate };

        this.recordingAdapter.update(updatedRecording)
            .then(() => {
                const userActivityLog: IUserActivityLog = {
                    description: "Zmienił okres przechowywania nagrania: " + this.props.recording.title,
                };
                this.userActivityLogAdapter.persist(userActivityLog);

                this.setState({
                    isModalOpen: false,
                    newExpiryDate: '',
                    error: '',
                });
            })
            .catch((err) => {
                console.error("Failed to update recording:", err);
                this.setState({ error: 'Nie udało się zapisać nowej daty' });
            });
    }


    private readonly handleOpenModal = (): void => {
        this.setState({ isModalOpen: true });
    };

    private readonly handleCloseModal = (): void => {
        this.setState({ isModalOpen: false });
    };

    render() {
        return (
            <>
                <div className="single-recording_container_body_content_expiry">
                    <div className="single-recording_container_body_content_expiry_header">
                        <span> Okres przechowywania: {calculateDateToDays(this.props.recording.expiryDate)} dni</span>
                    </div>
                    <div className="single-recording_container_body_content_expiry_divider">
                        <Divider/>
                    </div>
                    <div className="single-recording_container_body_content_expiry_calendar">
                        <Tooltip title="Zmień okres przechowywania" placement="top">
                            <Button
                                onClick={this.handleOpenModal}
                                sx={{
                                    color: '#fff',
                                    marginRight: '8px',
                                    backgroundColor: '#3f51b5',
                                    '&:hover': {
                                        backgroundColor: '#303f9f',
                                    },
                                    border: '1px solid #3f51b5',
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                                    padding: '8px 16px',
                                    fontSize: '14px',
                                }}
                            >
                                Zmień okres przechowywania
                            </Button>
                        </Tooltip>

                        <Dialog open={this.state.isModalOpen} onClose={this.handleCloseModal}>
                            <DialogTitle>Zmień okres przechowywania</DialogTitle>
                            <DialogContent>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="Dni"
                                    type="number"
                                    fullWidth
                                    value={this.state.newExpiryDate}
                                    onChange={this.handleChangeExpiryDate}
                                    helperText={this.state.error}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseModal} color="primary">
                                    Anuluj
                                </Button>
                                <Button onClick={this.handleSave} color="primary">
                                    Zapisz
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            </>
        )
    }
}
