import RecordingNoteFacade from "../../../Domain/Recording/RecordingNoteFacade";
import { IRecordingNote } from "../../../Infrastructure/Http/Recording/IRecordingNote";
import {IRecording} from "../../../Infrastructure/Http/Recording/IRecording";

export default class RecordingNoteAdapter {

    private readonly recordingNoteFacade: RecordingNoteFacade;

    constructor() {
        this.recordingNoteFacade = new RecordingNoteFacade();
    }
    public async findAll(id: number): Promise<IRecordingNote[]> {
        return await this.recordingNoteFacade.findAll(id);
    }

    public async findById(id: number): Promise<IRecording> {
        return await this.recordingNoteFacade.findById(id);
    }

    public async persist(id: number, http: IRecordingNote): Promise<IRecordingNote> {
        return await this.recordingNoteFacade.persist(id, http);
    }

    public async update(id: number, http: IRecordingNote): Promise<IRecordingNote> {
        return await this.recordingNoteFacade.update(id, http);
    }

    public async delete(id: number): Promise<any> {
        return await this.recordingNoteFacade.delete(id);
    }
}