import React, {Component} from 'react';
import "./PageHeaderStyle.scss";
export interface IPageHeaderProps {
  label: string;
  description: string
}

export interface IPageHeaderState {

}

export class PageHeader extends Component<IPageHeaderProps, IPageHeaderState> {
    constructor(props: IPageHeaderProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="page-header">
              <div className="page-header_label"> {this.props.label} </div>
              <div className="page-header_description">{this.props.description}</div>
            </div>
        );
    }
}
