import React, {Component} from 'react';
import {ISideBarItem} from "../Item/SideBarItem";
import {ISettings} from "../../../../Http/Settings/ISettings";
import IUser, {UserRole} from "../../../../Http/User/IUser";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {AuthorizedPath} from "../../../../Route/Authorized/AuthorizedPath";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import {PublicPath} from "../../../../Route/Public/PublicPath";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import {AdminPanelSettings, Assessment} from "@mui/icons-material";
import FolderIcon from '@mui/icons-material/Folder';
import PersonIcon from '@mui/icons-material/Person';
import {NotificationIcon} from "../../Notification/NotificationIcon";

export interface ISidebarMenuFactoryProps {

}

export interface ISidebarMenuFactoryState {

}

export class SidebarMenuFactory  {
   public static CreateMenuItems(user: IUser, settings: ISettings): ISideBarItem[] {
     const items: ISideBarItem[] = [];

     if (settings.showDashboardTab) {
       items.push({icon: <DashboardIcon/>, label: "Pulpit", route: AuthorizedPath.HOME, active: true},)
     }

     if (settings?.showUserTab === true) {
       items.push({icon: <PeopleAltIcon/>, label: "Użytkownicy", route: AuthorizedPath.USERS})
     }

     if (settings?.showRecordingsTab === true) {
       items.push({icon: <FolderIcon/>, label: "Nagrania", route: AuthorizedPath.RECORDINGS})
     }

     items.push({icon: <Assessment/>, label: "Analityka", route: AuthorizedPath.GRAFANA, newTab: true})


     if (user?.id > 0) {
         items.push({icon: <NotificationIcon userId={user.id}/>, label: "Powiadomienia", route: AuthorizedPath.NOTIFICATIONS,})
     }

     if (user.id > 0 && user.roles?.includes(UserRole.ROLE_ADMIN)) {
         items.push({icon: <AdminPanelSettings />, label: "Panel Administratora", route: AuthorizedPath.ADMIN_PANEL, newTab: true});
     }

     return items;
   }

   public static CreateUnderlineMenuItems(currentUser: IUser): ISideBarItem[]  {
     return [
       {icon: <PersonIcon/>, label: "Moje konto", route: AuthorizedPath.MY_USER},
     ]
   }
}
