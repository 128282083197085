import {IPaginator} from "../../Paginator/Paginator";
import {IUserPaginator} from "../../Http/User/IUserPaginator";
import {GetHttpClient} from "../../HttpClient/Factory/HttpClientFactory";
import {IRecordingPaginator} from "../../Http/Recording/IRecordingPaginator";
import {IRecording, IRecordingFilter} from "../../Http/Recording/IRecording";

export default class RecordingRepository {

    public static async FindAll(filters: IRecordingFilter): Promise<IRecordingPaginator> {
        let url: string = `/api/recordings`;

        if (filters) {
            const queryParams: string[] = [];

            if (filters.page) queryParams.push(`page=${filters.page}`);
            if (filters.limit) queryParams.push(`limit=${filters.limit}`);
            if (filters.order) queryParams.push(`order=${filters.order}`);
            if (filters.title) queryParams.push(`title=${filters.title}`);
            if (filters.serialNumber) queryParams.push(`serialNumber=${filters.serialNumber}`);
            if (filters.userId) queryParams.push(`userId=${filters.userId}`);
            if (filters.dateFrom) queryParams.push(`dateFrom=${filters.dateFrom}`);
            if (filters.dateTo) queryParams.push(`dateTo=${filters.dateTo}`);
            if (filters.keyword) queryParams.push(`keyword=${filters.keyword}`);
            if (filters.isEvidence) queryParams.push(`isEvidence=${filters.isEvidence}`);

            if (queryParams.length > 0) {
                url += `?${queryParams.join('&')}`;
            }
        }
        return  GetHttpClient().GET(url).then((response) => {
            return response.data
        })
    }

    public static async FindById(id: number): Promise<IRecording> {
        let url: string = `/api/recordings/${id}`;
        return  GetHttpClient().GET(url).then((response) => {
            return response.data
        })
    }

    public static async Update(recording: IRecording): Promise<void> {
        let url: string = `/api/recordings/${recording.id}`;
        return GetHttpClient().PUT(url, recording).then(res => {
            return res.data
        })
    }
    public static async Delete(id: number): Promise<void> {
        let url: string = `/api/recordings/${id}`;
        return GetHttpClient().DELETE(url).then(res => {
            return res.data
        })
    }
}
