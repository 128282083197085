import React, {Component} from 'react';
import {Box, LinearProgress, Typography} from "@mui/material";
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';
import Battery4BarIcon from '@mui/icons-material/Battery4Bar';
import Battery5BarIcon from '@mui/icons-material/Battery5Bar';
import Battery6BarIcon from '@mui/icons-material/Battery6Bar';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
export interface IBatteryLevelProps {
  batteryLevel: number
}

export interface IBatteryLevelState {

}

export class BatteryLevel extends Component<IBatteryLevelProps, IBatteryLevelState> {
    constructor(props: IBatteryLevelProps) {
        super(props);
        this.state = {};
    }

  private getColor () {
    if (this.props.batteryLevel < 20) return 'error';
    if (this.props.batteryLevel < 30 ||
        this.props.batteryLevel < 50
    ) {
        return  'warning';
    }
    return 'primary';
  }

  private getIcon() {
      if (this.props.batteryLevel < 20) {
          return <Battery1BarIcon color="error" />;
      }
      if (this.props.batteryLevel < 30) {
          return <Battery2BarIcon color="warning" />;
      }
      if (this.props.batteryLevel < 50) {
          return <Battery3BarIcon color="warning" />;
      }
      if (this.props.batteryLevel < 70) {
          return <Battery4BarIcon color="primary" />;
      }
      if (this.props.batteryLevel < 99) {
          return <Battery5BarIcon color="primary" />;
      }
      // For levels 90% and above
      return <BatteryFullIcon color="primary" />;
  }

    render() {
        return (
            <Box display="flex" alignItems="center" gap={2}>
              {this.getIcon()}
              <Box width="100%" mr={1}>
                <Typography variant="body2" color={this.getColor()}>{`${this.props.batteryLevel}%`}</Typography>
                <LinearProgress variant="determinate" value={this.props.batteryLevel} color={this.getColor()} />
              </Box>
            </Box>
        );
    }
}
