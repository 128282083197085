import {GetHttpClient} from "../../HttpClient/Factory/HttpClientFactory";
import {IRecordingEvidenceNote} from "../../Http/Recording/IRecordingEvidenceNote";

export default class RecordingEvidenceNoteRepository {

    public static async FindById(evidenceId: number | undefined): Promise<IRecordingEvidenceNote[]> {
        let url: string = `/api/recordings/evidence/${evidenceId}/note`;
        return  GetHttpClient().GET(url).then((response) => {
            return response.data
        })
    }

    public static async Persist(evidenceId: number | undefined, http: IRecordingEvidenceNote): Promise<IRecordingEvidenceNote> {
        let url: string = `/api/recordings/evidence/${evidenceId}/note`;
        return GetHttpClient().POST(url, http).then((response) => {
            return response.data
        })
    }

    public static async Update(evidenceNoteId: number | undefined, http: IRecordingEvidenceNote): Promise<IRecordingEvidenceNote> {
        let url: string = `/api/recordings/evidence/note/${evidenceNoteId}`;
        return GetHttpClient().PUT(url, http).then((response) => {
            return response.data
        })
    }
}
