import {IUserAuthCredentials} from "../../../Infrastructure/Http/Auth/IUserAuthCredentials";
import AuthFacade from "../../../Domain/Auth/AuthFacade";
import { Router } from 'react-router';
import RouteNavigator from "../../../Infrastructure/Route/Navigator/RouteNavigator";
import AuthorizedRoutes from "../../../Infrastructure/Route/Authorized/AuthorizedRoutes";
import {AuthorizedPath} from "../../../Infrastructure/Route/Authorized/AuthorizedPath";
import UserFacade from "../../../Domain/User/UserFacade";
import {IUserTokenValidity} from "../../../Infrastructure/Http/Auth/IUserTokenValidity";
import InvalidAuthTokenException from "../../../Infrastructure/Exception/Shared/InvalidAuthTokenException";
import Exception from "../../../Infrastructure/Exception/Shared/Exception";
import NotFoundException from "../../../Infrastructure/Exception/Shared/NotFoundException";
import {IPasswordVerify} from "../../../Infrastructure/Http/Auth/IPasswordVerify";

export default class AuthAdapter {

    private readonly authFacade: AuthFacade;
    private readonly userFacade: UserFacade;
    constructor() {
        this.authFacade = new AuthFacade();
        this.userFacade = new UserFacade();
    }


    public async login(credentials: IUserAuthCredentials): Promise<void> {
        return await this.authFacade.login(credentials).then(async (data) => {
            localStorage.setItem('id', data?.id?.toString());
            localStorage.setItem('token', data?.token);

            await this.userFacade.findById(data?.id).then((userData) => {
                localStorage.setItem('firstName', userData?.profile?.firstName);
                localStorage.setItem('lastName', userData?.profile?.lastName);
                localStorage.setItem('userName', userData?.userName);
                localStorage.setItem('roles', JSON.stringify(userData?.roles));
            });
            RouteNavigator.navigate(AuthorizedPath.HOME)
        })
    }

    public async IsValidToken(): Promise<IUserTokenValidity> {
        return await this.authFacade.isValidToken();
    }

    public async logout(withNavigation: boolean = true) {
        this.userFacade.logout(withNavigation);
    }

    public async passwordVerify(password: IPasswordVerify): Promise<number> {
        return await this.authFacade.passwordVerify(password);
    }
}
