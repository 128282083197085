import {REACT_APP_API_URL, REACT_APP_GRAFANA_URL} from "../../../env";

export const AuthorizedPath = {
    HOME: '/home',
    USERS: '/users',
    MY_USER: '/me',
    USER: (id: string) => `/users/${id}`,
    USER_EDIT: (id: string) => `/users/${id}/edit`,
    ADMIN_PANEL: `${REACT_APP_API_URL}/admin`,

    CAMERAS: '/cameras',
    CAMERA: (id: string) => `/cameras/${id}`,

    RECORDINGS: '/recordings',
    RECORDING: (id: string) => `/recordings/${id}`,

    GRAFANA: `${REACT_APP_GRAFANA_URL}`,

    NOTIFICATIONS: '/notifications',
}
