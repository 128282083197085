import React, { Component } from 'react';
import PublicRoutes from "./Public/PublicRoutes";
import AuthorizedRoutes from "./Authorized/AuthorizedRoutes";

export interface IEntryPointProps {}

export interface IEntryPointState {
    isTokenValid: boolean;
}

export default class EntryPoint extends Component<IEntryPointProps, IEntryPointState> {
    constructor(props: IEntryPointProps) {
        super(props);
        this.state = {
            isTokenValid: !!localStorage.getItem('token')
        };
    }

    render() {
        return (
            <div className="dark-theme">
                {this.state.isTokenValid ? <AuthorizedRoutes /> : <PublicRoutes />}
            </div>
        );
    }
}
