import React, { Component } from 'react';
import './SideBarProfileStyle.scss';
import { UserRole } from "../../../../Http/User/IUser";
import { Link } from "react-router-dom";

export interface ISideBarProfile {
    image?: any;
    firstName: string;
    lastName: string;
    userName: string;
    roles: UserRole[];
    redirectRoute: string;
}

export interface ISideBarProfileProps extends ISideBarProfile {}

export interface ISideBarProfileState {}

export default class SideBarProfile extends Component<ISideBarProfileProps, ISideBarProfileState> {
    constructor(props: ISideBarProfileProps) {
        super(props);
        this.state = {};
    }

    private getInitials(firstName: string, lastName: string): string {
        const firstInitial = firstName.charAt(0).toUpperCase();
        const lastInitial = lastName.charAt(0).toUpperCase();
        return `${firstInitial}${lastInitial}`;
    }

    render() {
        const { firstName, lastName, userName, redirectRoute } = this.props;
        const initials = this.getInitials(firstName, lastName);

        return (
            <div className="sidebar-profile">
                <div className="sidebar-profile_container">

                        <div className="sidebar-profile_container_info">
                            <Link to={redirectRoute}>
                                <p className="sidebar-profile_container_info_full_name">
                                    {firstName} {lastName}
                                </p>
                                <p className="sidebar-profile_container_info_username">
                                    {userName}
                                </p>
                            </Link>
                        </div>
                </div>
            </div>
        );
    }
}
