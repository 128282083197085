import React, {Component} from 'react';

import WithLayout from "../../../../Infrastructure/HOC/Layout/WithLayout";
import withRouter, {IRouterProps} from "../../../../Infrastructure/HOC/Router/WithRouter";
import {GridRowsProp} from "@mui/x-data-grid";
import CameraAdapter from "../../../Camera/Adapter/CameraAdapter";
import DiskSpace from "../../../../Infrastructure/Component/Disk/DiskSpace/DiskSpace";
import DashboardAdapter from "../../Adapter/DashboardAdapter";
import IDiskSpaceInfo from "../../../../Infrastructure/Http/Disk/IDiskSpaceInfo";
import {IAbstractLayoutChildrenProps} from "../../../../Infrastructure/HOC/Layout/AbstractLayout";
import {UserRecordingsList} from "../../../../Infrastructure/Component/User/RecordingsList/UserRecordingsList";
import RecordingAdapter from "../../../Recording/Adapter/RecordingAdapter";
import "./HomeViewStyle.scss";
import {PageHeader} from "../../../../Infrastructure/Component/Shared/PageHeader/PageHeader";

export interface IHomeViewProps extends IAbstractLayoutChildrenProps, IRouterProps{
}

export interface IHomeViewState {
    diskSpace?: IDiskSpaceInfo;
    recordingRows: GridRowsProp;
    isRecordingsTableLoading: boolean;
}

class HomeView extends Component<IHomeViewProps, IHomeViewState> {

    private readonly cameraAdapter: CameraAdapter;
    private readonly dashboardAdapter: DashboardAdapter;
    private readonly recordingsAdapter: RecordingAdapter;
    constructor(props: IHomeViewProps) {
        super(props);
        this.cameraAdapter = new CameraAdapter();
        this.dashboardAdapter = new DashboardAdapter();
        this.recordingsAdapter = new RecordingAdapter();
        this.state = {
            recordingRows: [],
            isRecordingsTableLoading: true,
            diskSpace: undefined
        }
    }

    private async fetchDiskSpaceInfo() {
        await this.dashboardAdapter.findSpaceInfo().then(diskInfo => {
            this.setState({diskSpace: diskInfo})
        })
    }

    private async fetchRecordings() {
        await this.recordingsAdapter.findAll({userId: Number(this.props.currentUser?.id)}).then((page) => {
            const rows = page.items?.map((row) => {
                return {
                    id: row.id,
                    title: row.title,
                    url: row.file?.url,
                    userId: row.user?.id,
                    startDate: row.file?.recordedAt,
                    endDate: row.file?.endedAt,
                    isEvidence: row.isEvidence
                }
            });
            this.setState({recordingRows: rows, isRecordingsTableLoading: false});
        }).catch((error) => {
            this.setState({isRecordingsTableLoading: false})
        })
    }

    componentDidMount() {
        this.fetchDiskSpaceInfo().then();
        this.fetchRecordings().then();
    }

    render() {
        return (
            <div className="home">
                <div className="home_container">
                    <div className="home_container_header">
                        <PageHeader
                            label="Pulpit"
                            description="W tej zakładce znajduję się przestrzeń dyskowa oraz Twoje nagrania"
                        />
                        {this.state.diskSpace && (
                            <div className="home_container_header_disk">
                                <DiskSpace usedSpace={this.state.diskSpace?.usedSize} totalSpace={this.state.diskSpace?.totalSize}/>
                            </div>
                        )}
                    </div>

                    <div className="home_container_body">
                        <div className="home_container_body_uploads_table">
                            <UserRecordingsList
                                rows={this.state.recordingRows}
                                isTableLoading={this.state.isRecordingsTableLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default WithLayout(withRouter(HomeView));
