import {GetHttpClient} from "../../HttpClient/Factory/HttpClientFactory";
import {IUserPermissions} from "../../Http/User/IUser";

export default class UserPermissionsRepository {

    public static async FindAll(): Promise<IUserPermissions[]> {
        const url: string = `/api/core/users/permissions`;
        return  GetHttpClient().GET(url).then((response) => {
            return response.data
        })
    }
}