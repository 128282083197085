import {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";

export default class AbstractHTTPClient
{
    /**
     *
     * @private
     */
    protected readonly axiosInstance: AxiosInstance;

    /**
     *
     * @private
     */
    protected readonly axiosConfig: AxiosRequestConfig;

    /**
     *
     * @param axiosInstance
     * @param axiosConfig
     * @protected
     */
    constructor(axiosInstance: AxiosInstance, axiosConfig: AxiosRequestConfig = {}) {
        this.axiosInstance = axiosInstance;
        this.axiosConfig = axiosConfig;
    }

    public getInstance(): AxiosInstance {
        return this.axiosInstance;
    }

    public getAxiosConfig() {
        return this.axiosConfig
    }


    /**
     * @param path
     * @param config
     * @constructor
     */
    public async GET<T> (path: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse>
    {
        return this.axiosInstance.get<T>(path, config);
    }

    /**
     * @param path
     * @param data
     * @constructor
     */
    public async POST<T> (path: string, data: object|string = {}): Promise<AxiosResponse>
    {
        return await this.axiosInstance.post<T>(path, data)
    }

    /**
     * @param path
     * @param data
     * @param onSuccess
     * @param onError
     * @constructor
     */
    public async FileUpload (
        path: string,
        data: any,
        onSuccess: any = function (){},
        onError: any = function (){},
    )
    {
        this.axiosInstance.post(path, data).then((response: AxiosResponse) => {
            this.handleSuccess('POST', response.config.url, response, onSuccess);
        }).catch((reason: any) => {
            this.handleError(reason, onError)
        })
    }

    /**
     * @param path
     * @param data
     * @constructor
     */
    public async PUT<T> (path: string, data: any = null,) {
        return await this.axiosInstance.put(path, data);
    }

    /**
     * @param path
     * @param data
     * @constructor
     */
    public async DELETE<T>(path: string, data: object = {}): Promise<AxiosResponse> {
        return await this.axiosInstance.delete<T>(path, {
            data: {
                ...data,
            }
        });
    }


    public async DOWNLOAD<T>(resourceUrl: string, onProgress: (progress: number) => void) {
        try {
            const response = await this.axiosInstance.get(resourceUrl, {
                responseType: 'blob',
                onDownloadProgress: (progressEvent) => {
                    const total = progressEvent?.total ?? 1;
                    const progress = Math.round((progressEvent.loaded * 100) / total);
                    onProgress(progress);
                }
            });

            const contentDisposition = response.headers['content-disposition'];
            const filenameMatch = contentDisposition && contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
            const filename = filenameMatch && decodeURIComponent(filenameMatch[1].replace(/['"]/g, '')) || 'downloaded_file';

            // Create a Blob from the response data
            const blob = new Blob([response.data], { type: response.headers['content-type'] });

            // Create a URL for the blob
            const url = URL.createObjectURL(blob);

            // Create a hidden anchor element to initiate the download
            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.download = filename;
            downloadLink.style.display = 'none';
            document.body.appendChild(downloadLink);

            // Simulate a click on the anchor element to start the download
            downloadLink.click();

            // Cleanup: remove the anchor element and revoke the blob URL
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    }



    /**
     *
     * @param method
     * @param url
     * @param data
     * @param onSuccess
     * @private
     */
    private handleSuccess(method: string, url: string|undefined, data: AxiosResponse, onSuccess: (data: AxiosResponse) => void) {
        onSuccess(data);
    }

    private handleError(reason: any, onErrorCallback: (reason: any) => void) {
        console.error(`HttpClient::error => ${reason}, status: ${reason?.status}`)
        onErrorCallback(reason);
    }

}
