import {GetHttpClient} from "../../HttpClient/Factory/HttpClientFactory";
import {IUserAuthCredentials} from "../../Http/Auth/IUserAuthCredentials";
import {IUserAuthToken} from "../../Http/Auth/IUserAuthToken";
import {IUserTokenValidity} from "../../Http/Auth/IUserTokenValidity";
import {IPasswordVerify} from "../../Http/Auth/IPasswordVerify";

export default class AuthRepository {


    public static async Login(credentials: IUserAuthCredentials): Promise<IUserAuthToken> {
        return  GetHttpClient().POST('/api/auth/login', credentials).then((response) => {
            return response.data
        })
    }

    public static async IsValidToken(): Promise<IUserTokenValidity> {

        return await GetHttpClient().GET('/api/auth/verify-token').then((response) => {
            return response.data
        })
    }

    public static async PasswordVerify(password: IPasswordVerify): Promise<number> {
        return await GetHttpClient().POST('/api/auth/password-verify', password).then(response => {
            return response.status;
        })
    }
}
