import {IPaginator} from "../../Paginator/Paginator";
import {IUserPaginator} from "../../Http/User/IUserPaginator";
import UserRepository from "../../Repository/User/UserRepository";
import IUser, {IUserFilter} from "../../Http/User/IUser";
import RouteNavigator from "../../Route/Navigator/RouteNavigator";
import {AuthorizedPath} from "../../Route/Authorized/AuthorizedPath";
import PublicRoutes from "../../Route/Public/PublicRoutes";
import {PublicPath} from "../../Route/Public/PublicPath";
import {IUserNotification} from "../../Http/User/HttpUserNotification";

export default class UserStorage {
    public async findAll(page: IPaginator, filters: IUserFilter|undefined = undefined): Promise<IUserPaginator> {
        return await UserRepository.FindAll(page, filters);
    }

    public async findById(id: Number): Promise<IUser> {
        return await UserRepository.FindById(id);
    }

    public async me(): Promise<IUser> {
        return await UserRepository.Me();
    }

    public async update(user: IUser): Promise<IUser> {
        return await UserRepository.Update(user);
    }
    public logout(withNavigation: boolean = true): void {
        localStorage.removeItem('id');
        localStorage.removeItem('token');
        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
        localStorage.removeItem('userName');
        localStorage.removeItem('role');

        if (withNavigation) {
            RouteNavigator.navigate(PublicPath.LOGIN)
        }
    }

    public async notifications(page?: number): Promise<IUserNotification[]> {
        return await UserRepository.Notifications(page);
    }

    public async readNotificationById(id: string): Promise<IUserNotification> {
        return await UserRepository.ReadNotificationById(id);
    }
}
