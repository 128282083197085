import React, {Component} from 'react';
import RouteNavigator from "../../../../Infrastructure/Route/Navigator/RouteNavigator";
import {AuthorizedPath} from "../../../../Infrastructure/Route/Authorized/AuthorizedPath";
import {PublicPath} from "../../../../Infrastructure/Route/Public/PublicPath";

export interface ILogoutViewProps {

}

export interface ILogoutViewState {

}

export default class LogoutView extends Component<ILogoutViewProps, ILogoutViewState> {

    constructor(props: ILogoutViewProps) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        RouteNavigator.navigate(PublicPath.LOGIN)
    }

    render() {
        return (
            <h1>LOGOUT</h1>
        );
    }
}