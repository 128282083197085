import React, {Component} from 'react';
import './DiskSpaceStyle.scss';
import DiskSpaceItem from "./DiskSpaceItem";
export interface IDiskSpaceProps {
    usedSpace:string;
    totalSpace:string;
}

export interface IDiskSpaceState {

}

export default class DiskSpace extends Component<IDiskSpaceProps, IDiskSpaceState> {

    constructor(props: IDiskSpaceProps) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="disk-space">
                <div className="disk-space_container">
                    <div className="disk-space_container_header">
                        <div className="disk-space_container_header_label">
                            Przestrzeń dyskowa
                        </div>
                    </div>
                    <div className="disk-space_container_body">
                        <div className="disk-space_container_body_left">
                            <DiskSpaceItem size={this.props.usedSpace} label={"Zajęte"} type={'used'}/>
                        </div>

                        <div className="disk-space_container_body_right">
                            <DiskSpaceItem size={'30TB'} label={"Całość"} type={'total'}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
