import React, { Component } from 'react';
import './UserAvatarStyle.scss';

const colorMap: { [key: string]: string } = {
    'A': '#FF5733',
    'B': '#33FF57',
    'C': '#3357FF',
    'D': '#F1C40F',
    'E': '#9B59B6',
    'F': '#1ABC9C',
    'G': '#E67E22',
    'H': '#EC7063',
    'I': '#8E44AD',
    'J': '#2ECC71',
    'K': '#3498DB',
    'L': '#F39C12',
    'M': '#D35400',
    'N': '#C0392B',
    'O': '#7F8C8D',
    'P': '#9B59B6',
    'Q': '#1F618D',
    'R': '#AAB7B8',
    'S': '#6C3483',
    'T': '#48C9B0',
    'U': '#F5B041',
    'V': '#5DADE2',
    'W': '#F1948A',
    'X': '#76D7C4',
    'Y': '#F7DC6F',
    'Z': '#C39BD3',
};

export interface IUserAvatarProps {
    firstName: string;
    lastName: string;
    mode?: 'small' | 'large';
}

export interface IUserAvatarState {}

export class UserAvatar extends Component<IUserAvatarProps, IUserAvatarState> {
    constructor(props: IUserAvatarProps) {
        super(props);
        this.state = {};
    }

    getBackgroundColor(initial: string): string {
        const letter = initial.toUpperCase();
        return colorMap[letter] || '#000';
    }

    render() {
        const { firstName, lastName } = this.props;
        const initials: string = `${firstName.charAt(0)}${lastName.charAt(0)}`;
        const backgroundColor = this.getBackgroundColor(initials.charAt(0));

        return (
            <div
                className={`user_avatar ${this.props.mode}`}
                style={{ backgroundColor }}
            >
                {initials.toUpperCase()}
            </div>
        );
    }
}
