export default class Exception {
    private readonly message: string;
    constructor(message: string = "") {
        this.message = message;
    }

    public getMessage(): string {
        return this.message;
    }

    toString() {
        return this.message;
    }
}