import React, {Component} from 'react';
import {MenuItem} from "@mui/material";
import {GridRowsProp} from "@mui/x-data-grid/models/gridRows";

export interface IGridTxtExportMenuItemProps {
    rows: GridRowsProp;
}

export interface IGridTxtExportMenuItemState {}

export class GridTxtExportMenuItem extends Component<IGridTxtExportMenuItemProps, IGridTxtExportMenuItemState> {
    constructor(props: IGridTxtExportMenuItemProps) {
        super(props);
        this.onExport = this.onExport.bind(this);
    }

    private onExport() {
        const { rows } = this.props;

        if (!rows || rows.length === 0) {
            alert("Brak dostępnych danych do eksportu");
            return;
        }

        const currentDateTime = new Date().toISOString().replace(/:/g, '-').replace(/T/g, '_').split('.')[0];
        const fileName = `${currentDateTime}.txt`;
        const maxKeyLength = Math.max(...rows.map((row) =>
            Object.keys(row).reduce((max, key) => Math.max(max, key.length), 0))
        );
        const headers = Object.keys(rows[0])
            .map((key) => key.padEnd(maxKeyLength))
            .join('\t');

        const txtContent = rows.map((row) => {
            return Object.keys(row)
                .map((key) => `${row[key]?.toString().padEnd(maxKeyLength)}`)
                .join('\t');
        }).join('\n');

        const blob = new Blob([headers, '\n', txtContent], { type: 'text/plain' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    }

    render() {
        return (
            <MenuItem onClick={this.onExport}>
                Pobierz jako plik TXT
            </MenuItem>
        );
    }
}
