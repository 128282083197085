import React, { Component } from "react";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import DownloadProgressPopup from "../DownloadProgressPopup/DownloadProgressPopup";
import { IRecording } from "../../../Http/Recording/IRecording";
import IUserActivityLog from "../../../Http/User/IUserActivityLog";
import { GetHttpClient } from "../../../HttpClient/Factory/HttpClientFactory";
import UserActivityLogAdapter from "../../../../Application/User/Adapter/UserActivityLogAdapter";

interface DownloadButtonProps {
    recording: IRecording;
}

interface DownloadButtonState {
    downloadProgress: number;
    isDownloadDialogOpen: boolean;
}

export default class DownloadButton extends Component<DownloadButtonProps, DownloadButtonState> {
    private readonly userActivityLogAdapter: UserActivityLogAdapter;

    constructor(props: DownloadButtonProps) {
        super(props);
        this.state = {
            downloadProgress: 0,
            isDownloadDialogOpen: false,
        };
        this.userActivityLogAdapter = new UserActivityLogAdapter();
        this.onDownload = this.onDownload.bind(this);
    }

    private onDownload(): void {
        this.setState({ isDownloadDialogOpen: true });
        const userActivityLog: IUserActivityLog = {
            description: "Podjął próbę pobrania pliku o nazwie " + this.props.recording.title,
        };
        this.userActivityLogAdapter.persist(userActivityLog);
        const url = this.props.recording?.file?.url ?? '';
        if (url === '') {
            alert('Plik nagrania nie istnieje');
            return; // Exit function if no URL
        }
        GetHttpClient()
            .DOWNLOAD(url, (progress) => {
                this.setState({ downloadProgress: progress });
                if (progress >= 100) {
                    this.setState({ isDownloadDialogOpen: false });
                    const userActivityLog: IUserActivityLog = {
                        description: "Pobrał plik o nazwie " + this.props.recording.title,
                    };
                    this.userActivityLogAdapter.persist(userActivityLog);
                }
            })
            .catch((err) => {
                console.error("Download failed:", err);
                const userActivityLog: IUserActivityLog = {
                    description: "Pobieranie nagrania " + this.props.recording.title + " nie powiodło się",
                };
                this.userActivityLogAdapter.persist(userActivityLog);
                this.setState({ isDownloadDialogOpen: false });
            });
    }

    render() {
        return (
            <div className="single-recording_container_body_content_buttons">
                <div className="single-recording_container_body_content_buttons_left">
                    <Button variant="contained" startIcon={<DownloadIcon />} onClick={this.onDownload}>
                        Pobierz
                    </Button>
                </div>
                <DownloadProgressPopup
                    downloadProgress={this.state.downloadProgress}
                    isDownloadDialogOpen={this.state.isDownloadDialogOpen}
                />
            </div>
        );
    }
}
