import React, { Component } from 'react';
import withRouter, { IRouterProps } from "../../../../Infrastructure/HOC/Router/WithRouter";
import WithLayout from "../../../../Infrastructure/HOC/Layout/WithLayout";
import { GridColDef, GridRenderCellParams, GridRowsProp } from "@mui/x-data-grid";
import { Button, IconButton, Link, Menu, MenuItem } from "@mui/material";
import { AuthorizedPath } from "../../../../Infrastructure/Route/Authorized/AuthorizedPath";
import Table from "../../../../Infrastructure/Component/Shared/Table/Table";
import { RecordingsTableFilter } from "../../../../Infrastructure/Component/Recording/Filter/RecordingsTableFilter";
import DownloadIcon from '@mui/icons-material/Download';
import "./RecordingsListViewStyle.scss";
import { RecordingTableThumbnail } from "../../../../Infrastructure/Component/Recording/TableThumbnail/RecordingTableThumbnail";
import RouteNavigator from "../../../../Infrastructure/Route/Navigator/RouteNavigator";
import RecordingAdapter from "../../Adapter/RecordingAdapter";
import Exception from "../../../../Infrastructure/Exception/Shared/Exception";
import InvalidAuthTokenException from "../../../../Infrastructure/Exception/Shared/InvalidAuthTokenException";
import UserAdapter from "../../../User/Adapter/UserAdapter";
import { IAbstractLayoutChildrenProps } from "../../../../Infrastructure/HOC/Layout/AbstractLayout";
import { IRecordingFilter } from "../../../../Infrastructure/Http/Recording/IRecording";
import { orange, red, yellow } from "@mui/material/colors";
import UserActivityLogAdapter from "../../../User/Adapter/UserActivityLogAdapter";
import IUserActivityLog from "../../../../Infrastructure/Http/User/IUserActivityLog";
import { MoreVert } from "@mui/icons-material";
import {PageHeader} from "../../../../Infrastructure/Component/Shared/PageHeader/PageHeader";

export interface IRecordingsListViewProps extends IAbstractLayoutChildrenProps, IRouterProps { }

export interface IRecordingsListViewState {
    columns: GridColDef[];
    rows: GridRowsProp;
    filters: IRecordingFilter;
    isRecordingsTableLoading: boolean;
    anchorEl: null | HTMLElement;
}

class RecordingsListView extends Component<IRecordingsListViewProps, IRecordingsListViewState> {
    private readonly userAdapter: UserAdapter;
    private readonly recordingAdapter: RecordingAdapter;
    private readonly userActivityLogAdapter: UserActivityLogAdapter;

    constructor(props: IRecordingsListViewProps) {
        super(props);
        this.userAdapter = new UserAdapter();
        this.recordingAdapter = new RecordingAdapter();
        this.userActivityLogAdapter = new UserActivityLogAdapter();
        this.onFilter = this.onFilter.bind(this);
        this.generateReport = this.generateReport.bind(this);
        this.generateEndingReport = this.generateEndingReport.bind(this);
        this.handleMenuOpen = this.handleMenuOpen.bind(this);
        this.handleMenuClose = this.handleMenuClose.bind(this);
        this.state = {
            rows: [],
            columns: [
                {
                    field: 'title', headerName: "Nagranie", width: 120, type: 'string', editable: false, renderCell: (params) => {
                        return <RecordingTableThumbnail
                            id={params.row?.id}
                            title={params.row?.title}
                            sourceUrl={params?.row?.url}
                        />;
                    }
                },
                { field: 'user', headerName: 'Użytkownik', width: 200, editable: false, flex: 1 },
                { field: 'camera', headerName: 'Kamera', width: 200, editable: false, flex: 1, renderCell: (params) => {
                        return (
                            <div className="flex justify-between items-center gap-2">
                                <Link href={AuthorizedPath.CAMERA(params?.row?.camera?.id)}>{`${params?.row?.camera?.serialNumber}`}</Link>
                            </div>
                        );
                    }
                },
                { field: 'startDate', headerName: 'Początek', width: 200, type: "string", editable: false, flex: 1 },
                { field: 'endDate', headerName: 'Koniec', width: 200, type: "string", editable: false, flex: 1 },
                { field: 'expiryDate', headerName: 'Data Wygaśnięcia', width: 200, type: "string", editable: false, flex: 1, renderCell: (params: GridRenderCellParams) => this.renderExpiryDate(params) },
                {
                    field: 'isEvidence', headerName: 'Dowód', width: 200, type: "boolean", editable: false, flex: 1, renderCell: (params) => {
                        return <span color="#fff">{params?.row?.isEvidence === true ? "Tak" : "Nie"}</span>;
                    }
                },
                {
                    field: 'action', headerName: "Akcja", width: 200, type: 'string', editable: false, flex: 1, renderCell: (params) => {
                        return (
                            <div className="flex justify-between items-center gap-2">
                                <Link href={AuthorizedPath.RECORDING(params?.row?.id)}>Wyświetl</Link>
                            </div>
                        );
                    }
                }
            ],
            filters: {
                page: 1,
                limit: 50,
                order: "DESC"
            },
            anchorEl: null,
            isRecordingsTableLoading: true
        };
    }

    private verifyPermissions() {
        if (!this.props.currentUser?.permissions?.canViewRecording) {
            this.props.navigate(AuthorizedPath.HOME);
        }
    }

    private async fetch() {
        this.verifyPermissions();
        await this.recordingAdapter.findAll(this.state.filters).then((page) => {
            const rows: GridRowsProp = page.items?.map((row) => {
                return {
                    id: row.id,
                    title: row.title,
                    url: row.file?.url,
                    user: `${row.user?.firstName} ${row.user?.lastName}`,
                    camera: row.camera,
                    startDate: row.file?.recordedAt,
                    endDate: row.file?.endedAt,
                    expiryDate: row.expiryDate,
                    isEvidence: row.isEvidence
                };
            });

            this.setState({ rows: rows, isRecordingsTableLoading: false });
        }).catch((exception) => {
            this.setState({ isRecordingsTableLoading: false });
            this.handleException(exception);
        });
    }

    private onFilter(filters: IRecordingFilter) {
        this.setState({ filters: filters, isRecordingsTableLoading: true }, async () => {
            await this.fetch();
        });
    }

    private handleException(exception: Exception) {
        if (exception instanceof InvalidAuthTokenException) {
            this.userAdapter.logout();
        }
    }

    private calculateDaysLeft(expiryDate: string): { days: number; color: string } {
        const today = new Date();
        const expiry = new Date(expiryDate);
        const timeDiff = expiry.getTime() - today.getTime();
        const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));

        let color = 'black';
        if (daysLeft <= 3) {
            color = red[500];
        } else if (daysLeft <= 7) {
            color = orange[500];
        } else if (daysLeft <= 14) {
            color = yellow[700];
        } else {
            color = 'white';
        }

        return { days: daysLeft, color: color };
    }

    private renderExpiryDate(params: GridRenderCellParams): JSX.Element {
        const { days, color } = this.calculateDaysLeft(params.row.expiryDate);
        return <span style={{ color }}>{params.row.expiryDate} ({days} dni)</span>;
    }

    private generateEndingReport() {
        const { rows } = this.state;
        let txtContent = "";
        txtContent += "Raport o kończącym się okresie przechowywania nagrań\n";
        txtContent += "--------------------------------------------------------------------------------------\n";
        txtContent += "Nazwa nagrania | Data Wygaśnięcia | Dni do wygaśnięcia | Dowód\n";
        txtContent += "--------------------------------------------------------------------------------------\n";

        rows.forEach(row => {
            const { days, color } = this.calculateDaysLeft(row.expiryDate);

            if (days <= 7) {
                txtContent += `${row.title} | ${row.expiryDate} | ${days} | ${row.isEvidence ? "Tak" : "Nie"}\n`;
            }
        });

        const blob = new Blob([txtContent], { type: "text/plain;charset=utf-8;" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "raport_o_kończącym_się_okresie_przechowywania.txt");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        const userActivityLog: IUserActivityLog = {
            description: "Pobrał raport o kończącym się okresie przechowywania nagrań"
        };
        this.userActivityLogAdapter.persist(userActivityLog);
    }

    private generateReport() {
        const { rows } = this.state;
        let txtContent = "";
        txtContent += "Raport nagrań\n";
        txtContent += "--------------------------------------------------------------------------------------\n";
        txtContent += "Nazwa nagrania | Data Wygaśnięcia | Dni do wygaśnięcia | Dowód\n";
        txtContent += "--------------------------------------------------------------------------------------\n";

        rows.forEach(row => {
            const { days, color } = this.calculateDaysLeft(row.expiryDate);
            txtContent += `${row.title} | ${row.expiryDate} | ${days} | ${row.isEvidence ? "Tak" : "Nie"}\n`;
        });

        const blob = new Blob([txtContent], { type: "text/plain;charset=utf-8;" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "raport_nagran.txt");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        const userActivityLog: IUserActivityLog = {
            description: "Pobrał raport nagrań"
        };
        this.userActivityLogAdapter.persist(userActivityLog);
    }

    handleMenuOpen(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleMenuClose() {
        this.setState({ anchorEl: null });
    }

    componentDidMount() {
        this.fetch();
    }

    render() {
        return (
            <div className="recordings">
                <div className="recordings_container">
                    <div className="recordings_container_header">
                        <PageHeader
                            label="Nagrania"
                            description="W tej zakładce znajdują się nagrania z całego systemu"
                        />
                        <div className="report-button-container">
                            <div className="report-label">Raporty</div>
                            <IconButton
                                aria-controls="report-menu"
                                aria-haspopup="true"
                                onClick={this.handleMenuOpen}
                                sx={{
                                    color: 'var(--primary)',
                                    borderRadius: 2,
                                    padding: '8px',
                                    backgroundColor: 'var(--table-filters-background)',
                                    transition: 'background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: 'var(--table-row-active-background)',
                                        transform: 'scale(1.02)',
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                                    },
                                    '&:active': {
                                        transform: 'scale(0.95)',
                                    },
                                }}
                            >
                                <MoreVert />
                            </IconButton>
                        </div>
                        <Menu
                            id="report-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.handleMenuClose}
                        >
                            <MenuItem onClick={() => { this.generateEndingReport(); this.handleMenuClose(); }}>
                                Pobierz raport o kończącym się okresie przechowywania
                            </MenuItem>
                            <MenuItem onClick={() => { this.generateReport(); this.handleMenuClose(); }}>
                                Pobierz raport nagrań
                            </MenuItem>
                        </Menu>
                    </div>

                    <div className="recordings_container_body">
                        <div className="recordings_container_body_filters w-full">
                            <RecordingsTableFilter
                                onFilter={this.onFilter}
                            />
                        </div>

                        <div className="recordings_container_body_table" id="recordings-table">
                            <Table
                                columns={this.state.columns}
                                rows={this.state.rows}
                                checkboxSelection={false}
                                disableRowSelectionOnClick={true}
                                loading={this.state.isRecordingsTableLoading}
                            />
                        </div>
                    </div>
                    <div className="recordings_container_footer">
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(WithLayout(RecordingsListView));
