import AbstractHTTPClient from "./AbstractHTTPClient";
import axios, {AxiosInstance, AxiosRequestConfig} from "axios";

export default class HTTPClient extends AbstractHTTPClient
{

    constructor(params: AxiosRequestConfig = {}) {
        const axiosInstance = axios.create(params);
        super(axiosInstance, params);
    }

    public setHeader(key: string, value: string): HTTPClient {
        this.axiosInstance.defaults.headers.common[key] =  value
        return this;
    }
    public getHeaders() {
        return this.axiosInstance.defaults.headers;
    }

    public removeHeader(key: string): HTTPClient {
        delete this.axiosInstance.defaults.headers.common[key];
        return this;
    }
}