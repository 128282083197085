import React, { Component } from 'react';
import SideBarItem, { ISideBarItem } from "./Item/SideBarItem";
import SideBarProfile, { ISideBarProfile } from "./Profile/SideBarProfile";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { PublicPath } from "../../../Route/Public/PublicPath";
import { Button } from "@mui/material";
import UserAdapter from "../../../../Application/User/Adapter/UserAdapter";
import './SidebarStyle.scss';


export interface ISideBarProps {
    profile: ISideBarProfile;
    items: ISideBarItem[];
    underLinedItems?: ISideBarItem[];
    onLogout: () => void;
    userId: number;
}

export interface ISideBarState {
}

export default class SideBar extends Component<ISideBarProps, ISideBarState> {
    private readonly userAdapter: UserAdapter;
    constructor(props: ISideBarProps) {
        super(props);
        this.state = {
        };
        this.handleLogout = this.handleLogout.bind(this);

        this.userAdapter = new UserAdapter();
    }

    private handleLogout(): void {
        this.props.onLogout();
        window.location.href = PublicPath.LOGIN;
    };


    render() {


        return (
            <div className='sidebar'>
                <div className="sidebar_header">
                    <h2>KTAVisor</h2>
                </div>

                <div className="sidebar_container">
                    <ul className="sidebar_container_items">
                        {this.props.items.map((itemProps, idx) => (
                            <SideBarItem {...itemProps} key={idx} />
                        ))}
                    </ul>

                    <hr />

                    <ul className="sidebar_container_items_underline">
                        {this.props.underLinedItems && this.props.underLinedItems.map((itemProps, idx) => (
                            <SideBarItem {...itemProps} key={idx} />
                        ))}
                    </ul>
                </div>

                <div className="sidebar_profile">
                    <SideBarProfile
                        {...this.props.profile}
                    />
                    <Button
                        onClick={this.handleLogout}
                        className="logout_button"
                    >
                        <LogoutOutlinedIcon color="primary" />
                    </Button>
                </div>
            </div>
        );
    }
}
