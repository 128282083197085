import SettingsFacade from "../../Domain/Settings/SettingsFacade";
import {ISettings} from "../../Infrastructure/Http/Settings/ISettings";

export default class SettingsAdapter {
    private readonly settingsFacade: SettingsFacade;

    constructor() {
        this.settingsFacade = new SettingsFacade();
    }

    public async findSettings(): Promise<ISettings> {
        return await this.settingsFacade.findSettings();
    }
}

