import ICameraResolution from "../../Infrastructure/Http/Camera/ICameraResolution";
import ICameraQuality from "../../Infrastructure/Http/Camera/ICameraQuality";
import CameraCoreRepository from "../../Infrastructure/Repository/Camera/CameraCoreRepository";

export default class CameraCoreService {


    public constructor() {
    }
    public async findAllResolutions(): Promise<ICameraResolution[]> {
        return await CameraCoreRepository.FindAllResolutions();
    }

    public async findAllQualities(): Promise<ICameraQuality[]> {
        return CameraCoreRepository.FindAllQualities();
    }
}