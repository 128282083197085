import React, { Component, RefObject } from 'react';
import { Box } from '@mui/material';
import { IRecordingFile } from '../../../Http/Recording/IRecording';
import IUserActivityLog from "../../../Http/User/IUserActivityLog";
import UserActivityLogAdapter from "../../../../Application/User/Adapter/UserActivityLogAdapter";

export interface IRecordingPlayerProps {
    recordingId: number;
    file: IRecordingFile;
    videoRef: RefObject<HTMLVideoElement>;
    title: string;
}

class RecordingPlayer extends Component<IRecordingPlayerProps> {
    private readonly userActivityLogAdapter: UserActivityLogAdapter;
    constructor(props: IRecordingPlayerProps) {
        super(props);
        this.userActivityLogAdapter = new UserActivityLogAdapter();
        this.handleContextMenu = this.handleContextMenu.bind(this);
        this.handlePlayEvent = this.handlePlayEvent.bind(this);
    }

    componentDidMount() {
        if (this.props.videoRef && this.props.videoRef.current) {
            this.props.videoRef.current.addEventListener('contextmenu', this.handleContextMenu);
            this.props.videoRef.current.addEventListener('play', this.handlePlayEvent);
        }
    }

    componentWillUnmount() {
        if (this.props.videoRef && this.props.videoRef.current) {
            this.props.videoRef.current.removeEventListener('contextmenu', this.handleContextMenu);
            this.props.videoRef.current.removeEventListener('play', this.handlePlayEvent);
        }
    }

    private handleContextMenu(event: MouseEvent): void {
        event.preventDefault();
    }

    private handlePlayEvent(): void {
        const userActivityLog: IUserActivityLog = {
            description: "Rozpoczął oglądanie wideo: " + this.props.title,
        };
        this.userActivityLogAdapter.persist(userActivityLog);
    }

    render() {
        const { file, videoRef } = this.props;

        return (
            <Box display="flex" color="#fff">
                <Box flexGrow={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Box position="relative" width="99%">
                        <video
                            ref={videoRef}
                            controls
                            controlsList="nodownload noremoteplayback"
                            disablePictureInPicture
                            src={file?.url || ''}
                            preload="metadata"
                            style={{ width: '100%', borderRadius: '8px', boxShadow: '0 4px 2px rgba(0, 0, 0, 0.2)' }}
                        />
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default RecordingPlayer;
