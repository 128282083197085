import {IUserAuthCredentials} from "../../Http/Auth/IUserAuthCredentials";
import {IUserAuthToken} from "../../Http/Auth/IUserAuthToken";
import {GetHttpClient} from "../../HttpClient/Factory/HttpClientFactory";
import IPageIterator, {IPaginator} from "../../Paginator/Paginator";
import {IUserPaginator} from "../../Http/User/IUserPaginator";
import IUser, {IUserFilter} from "../../Http/User/IUser";
import {IUserNotification} from "../../Http/User/HttpUserNotification";

export default class UserRepository {

    public static async FindAll(page: IPaginator, filters: IUserFilter|undefined = undefined): Promise<IUserPaginator> {
        let url: string = `/api/users`;

        if (filters) {
            const queryParams: string[] = [];

            if (page.page) queryParams.push(`page=${page.page}`);
            if (page.limit) queryParams.push(`limit=${page.limit}`);
            if (filters.permissionId) queryParams.push(`permissionId=${filters.permissionId}`);
            if (filters.hasRfid) queryParams.push(`hasRfid=${filters.hasRfid}`);

            if (queryParams.length > 0) {
                url += `?${queryParams.join('&')}`;
            }
        }
        return  GetHttpClient().GET(url).then((response) => {
            return response.data
        })
    }

    public static async FindById(id: Number): Promise<IUser> {
        const url: string = `/api/users/${id}`;
        return  GetHttpClient().GET(url).then((response) => {
            return response.data
        })
    }
    public static async Me(): Promise<IUser> {
        const url: string = `/api/users/me`;
        return  GetHttpClient().GET(url).then((response) => {
            return response.data
        })
    }

    public static async Update(user: IUser): Promise<IUser> {
        const url: string = `/api/users/me`;
        return  GetHttpClient().PUT(url, user).then((response) => {
            return response.data
        })
    }

    public static async Notifications(page?: number): Promise<IUserNotification[]> {
        let url: string = '/api/users/notifications'
        const queryParams: string[] = [];

        if (page) queryParams.push(`page=${page}`);

        if (queryParams.length > 0) {
            url += `?${queryParams.join('&')}`;
        }

        return GetHttpClient().GET(url).then((response) => {
            return response.data;
        })
    }

    public static async ReadNotificationById(id: string): Promise<IUserNotification> {
        let url: string = `/api/users/notifications/${id}`

        return GetHttpClient().GET(url).then((response) => {
            return response.data;
        })
    }
}
