import React, {Component} from 'react';
import "./RecordingNoteInputStyle.scss";
import {Button} from "@mui/material";
export interface IRecordingNoteInputProps {
    onAdd: (e: string) => void;
}

export interface IRecordingNoteInputState {
    text: string
}

export default class RecordingNoteInput extends Component<IRecordingNoteInputProps, IRecordingNoteInputState> {

    constructor(props: IRecordingNoteInputProps) {
        super(props);
        this.onTextAreaChanged = this.onTextAreaChanged.bind(this);
        this.state = {
            text: ""
        }
    }

    private onTextAreaChanged(e: string) {
        this.setState({ text:  e});
    }

    render() {
        return (
            <div className="recording-note-input">
                <div className="recording-note-input_container">
                    <div className="recording-note-input_container_header">
                        <span>Dodaj notatkę</span>
                    </div>
                    <div className="recording-note-input_container_body">
                         <textarea
                             value={this.state.text}
                             onChange={(e) => this.onTextAreaChanged(e.target?.value)}
                         />

                    </div>
                    <div className="recording-note-input_container_footer">
                        <span className="recording-note-input_container_footer_add" onClick={() => this.props.onAdd(this.state.text)}>Zapisz</span>
                    </div>
                </div>
            </div>
        );
    }
}