import IUserActivityLog, {IUserActivityLogFilter, IUserActivityLogPaginator} from "../../Http/User/IUserActivityLog";
import {GetHttpClient} from "../../HttpClient/Factory/HttpClientFactory";
import {IPaginator} from "../../Paginator/Paginator";

export default class UserActivityLogRepository {

    public static async FindAll(page: IPaginator, filters: IUserActivityLogFilter): Promise<IUserActivityLogPaginator> {
        let url: string = `/api/users/activity-log`;

        if (filters) {
            const queryParams: string[] = [];

            if (page.page) queryParams.push(`page=${page.page}`);
            if (page.limit) queryParams.push(`limit=${page.limit}`);
            if (filters.userId) queryParams.push(`userId=${filters.userId}`);

            if (queryParams.length > 0) {
                url += `?${queryParams.join('&')}`;
            }
        }
        return  GetHttpClient().GET(url).then((response) => {
            return response.data
        })
    }

    public static async Persist(http: IUserActivityLog): Promise<void> {
        let url: string = `/api/users/activity-log`;

        return GetHttpClient().POST(url, http).then();
    }
}
