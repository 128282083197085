import React, {Component, createRef, RefObject} from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import EvidenceEditor from "../Player/Evidence/EvidenceEditor";
import EvidenceList from "../Player/Evidence/EvidenceList";
import {IRecording, IRecordingEvidence, IRecordingFile} from "../../../Http/Recording/IRecording";
import EvidenceDialog from "../Player/Evidence/EvidenceDialog";
import {IRecordingEvidenceNote} from "../../../Http/Recording/IRecordingEvidenceNote";
import RecordingEvidenceAdapter from "../../../../Application/Recording/Adapter/RecordingEvidenceAdapter";
import RecordingEvidenceNoteAdapter from "../../../../Application/Recording/Adapter/RecordingEvidenceNoteAdapter";
import {formatTime} from "../../Shared/TimeFormat/formatTime";
import {calculateDateToDays} from "../../Shared/CalculateDateToDays/CalculateDateToDays";

interface EvidenceCreatorProps {
    recording: IRecording;
    currentUserId: number;
    videoRef: HTMLVideoElement | null;
}

interface EvidenceCreatorState {
    error: string;
    isEvidenceMode: boolean;
    evidenceStartTime: string;
    evidenceEndTime: string;
    evidenceTitle: string;
    expiredAt: string;
    indelible: boolean;
    editMode: boolean;
    isDialogOpen: boolean;
    isModalOpen: boolean;
    evidenceToEdit: number | undefined;
    evidenceToDelete: number | null | undefined;
    selectedEvidence: IRecordingEvidence;
    isEvidenceDeleteDialogOpen: boolean;
    evidenceNotes: IRecordingEvidenceNote[];
}

class EvidenceCreator extends Component<EvidenceCreatorProps, EvidenceCreatorState> {
    private recordingEvidenceAdapter: RecordingEvidenceAdapter;
    private recordingEvidenceNoteAdapter: RecordingEvidenceNoteAdapter;

    constructor(props: EvidenceCreatorProps) {
        super(props);
        this.state = {
            error: '',
            isEvidenceMode: false,
            evidenceStartTime: '0',
            evidenceEndTime: '0',
            selectedEvidence: {} as IRecordingEvidence,
            evidenceToDelete: null,
            evidenceToEdit: 0,
            evidenceTitle: '',
            expiredAt: '',
            indelible: false,
            editMode: false,
            isModalOpen: false,
            isDialogOpen: false,
            isEvidenceDeleteDialogOpen: false,
            evidenceNotes: [],
        };
        this.recordingEvidenceAdapter = new RecordingEvidenceAdapter();
        this.recordingEvidenceNoteAdapter = new RecordingEvidenceNoteAdapter();

        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.handleEvidenceClick = this.handleEvidenceClick.bind(this);
        this.handleEvidenceEditClick = this.handleEvidenceEditClick.bind(this);
        this.handleEvidenceDeleteClick = this.handleEvidenceDeleteClick.bind(this);
    }

    toggleEvidenceMode = () => {
        this.setState((prevState) => ({
            isEvidenceMode: !prevState.isEvidenceMode,
        }));
    };

    private async fetchEvidenceNotes(evidenceId: number | undefined): Promise<void> {
        const evidenceNotes = await this.recordingEvidenceNoteAdapter.findById(evidenceId);
        this.setState({ evidenceNotes: evidenceNotes });
    }

    private openDialog(): void {
        this.setState((prevState) => {
            return {
                ...prevState,
                isDialogOpen: true,
            }
        });
    }

    private closeDialog(): void {
        this.setState((prevState) => {
            return {
                ...prevState,
                isDialogOpen: false,
            }
        });
    }

    handleTimeRangeChange = (start: string, end: string) => {
        this.setState({
            evidenceStartTime: start,
            evidenceEndTime: end,
        });
    };

    private async handleEvidenceDeleteClick(evidenceId: number | undefined): Promise<void> {
        this.setState({ evidenceToDelete: evidenceId, isEvidenceDeleteDialogOpen: true });
        await this.recordingEvidenceAdapter.delete(this.props.recording.id, evidenceId);
        window.location.reload()
    }

    private async handleEvidenceEditClick(evidenceId: number | undefined): Promise<void> {
        this.setState({ editMode: true })
        const evidenceToEdit: IRecordingEvidence | undefined = this.props.recording.evidences.find(
            evidence => evidence.id === evidenceId
        );

        if (evidenceToEdit) {
            this.setState({ isDialogOpen: true })

            evidenceToEdit.expiryAt = calculateDateToDays(evidenceToEdit.expiryAt);

            this.setState({ evidenceToEdit: evidenceId, selectedEvidence: evidenceToEdit });
            this.fetchEvidenceNotes(evidenceId);
        } else {
            console.error(`Evidence with ID ${evidenceId} not found`);
        }
    }

    private async handleEvidenceDeleteConfirm(password: string): Promise<void> {
        if (this.state.evidenceToDelete !== null) {
            try {
                await this.recordingEvidenceAdapter.delete(this.props.recording.id, this.state.evidenceToDelete);
            } catch (error) {
                console.error('Failed to delete evidence:', error);
            }
        }
        this.setState({ isEvidenceDeleteDialogOpen: false, evidenceToDelete: null });
    }

    private handleEvidenceDeleteCancel(): void {
        this.setState({ isEvidenceDeleteDialogOpen: false, evidenceToDelete: null });
    }

    private async updateEvidence(): Promise<void> {
        const { selectedEvidence, evidenceStartTime, evidenceEndTime } = this.state;

        let expiryDate: string | null = '';
        if (selectedEvidence?.expiryAt !== '' || selectedEvidence?.expiryAt !== null) {
            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + Number(selectedEvidence?.expiryAt));
            expiryDate = currentDate.toISOString().split('T')[0];
        } else {
            expiryDate = null;
        }

        if (!selectedEvidence?.indelible) {
            const expiryDateNumber = Number(selectedEvidence?.expiryAt);
            console.log(expiryDateNumber)

            if (expiryDateNumber < 40 || expiryDateNumber > 1000) {
                this.setState({ error: 'Wprowadź liczbę od 40 do 1000' });
                return;
            } else {
                this.setState({ error: '' })
            }
        }
        const newEvidence: IRecordingEvidence = {
            id: selectedEvidence?.id,
            title: selectedEvidence?.title || '',
            timeFrom: selectedEvidence?.timeFrom || '',
            timeTo: selectedEvidence?.timeTo || '',
            userId: this.props.currentUserId,
            indelible: selectedEvidence?.indelible || undefined,
            expiryAt: expiryDate,
        };

        try {
            await this.recordingEvidenceAdapter.update(Number(this.props.recording.id), selectedEvidence?.id, newEvidence);

            this.setState({
                evidenceTitle: '',
                isEvidenceMode: false,
                expiredAt: "",
                editMode: false,
                error: '',
            });
        } catch (error) {
            console.error('Error updating evidence:', error);
        }
    }

    private handleEvidenceClick(timeFrom: string): void {
        const newTime: number = parseInt(timeFrom, 10);
        if (this.props.videoRef) {
            this.props.videoRef.currentTime = newTime;
            this.props.videoRef.pause();
        }
    }

    render() {
        const { isEvidenceMode, evidenceStartTime, evidenceEndTime, editMode } = this.state;
        const { recording } = this.props;

        return (
            <Box className="single-recording_container_body_content_evidence_editor">
                    <>
                        {!isEvidenceMode ? (
                                <IconButton
                                    onClick={this.toggleEvidenceMode}
                                    sx={{
                                        color: '#fff',
                                        marginRight: '8px',
                                        backgroundColor: '#3f51b5',
                                        '&:hover': {
                                            backgroundColor: '#303f9f',
                                        },
                                        border: '1px solid #3f51b5',
                                        borderRadius: '8px',
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                                        padding: '8px 16px',
                                        fontSize: '14px',
                                    }}
                                >
                                    Utwórz dowód
                                </IconButton>
                        ) : (
                                <IconButton
                                    onClick={this.openDialog}
                                    sx={{
                                        color: '#fff',
                                        marginRight: '8px',
                                        backgroundColor: '#4caf50',
                                        '&:hover': {
                                            backgroundColor: '#388e3c',
                                        },
                                        border: '1px solid #4caf50',
                                        borderRadius: '8px',
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                                        padding: '8px 16px',
                                        fontSize: '14px',
                                    }}
                                >
                                    Zapisz dowód
                                </IconButton>
                        )}
                        {recording.file && (
                            <EvidenceEditor
                                evidenceMode={isEvidenceMode}
                                timeFrom={evidenceStartTime}
                                timeTo={evidenceEndTime}
                                durationInSeconds={parseFloat(recording.file.duration || '0')}
                                videoRef={this.props.videoRef}
                                onTimeRangeChange={this.handleTimeRangeChange}
                            />
                        )}
                        {recording.evidences.length > 0 && (
                            <Box className="evidence-timeline">
                                <EvidenceList
                                    evidences={recording.evidences}
                                    durationInSeconds={parseFloat(recording.file.duration)}
                                    handleEvidenceClick={this.handleEvidenceClick}
                                    handleEvidenceDeleteClick={this.handleEvidenceDeleteClick}
                                    handleEvidenceEditClick={this.handleEvidenceEditClick}
                                    file={this.props.recording.file}
                                    editMode={editMode}
                                    videoRef={this.props.videoRef}
                                />
                            </Box>
                        )}
                    </>
                <EvidenceDialog
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}
                    evidenceTitle={this.state.evidenceTitle}
                    evidenceNotes={this.state.evidenceNotes}
                    formatTime={formatTime}
                    recordingId={this.props.recording.id}
                    evidenceStartTime={Number(this.state.evidenceStartTime)}
                    evidenceEndTime={Number(this.state.evidenceEndTime)}
                    currentUserId={this.props.currentUserId}
                />
            </Box>
        );
    }
}

export default EvidenceCreator;
