import RecordingEvidenceFacade from "../../../Domain/Recording/RecordingEvidenceFacade";
import {IRecordingEvidence} from "../../../Infrastructure/Http/Recording/IRecording";

export default class RecordingEvidenceAdapter {
    private readonly recordingEvidenceFacade: RecordingEvidenceFacade;

    constructor() {
        this.recordingEvidenceFacade = new RecordingEvidenceFacade();
    }

    public async persist(id: number, http: IRecordingEvidence): Promise<IRecordingEvidence> {
        return await this.recordingEvidenceFacade.persist(id, http);
    }

    public async update(recordingId: number, evidenceId: number | undefined, http: IRecordingEvidence): Promise<IRecordingEvidence> {
        return await this.recordingEvidenceFacade.update(recordingId, evidenceId, http);
    }

    public async delete(recordingId: number, evidenceId: number | undefined): Promise<void> {
        return await this.recordingEvidenceFacade.delete(recordingId, evidenceId);
    }
}
