import IUserActivityLog, {
    IUserActivityLogFilter,
    IUserActivityLogPaginator
} from "../../Infrastructure/Http/User/IUserActivityLog";
import UserActivityLogService from "../../Infrastructure/Service/User/UserActivityLogService";
import {IPaginator} from "../../Infrastructure/Paginator/Paginator";

export default class UserActivityLogFacade {

    private readonly userActivityLogService: UserActivityLogService;
    constructor() {
        this.userActivityLogService = new UserActivityLogService();
    }

    public async findAll(page: IPaginator, filters: IUserActivityLogFilter): Promise<IUserActivityLogPaginator>  {
        return await this.userActivityLogService.findAll(page, filters);
    }

    public async persist(http: IUserActivityLog): Promise<void> {
        return await this.userActivityLogService.persist(http);
    }
}
